import React, { useState, useEffect } from 'react';
import { Editor, EditorState, ContentState, CompositeDecorator } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { Paper, Box, Typography } from '@mui/material';

// Custom decorator to find text within brackets and style it.
const findBracketedText = (contentBlock, callback) => {
  const text = contentBlock.getText();
  const regex = /\[(.*?)\]/g;
  let matchArr;
  while ((matchArr = regex.exec(text)) !== null) {
    const start = matchArr.index;
    const end = start + matchArr[0].length;
    callback(start, end);
  }
};

// Style for redacted text based on view mode
const RedactedSpan = (props) => {
  const { viewMode } = props;
  const style = viewMode === 'question'
    ? { backgroundColor: 'blue', color: 'blue' }
    : { color: 'blue' };

  // Display the text inside the brackets without showing the brackets
  const displayText = props.decoratedText.slice(1, -1);

  return (
    <span style={style}>
      {displayText}
    </span>
  );
};

// Composite decorator to use our custom strategy and component
const getDecorator = (viewMode) => new CompositeDecorator([
  {
    strategy: findBracketedText,
    component: (props) => <RedactedSpan {...props} viewMode={viewMode} />,
  },
]);

const RedactView = ({ question, viewMode }) => {
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromText(question.question);
    return EditorState.createWithContent(contentState, getDecorator(viewMode));
  });

  useEffect(() => {
    const contentState = ContentState.createFromText(question.question);
    setEditorState(EditorState.createWithContent(contentState, getDecorator(viewMode)));
  }, [question, viewMode]);

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', minHeight: 300, maxWidth: 700, margin: 'auto' }}>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" gutterBottom>
          {question.title}
        </Typography>
      </Box>
      <Editor
        editorState={editorState}
        readOnly={true}
        onChange={() => {}}
      />
    </Paper>
  );
};

export default RedactView;
