// businessDataFetcher.js
import { getNodesList } from '../../MlModels/MlNode';

export const fetchBusinessData = async (supplier) => {
  if (!supplier) {
    console.warn('Supplier is required to fetch business data.');
    return null;
  }

  const customMatch = {
    identity: 'autodin',
    type: 'businessData',
    businessName: supplier,
  };
  
  const customProject = {
    _id: 1,
    businessName: 1,
    brief: 1,
    businessAvatar: 1,
    email: 1,
    phone: 1,
    street: 1,
    number: 1,
    unit: 1,
    postalCode: 1,
    city: 1,
    country: 1,
  };

  try {
    const userId = ''; // Define userId if necessary
    const nodesList = await getNodesList(userId, customMatch, customProject);

    if (nodesList.length > 0) {
      return nodesList[0]; // Return the first node if found
    } else {
      console.warn('No business data found.');
      return null;
    }
  } catch (error) {
    console.error('Failed to fetch business data:', error);
    return null;
  }
};
