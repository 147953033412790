import React from "react";
import { PageBg, MlText, MlLayoutStack, MlPageContent } from '../mainlistcomponents';
import QwanyxHeader from "./QwanyxHeader";
import { nodeGet } from '../../../MlModels/MlNode';
import QwanyxHCard from "./QwanyxHCard";
import QwanyxImageList from "./QwanyxImageList";
import NodeList from "../../../components/qwanyx/NodeList"
import Footer from "../../qwanyx/Footer";
import { useNavigate } from "react-router-dom";



import {
    Stack,
    Box,
    Typography,
} from '@mui/material';



const QwanyxLandingPage = ({ bgColor = 'rgb(245,245,255)' }) => {
    const navigate = useNavigate(); // Initialize the hook

    const handleCardClick = (nodeId) => {
        navigate(`/public-canvas/${nodeId}`);
        //alert(`Node with ID ${nodeId} clicked`);
    };

    


    return (
        <>
            <Stack
                alignItems={'center'}
                sx={{
                    backgroundColor: bgColor,
                    minHeight: '100vh', // At least 100% of the viewport height
                    flexGrow: 1,        // Allows the Stack to grow with content
                }}
            ><QwanyxHeader />
                <Box
                    mt='3rem'
                    sx={{
                        px: 2
                    }}
                >
                    <QwanyxHCard
                        imageSize='400px'
                        maxWidth='800px' />
                </Box>
                <Box
                    mt='3rem'
                    mb='3rem'
                    width={800}
                >
                    <NodeList onCardClick={handleCardClick} />
                </Box>
            </Stack>
            <Footer />
        </>
    );
};

export default QwanyxLandingPage;
