import React, { useState } from 'react';
import MlIconBox from './MlIconBox';
import EditTextIcon from '@mui/icons-material/Edit'; // Icon for Edit Text
import EditSoundIcon from '@mui/icons-material/Audiotrack'; // Icon for Edit Sound
import EditVideoIcon from '@mui/icons-material/Movie'; // Icon for Edit Video
import EditImageIcon from '@mui/icons-material/Image'; // Icon for Edit Image
import DisplayIcon from '@mui/icons-material/DesktopWindows'; // Icon for Display
import DatasetIcon from '@mui/icons-material/Dataset'; //Icon for Data
import UploadIcon from '@mui/icons-material/Upload';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
//import List from '@mui/icons-material/Segment'; //
/*to implement the node set... and display the list */
const MlEditorToolBox = ({ onToolSelect }) => {

  const [disabledTool, setDisabledTool] = useState('Text');
  const tools = [
    { icon: EditTextIcon, text: 'Text' },
    { icon: EditSoundIcon, text: 'Sound' },
    { icon: EditVideoIcon, text: 'Video' },
    { icon: EditImageIcon, text: 'Image' },
    { icon: DisplayIcon, text: 'Display' },
    { icon: UploadIcon, text: 'Import' },
    { icon: DatasetIcon, text: 'Data' },
    { icon: SchoolRoundedIcon, text: 'Qwanyz' },
  ];

  const handleToolClick = (toolText) => {
    setDisabledTool(toolText);
    if (onToolSelect) {
      onToolSelect(toolText);
    }
  };

  return (
    <MlIconBox
      data={tools}
      iconSize="20px"
      onIconClick={handleToolClick}
      disabledTool={disabledTool}
    />
  );
};

export default MlEditorToolBox;