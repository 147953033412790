import React, { useState, useEffect } from 'react';
import { QStack, QIcon } from 'qwanyx-core';
import { Typography, Stack } from '@mui/material';
import { getRootCanvasTitlesAndIds, getUserId } from 'MlModels/MlNode';

// NodeCard Component for displaying each root node
const NodeCard = ({ title, brief }) => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '10px', borderBottom: '1px solid lightgrey' }}>
    <div style={{ flex: 1 }}>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body2" color="textSecondary">{brief}</Typography>
    </div>
    <QIcon label="Explore" />
  </div>
);

// Main GraphBrowser Component
export const GraphBrowser = () => {
  const [rootNodes, setRootNodes] = useState([]);

  useEffect(() => {
    // Get userId and fetch root nodes
    const userId = getUserId();
    if (userId) {
      getRootCanvasTitlesAndIds(userId).then(nodes => setRootNodes(nodes || []));
    } else {
      console.warn("User ID is not available");
      // Optional: Handle cases where userId is null
    }
  }, []);

  return (
    <Stack>
      {rootNodes.map((node) => (
        <NodeCard key={node._id} title={node.title} brief={node.brief} />
      ))}
    </Stack>
  );
};
