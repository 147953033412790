// components/QwanyxLandingPage.js
import React, { useEffect, useState } from 'react';
import { qwanyxRender, preprocessQwanyxData, localData } from 'qwanyx-core'; // Import necessary functions

export const QwanyxLandingPage = () => {
  const [renderedContent, setRenderedContent] = useState(null);

  useEffect(() => {
    const renderPage = async () => {
      try {
        // Fetch and preprocess the landing page data before rendering
        const processedData = await preprocessQwanyxData(localData.landingPage);
        const rendered = qwanyxRender(processedData);
        setRenderedContent(rendered);
      } catch (error) {
        console.error('Failed to render the landing page data', error);
      }
    };

    renderPage();
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {renderedContent}
    </div>
  );
};
