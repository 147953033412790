import React, { useState } from 'react';
import AutopartsDataView from './AutopartsDataView';
import PartEditor from './PartEditor';
import AutoPartsDataToolBox from './AutopartsDataToolBox';
import Header from './AutopartsEditorHeader'; // Import the Header component
import Box from '@mui/material/Box';


const DataMatchEditor = ({ record }) => {
    const [title, setTitle] = useState('');
    const [comments, setComments] = useState('');
    const [status, setStatus] = useState('editing'); // Track the status

    const handleStatusChange = (newStatus) => {
        setStatus(newStatus);
    };

    const handleSave = () => {
        console.log('Save action triggered');
    };

    const handleNavigate = (direction) => {
        console.log(`Navigate ${direction}`);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>


            {/* Toolbox */}
            <Box display="flex" justifyContent="center">
                <AutoPartsDataToolBox onIconClick={(action) => console.log(action)} />
            </Box>

            {/* Header */}
            <Header
                title={title}
                comments={comments}
                status={status}
                onTitleChange={setTitle}
                onCommentsChange={setComments}
                onStatusChange={handleStatusChange}
                onSave={handleSave}
                onNavigate={handleNavigate}
            />



            {/* Main Content */}
            <Box display="flex" gap={4}>
                {/* Left side: AutopartsDataView */}
                <Box flex={1}>
                    <AutopartsDataView record={record} />
                </Box>

                {/* Right side: PartEditor */}
                <Box flex={1}>
                    <PartEditor />
                </Box>
            </Box>
        </Box>
    );
};

export default DataMatchEditor;
