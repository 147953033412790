import React, { useEffect, useState, useRef } from 'react';
import MlTitle from '../../components/base/MlTitle';
import CarSummary from './CarSummary';
import CarDetails from './CarDetails';
import ImageEditor from '../../ImageEditorComponent/ImageEditor';
import VehiclePoster from './VehiclePoster';
import TabPanel from '../TabPanel';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Box, useMediaQuery, useTheme } from '@mui/material';
import { nodePut, createNode, nodeGet, nodeDelete } from '../../MlModels/MlNode';
import { useReactToPrint } from 'react-to-print';
import { carEmbeddingText } from '../../MlModels/objectToTextForEmbedding';
import { getEmbedding } from '../../MlModels/GPTembedding';
import { useUser } from '../../MlModels/UserContext';
import { fetchBusinessData } from './businessDataFetcher'; // Import the fetcher

const CarForm = ({ open, onClose, nodeId, loggedIn }) => {
    const [node, setNode] = useState(null);
    const [businessData, setBusinessData] = useState(null); // State for business data
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const identity = 'autodin';
    const form = 'vehicle';
    const posterRef = useRef();
    const { userData } = useUser();

    useEffect(() => {
        const fetchNodeData = async () => {
            if (nodeId) {
                try {
                    const fetchedNode = await nodeGet(nodeId);
                    setNode(fetchedNode);
                } catch (error) {
                    console.error('Failed to fetch node:', error);
                }
            } else {
                setNode(createNode({ title: 'Nouveau Véhicule', identity: 'autodin', form: 'vehicle', supplier: userData.businessIdentity, transmission: 2, power: '', powerUnit: 'KW' }));
            }
        };

        fetchNodeData();
    }, [nodeId]);

    useEffect(() => {
        const fetchBusinessInfo = async () => {
            if (node?.supplier) {
                const businessData = await fetchBusinessData(node.supplier); // Fetch business data
                setBusinessData(businessData);
            }
        };

        if (node) {
            fetchBusinessInfo();
        }
    }, [node]);

    const handleCancel = () => {
        onClose();
    };

    const addEmbedding = async () => {
        const textForEmbedding = carEmbeddingText(node);
        const embedding = await getEmbedding(textForEmbedding);
        node.embedding = embedding;
    };

    const handleSave = async () => {
        await addEmbedding();
        await nodePut(node);
        onClose();
    };

    const handleDelete = async () => {
        if (node) {
            await nodeDelete(node._id.toString());
        }
        onClose();
    };

    const handleDeleteConfirmation = () => {
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const handleConfirmDelete = async () => {
        await handleDelete();
        handleCloseDeleteDialog();
    };

    const handleChange = (field, value) => {
        setNode(prevNode => ({
            ...prevNode,
            [field]: value,
        }));
    };

    const tabLabels = ["Images", "Résumé", "Détails"];

    const logo = "https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com/IMAGES/upload/645b5e00c7b61df466430901/node_667d69d4dac9c828db94fdb1/AutoDinLogo.png?v=1719495169";

    const tabContents = [
        null,
        <CarSummary node={node} onChange={handleChange} />,
        <CarDetails node={node} onChange={handleChange} loggedIn={loggedIn} />
    ];

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePrint = useReactToPrint({
        content: () => posterRef.current,
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="car-form-dialog-title"
                fullWidth
                maxWidth="md"
                fullScreen={fullScreen}
                PaperProps={{
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: fullScreen ? '100%' : '95vh',
                    },
                }}
            >
                <DialogTitle
                    id="car-form-dialog-title"
                    sx={{
                        borderBottom: '1px solid #ccc',
                        backgroundColor: 'rgb(250,250,250)',
                    }}
                >
                    {node && (
                        <MlTitle
                            value={node.title}
                            onChange={e => handleChange('title', e.target.value)}
                            sx={{ flexGrow: 1 }}
                        />
                    )}
                </DialogTitle>
                <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}>
                    <TabPanel
                        tabLabels={tabLabels}
                        tabContents={tabContents}
                        selectedTab={selectedTab}
                        onChange={handleTabChange}
                        sx={{ flexShrink: 0 }}
                    />
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                        {selectedTab === 0 && <ImageEditor node={node} onChange={handleChange} loggedIn={loggedIn} />}
                    </Box>
                </Box>
                <DialogActions
                    sx={{
                        borderTop: '1px solid #ccc',
                        backgroundColor: 'rgb(250,250,250)',
                    }}
                >
                    <Button onClick={handleDeleteConfirmation} color="primary">
                        Supprimer
                    </Button>
                    <Button onClick={handleCancel} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Sauver
                    </Button>
                    <Button onClick={handlePrint} color="primary">
                        Imprimer
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="delete-confirmation-dialog-title"
            >
                <DialogTitle id="delete-confirmation-dialog-title">Confirmer la suppression</DialogTitle>
                <DialogContent>
                    Êtes-vous sûr de vouloir supprimer ce véhicule ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary">
                        Supprimer
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Render VehiclePoster off-screen for printing */}
            <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
                <VehiclePoster ref={posterRef} vehicleData={node} logo={logo} businessData={businessData} />
            </div>
        </>
    );
};

export default CarForm;
