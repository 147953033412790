import app from '../realm-config';

// Helper function to convert minutes to seconds with a default of 10 minutes
const getExpirationTime = (minutes = 10) => minutes * 60;

// Function to get pre-signed URLs from MongoDB Realm for different operations
const getPresignedUrl = async (fileName, operationType, expirationTimeInSeconds) => {
    try {
        // Fetch the pre-signed URL from your MongoDB Realm function
        const response = await app.currentUser.functions.generatePresignedUrl('qwanyx-storage-images', fileName, operationType, expirationTimeInSeconds);

        if (response.url) {
            return response.url; // Return the URL
        } else {
            throw new Error('No valid pre-signed URL returned');
        }
    } catch (error) {
        console.error('Error fetching pre-signed URL:', error);
        throw error;
    }
};

// Function to upload multiple files
// Function to upload multiple files
export const uploadFiles = async (files, expirationTime = 10) => {
    const timeInSeconds = getExpirationTime(expirationTime);

    try {
        // Use Promise.all to handle all files concurrently
        const uploadPromises = files.map(async ({ file, key }) => {
            // Get the pre-signed URL for each file (using its key, which includes the folder path)
            const presignedUrl = await getPresignedUrl(key, 'PUT', timeInSeconds);

            // Ensure the pre-signed URL is valid
            if (typeof presignedUrl !== 'string') {
                throw new Error(`Invalid pre-signed URL for ${key}`);
            }

            // Upload the file to the presigned URL with ACL set
            const response = await fetch(presignedUrl, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type,
                    'x-amz-acl': 'bucket-owner-full-control' // Ensure bucket owner gets full control
                },
            });

            if (response.ok) {
                console.log(`${key} uploaded successfully`);
            } else {
                console.error(`Failed to upload ${key}: ${response.statusText}`);
            }
        });

        // Wait for all the uploads to finish
        await Promise.all(uploadPromises);

        console.log("All files uploaded successfully!");

    } catch (error) {
        console.error('Error during the upload process:', error);
    }
};




// Function to delete multiple files
export const deleteFiles = async (files, expirationTime = 10) => {
    const timeInSeconds = getExpirationTime(expirationTime);

    for (const file of files) {
        try {
            // Get the pre-signed URL for deleting (DELETE operation)
            const presignedUrl = await getPresignedUrl(file.name, 'DELETE', timeInSeconds);

            // Perform the file deletion using the pre-signed URL
            const response = await fetch(presignedUrl, {
                method: 'DELETE',
            });

            if (response.ok) {
                console.log(`${file.name} deleted successfully`);
            } else {
                console.error(`Failed to delete ${file.name}: ${response.statusText}`);
            }
        } catch (error) {
            console.error(`Error deleting ${file.name}:`, error);
            // Optionally, handle the error (retry, skip, etc.)
        }
    }
};

// Function to download multiple files
export const getFiles = async (files, expirationTime = 10) => {
    const timeInSeconds = getExpirationTime(expirationTime);

    for (const file of files) {
        try {
            // Get the pre-signed URL for downloading (GET operation)
            const presignedUrl = await getPresignedUrl(file.name, 'GET', timeInSeconds);

            // Perform the file download using the pre-signed URL
            const response = await fetch(presignedUrl);

            if (response.ok) {
                const blob = await response.blob();
                console.log(`${file.name} downloaded successfully`);
                // Handle the blob (e.g., save it, display it, etc.)
            } else {
                console.error(`Failed to download ${file.name}: ${response.statusText}`);
            }
        } catch (error) {
            console.error(`Error downloading ${file.name}:`, error);
            // Optionally, handle the error (retry, skip, etc.)
        }
    }
};

// Function to list files
export const listFiles = async (bucketName, keyPrefix) => {
    try {
        const response = await app.currentUser.functions.ls(bucketName, keyPrefix);
        return response;
    } catch (error) {
        console.error("Error listing files: ", error);
        throw error;
    }
};
