import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';

const LabelIcon = ({ icon: Icon, label, onClick, disabled, color, transform }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <IconButton onClick={onClick} disabled={disabled} sx={{ color: color || 'inherit' }}>
        <Icon sx={{ transform: transform }} />
      </IconButton>
      <Typography variant="caption">{label}</Typography>
    </Box>
  );
};

export default LabelIcon;
