import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function AutopartsDataView({ record = {} }) {
  const fields = [
    { label: 'Code', key: 'code' },
    { label: 'Désignation courte', key: 'designation-courte' },
    { label: 'Famille', key: 'famille' },
    { label: 'Prix de vente HT', key: 'prix-de-vente-ht', type: 'number' },
    { label: 'Désignation longue', key: 'designation-longue' },
    { label: 'Prix de vente TTC', key: 'prix-de-vente-ttc', type: 'number' },
    { label: 'Stock théorique', key: 'stock-theorique', type: 'int' },
    { label: 'Stock réel', key: 'stock-reel', type: 'int' },
    { label: 'MARQUES', key: 'marques' },
    { label: 'EMPLACEMENT', key: 'emplacement' },
    { label: 'Qté en cde client', key: 'qte-en-cde-client', type: 'int' },
    { label: 'MODÈLES CHASSIS N', key: 'modeles-chassis-n' },
    { label: 'Référence fournisseur', key: 'reference-fournisseur' },
    { label: 'Qté en cde fournisseur', key: 'qte-en-cde-fournisseur', type: 'int' }
  ];

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {fields.map((field) => (
        <Box key={field.key} display="flex" alignItems="center">
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ width: 230, textAlign: 'right', paddingRight: 2 }}
          >
            {field.label}:
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: field.type === 'number' || field.type === 'int' ? 'right' : 'left',
              flexGrow: 1,
            }}
          >
            {record?.[field.key] ? (
              field.type === 'number'
                ? parseFloat(record[field.key]).toFixed(2)
                : record[field.key]
            ) : '-'}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

AutopartsDataView.propTypes = {
  record: PropTypes.shape({
    code: PropTypes.string,
    'designation-courte': PropTypes.string,
    famille: PropTypes.string,
    'prix-de-vente-ht': PropTypes.string,
    'designation-longue': PropTypes.string,
    'prix-de-vente-ttc': PropTypes.string,
    'stock-theorique': PropTypes.string,
    'stock-reel': PropTypes.string,
    marques: PropTypes.string,
    emplacement: PropTypes.string,
    'qte-en-cde-client': PropTypes.string,
    'modeles-chassis-n': PropTypes.string,
    'reference-fournisseur': PropTypes.string,
    'qte-en-cde-fournisseur': PropTypes.string
  })
};
