// helpers/fetchQwanyxData.js
import { localData } from 'qwanyx-core';

export function fetchQwanyxData(id) {
  // Fetch data by ID from local storage
  const data = localData[id];
  if (!data) {
    console.warn(`Data with id "${id}" not found locally.`);
  }
  return data;
}
