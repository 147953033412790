import React from 'react';
import { getUserId } from '../MlModels/MlNode';
import { FormGroup, TextField, Stack, Avatar, Typography } from '@mui/material';
import ImagePlaceHolder from '../ImageEditorComponent/Garage.webp'; // Import your placeholder image
import MlFileUploader from '../components/Mainlist/Dialogs/MlFileUploader';
import { useUser } from '../MlModels/UserContext';

export default function DashBoardProfile({ node, onChange }) {

    const { userData } = useUser();
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    // Use businessAvatar or fallback to placeholder
    const avatarSrc = `${process.env.REACT_APP_S3_URL_BASE}/${node?.businessAvatar}` || ImagePlaceHolder;

    const handleFileUpload = (fileName) => {
        const avatarLink = fileName;
        onChange('businessAvatar', avatarLink); // Set node.businessAvatar directly
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                {/* Avatar Section */}
                <Stack direction="row" alignItems="center" spacing={2}>
                    <MlFileUploader
                        onUpload={handleFileUpload}
                        folderPath={`IMAGES/autodin/business/`} // Set the correct folder path
                        userId={getUserId()}
                        accept="image/*"
                        multiple={false} // Only allow one image
                    />

                    <Avatar
                        alt={node?.businessName || 'Business Avatar'}
                        src={`${process.env.REACT_APP_S3_URL_BASE}/${node?.businessAvatar}` || ImagePlaceHolder}
                        sx={{ width: 80, height: 80, borderRadius: 0 }}
                    />
                </Stack>

                {/* Business Details */}
                {userData?.superUser ? (
                    // Renders when superUser is true
                    <TextField
                        label="Dénomination"
                        name="businessName"
                        value={node?.businessName || ''}
                        onChange={handleInputChange}
                    />
                ) : (
                    // Renders when superUser is false or undefined
                    <Typography variant="body1">
                        Dénomination: {node?.businessName || ''}
                    </Typography>
                )}

                <TextField
                    label="Secteur"
                    name="sector"
                    value={node?.sector || ''}
                    onChange={handleInputChange}
                />

                <TextField
                    label="Numéro d'entreprise"
                    name="taxId"
                    value={node?.taxId || ''}
                    onChange={handleInputChange}
                />

                {/* Description Section */}
                <TextField
                    name='brief'
                    id="outlined-multiline-static"
                    label="Description"
                    value={node?.brief || ''}
                    multiline
                    minRows={10}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
        </FormGroup>
    );
}
