import React from 'react';
import { Box } from '@mui/material';
import { QWANYZ_COLORS } from './QwanyzColorPalette';
const SimpleQuestionEditor = () => {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                backgroundColor: QWANYZ_COLORS.lightBeige
            }}
        >
            {/* Content goes here if needed */}
        </Box>
    );
};

export default SimpleQuestionEditor;