import React, { useRef, useState, useEffect } from "react";
import AutodinFooter from './AutodinFooter';
import AutoDinList from './AutoDinList';
import BusinessList from '../../business/BusinessList';
import SearchBox from '../SearchBox';
import AddButton from "../AddButton";
import TabPanelStyled from '../TabPanelStyled';
import AutoDinLogo from './AutoDinLogo';
import CarForm from './CarForm';
import VehicleDetails from './VehicleDetails';
import AutodinMenu from './AutodinMenu';
import BusinessBoard from "../../business/BusinessBoard";
import {
    Stack,
    Box,
    Button,
    Typography
} from '@mui/material';
import { getUserMail } from '../../MlModels/MlNode';
import { getEmbedding } from '../../MlModels/GPTembedding';
import { useUser } from '../../MlModels/UserContext';
import { useParams, useNavigate } from 'react-router-dom';

const AutodinLandingPage = ({ bgColor = 'rgb(230,230,230)', loggedIn = false }) => {
    const { selectedNodeId: urlNodeId } = useParams(); // Extract selectedNodeId from the URL
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [isCarFormVisible, setCarFormVisible] = useState(false);
    const [selectedBusinessId, setSelectedBusinessId] = useState(null);
    const [refreshList, setRefreshList] = useState(false);
    const [embedding, setEmbedding] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0); // "Edit" tab is selected by default (0 index)
    const [mainSelectedTab, setMainSelectedTab] = useState(1); // "Vehicules" tab is selected by default (1 index for vehicles)
    const navigate = useNavigate();
    const { userData } = useUser();
    const searchInputRef = useRef(null);
    const [isBusinessDashboardOpen, setBusinessDashboardOpen] = useState(false);


    useEffect(() => {
        if (selectedNodeId !== null) {
            setCarFormVisible(true);
        }
    }, [selectedNodeId]);
    // Update the state when the URL contains a selectedNodeId
    useEffect(() => {
        if (urlNodeId) {
            handleCardClick(urlNodeId); // Trigger the card click
        }
    }, [urlNodeId]);

    // On component mount, ensure "Edition" tab and "Vehicules" tab are selected for logged-in users
    useEffect(() => {
        if (loggedIn) {
            // Simulate the click on the "Edit" tab to apply filtering
            setSelectedTab(0); // Select "Edition" tab by default (tab index 0 for Edit)
            setMainSelectedTab(1); // Select "Vehicules" tab by default (tab index 1 for Vehicles)
            setRefreshList(prev => !prev); // Trigger a refresh in AutoDinList with the correct filtering for the current business
        }
    }, [loggedIn]);

    const handleBusinessDashboardOpen = () => {
        setBusinessDashboardOpen(true);
    };

    const handleBusinessDashboardClose = () => {
        setBusinessDashboardOpen(false);
        setRefreshList(prev => !(prev));
    };

    const handleAddButtonClick = () => {
        setSelectedNodeId(null);
        setCarFormVisible(true);
    };

    const handleAddGarageButtonClick = () => {
        setSelectedBusinessId(null);
        setTimeout(() => {
            handleBusinessDashboardOpen();
        }, 0);
    };

    const handleCloseCarForm = () => {
        setCarFormVisible(false);
        setRefreshList(prev => !prev);
    };

    // This function triggers when a card is clicked, setting the selectedNodeId
    const handleCardClick = (selectedNodeId) => {
        setSelectedNodeId(selectedNodeId); // Set the clicked node ID
        setCarFormVisible(true); // Open the car form/details when clicked
    };

    const handleBusinessClick = (businessId) => {
        setSelectedBusinessId(businessId);
        handleBusinessDashboardOpen();
        setRefreshList(prev => !prev);
    };

    const handleSearchClick = async () => {
        let searchText = searchInputRef.current.value;
        searchText = searchText.replace(/\n/g, " ");
        if (searchText) {
            const embeddingResult = await getEmbedding(searchText);
            setEmbedding(embeddingResult); // Store the embedding in state
        } else {
            setEmbedding(null); // Reset embedding if no search text
        }
        setRefreshList(prev => !prev); // Trigger a refresh in AutoDinList
    };

    const mainTabsLabels = ["Vendeurs", "Vehicules"];
    const tabLabels = ["Edition", "Archive", "En ligne", "Public"];

    return (
        <>
            <Stack
                alignItems={'center'}
                sx={{
                    backgroundColor: bgColor,
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <Stack mt={3} alignItems={'center'} position="relative" width="100%">
                    <AutoDinLogo width='12rem' />

                    {loggedIn &&
                        <Typography
                            variant="h7"
                            component="h2"
                            mt={2}
                        >
                            {`Editeur: ${getUserMail() || ''}`}
                        </Typography>
                    }
                    <Box position="absolute" top={0} right={0} m={2}>
                        <AutodinMenu />
                    </Box>
                </Stack>
                <Stack
                    sx={{
                        width: '98%',
                        maxWidth: '850px',
                    }}
                >
                    <Stack mt={0} alignItems='flex-end'>
                        {loggedIn && mainSelectedTab === 1 && <AddButton tooltip='Add a car' onClick={handleAddButtonClick} />}
                        {loggedIn && mainSelectedTab === 0 && userData?.superUser && (
                            <AddButton tooltip='Add a Garage' onClick={handleAddGarageButtonClick} />
                        )}
                        <Stack width='100%' alignItems='flex-end' mt={loggedIn ? 0 : 2} spacing={1}>
                            <SearchBox elevation={0} ref={searchInputRef} />
                            <Button variant="contained" size='small' onClick={handleSearchClick}>
                                Search
                            </Button>
                        </Stack>
                        <Box
                            mt={1}
                            mb={1}
                            sx={{
                                width: '100%',
                            }}
                        >
                            {loggedIn ? (
                                userData?.superUser ? (
                                    <>
                                        {/* Show both Vendeurs/Vehicules tab and Edit/Archive/Team/Public for super users */}
                                        <TabPanelStyled
                                            tabLabels={mainTabsLabels}
                                            selectedTab={mainSelectedTab}
                                            onChange={(newIndex) => {
                                                setMainSelectedTab(newIndex);
                                            }}
                                        />
                                        {mainSelectedTab === 1 && (
                                            <TabPanelStyled
                                                tabLabels={tabLabels}
                                                selectedTab={selectedTab}
                                                onChange={(newIndex) => {
                                                    setSelectedTab(newIndex);
                                                    setRefreshList((prev) => !prev); // Trigger a refresh in AutoDinList
                                                }}
                                            />
                                        )}
                                    </>
                                ) : (
                                    // Show only Edit/Archive/Team/Public tab for non-super users
                                    <TabPanelStyled
                                        tabLabels={tabLabels}
                                        selectedTab={selectedTab}
                                        onChange={(newIndex) => {
                                            setSelectedTab(newIndex);
                                            setRefreshList((prev) => !prev); // Trigger a refresh in AutoDinList
                                        }}
                                    />
                                )
                            ) : (
                                // Display public vehicles list when not logged in
                                <AutoDinList
                                    elevation={2}
                                    onCardClick={handleCardClick}
                                    refresh={refreshList}
                                    searchEmbedding={embedding}
                                    selectedTab={3} // Display Public vehicles (tab index 3)
                                    filter={"Public"} // Apply filter for public vehicles
                                />
                            )}

                            {loggedIn && userData?.superUser && mainSelectedTab === 1 && (
                                <AutoDinList
                                    elevation={2}
                                    onCardClick={handleCardClick}
                                    refresh={refreshList}
                                    searchEmbedding={embedding}
                                    selectedTab={selectedTab} // Pass selectedTab to AutoDinList
                                    filter={tabLabels[selectedTab]} // Apply filter according to the selected tab
                                />
                            )}

                            {loggedIn && !userData?.superUser && (
                                <AutoDinList
                                    elevation={2}
                                    onCardClick={handleCardClick}
                                    refresh={refreshList}
                                    searchEmbedding={embedding}
                                    selectedTab={selectedTab} // Pass selectedTab to AutoDinList
                                    filter={tabLabels[selectedTab]} // Apply filter according to the selected tab
                                />
                            )}

                            {loggedIn && mainSelectedTab === 0 && (
                                <BusinessList
                                    elevation={2}
                                    onRowClick={handleBusinessClick}
                                    refresh={refreshList}
                                    searchEmbedding={embedding}
                                    selectedTab={selectedTab}
                                    identity="autodin"
                                    type="businessData"
                                />
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <AutodinFooter />

            {/* Show the Car Form or Vehicle Details based on whether a card was clicked */}
            {isCarFormVisible && loggedIn && selectedTab !== 3 ? (
                <CarForm
                    open={isCarFormVisible}
                    onClose={handleCloseCarForm}
                    nodeId={selectedNodeId} // This can be null when adding a new car
                    loggedIn={loggedIn}
                />
            ) : (
                selectedNodeId && (
                    <VehicleDetails
                        open={isCarFormVisible}
                        onClose={handleCloseCarForm}
                        nodeId={selectedNodeId}
                        loggedIn={loggedIn}
                    />
                )
            )}


            <BusinessBoard
                open={isBusinessDashboardOpen}
                handleClose={handleBusinessDashboardClose}
                selectedBusinessId={selectedBusinessId}
                identity='autodin'
            />
        </>
    );
};

export default AutodinLandingPage;
