

export const QWANYZ_COLORS = {
    orange: "#FF4600",
    coral: "#FF7F50",
    beige: "#DDDBC4",
    lightBeige: "#FFFFFA",
    grey: "#5F5F5F",
    white: "#FFFFFF",
    disableled: '#bdbdbd',
  };

  export const QWANYX_MENU_COLORS = {
    orange: "255,70,0,1",
    coral: "255,127,80,1",
    beige: "221,219,196,1",
    lightBeige: "255,255,250,1",
    grey: "95,95,95,1",
    white: "255,255,255,1",
};

  