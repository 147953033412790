import React from 'react';
import {
    FormGroup,
    TextField,
    Stack,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
} from '@mui/material';

export default function DashBoardProfile({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>

                {node?.businessIdentity && (
                    <Typography variant="body1">
                        Business: {node.businessIdentity}
                    </Typography>
                )}

                <TextField
                    label="Prénom"
                    name="firstName"
                    value={node?.firstName || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Nom"
                    name="name"
                    value={node?.name || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Pseudo"
                    name="title"
                    value={node?.title || ''}
                    onChange={handleInputChange}
                />
                <FormControl fullWidth variant="outlined">
                    <InputLabel>Langue</InputLabel>
                    <Select
                        name="language"
                        value={node?.language || ''}
                        label="Langue"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="FR">Français</MenuItem>
                        <MenuItem value="NL">Néerlandais</MenuItem>
                        <MenuItem value="EN">Anglais</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    name="brief"
                    id="outlined-multiline-static"
                    label="Commentaires"
                    value={node?.brief || ''}
                    multiline
                    minRows={10}
                    fullWidth
                    variant="outlined"
                    onChange={handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Stack>
        </FormGroup>
    );
}
