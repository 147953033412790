import React from 'react';
import { IconButton, Stack } from '@mui/material';

function IconButtonBar({ icons }) {
  // Define sections for left, center, and right
  const leftIcons = icons.filter(icon => icon.position === 'left');
  const centerIcons = icons.filter(icon => icon.position === 'center');
  const rightIcons = icons.filter(icon => icon.position === 'right');

  return (
    <Stack direction="row" spacing={0} sx={{ width: '100%' }}>
      {/* Left Section - Left Justified */}
      <Stack direction="row" spacing={1} justifyContent="flex-start" sx={{ flex: 1 }}>
        {leftIcons.map((iconConfig, index) => (
          <IconButton key={index} onClick={() => iconConfig.onClick(iconConfig.name)}>
            {React.cloneElement(iconConfig.icon, { fontSize: iconConfig.size || 'default', color: iconConfig.color || 'inherit' })}
          </IconButton>
        ))}
      </Stack>

      {/* Center Section - Center Justified */}
      <Stack direction="row" spacing={1} justifyContent="center" sx={{ flex: 1 }}>
        {centerIcons.map((iconConfig, index) => (
          <IconButton key={index} onClick={() => iconConfig.onClick(iconConfig.name)}>
            {React.cloneElement(iconConfig.icon, { fontSize: iconConfig.size || 'default', color: iconConfig.color || 'inherit' })}
          </IconButton>
        ))}
      </Stack>

      {/* Right Section - Right Justified */}
      <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ flex: 1 }}>
        {rightIcons.map((iconConfig, index) => (
          <IconButton key={index} onClick={() => iconConfig.onClick(iconConfig.name)}>
            {React.cloneElement(iconConfig.icon, { fontSize: iconConfig.size || 'default', color: iconConfig.color || 'inherit' })}
          </IconButton>
        ))}
      </Stack>
    </Stack>
  );
}

export default IconButtonBar;
