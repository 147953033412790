import React from 'react';
import { Stack } from '@mui/material';
import QwanyzButtons from './QwanyzButtons';
import { QWANYZ_COLORS } from './QwanyzColorPalette';



function QwanyzControlPanel(props) {

  const {
    showNavigation = false,
    showPlayStop = true,
    showSetup = true
} = props;




  return (
    // Set the Stack to be a flex container with horizontal alignment
    <Stack 
      direction="row" 
      justifyContent="center" // Centers children horizontally in a row
      alignItems="center" // Centers children vertically
      sx={{ width: '100%', height: '64px' }} // Ensures Stack takes full width and height
      backgroundColor={QWANYZ_COLORS.grey}
    >
      <QwanyzButtons
      showNavigation = {showNavigation}
      showSetup = {showSetup}
      showPlayStop = {showPlayStop}
      fill={QWANYZ_COLORS.lightBeige}

      />
    </Stack>
  );
}

export default QwanyzControlPanel;