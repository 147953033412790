import React from 'react';
import { FormGroup, FormControlLabel, Checkbox, Radio, TextField, Stack, RadioGroup, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import CarTypes from '../autodin/CarTypes';
import Motorisation from '../autodin/Motorisation';
import BrandModelSelect from '../BelCar/form/BrandModelSelect';
import PowerInput from '../BelCar/base/PowerInput';
import CarStates from '../autodin/CarStates';
import CarTarifs from '../autodin/CarTarifs';
import Registration from '../autodin/Registration';
import Transmission from '../autodin/Transmission';

export default function DashBoardContact({ node, onChange }) {
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        onChange(name, value);
    };

    return (
        <FormGroup>
            <Stack width="100%" spacing={1.5} padding={1.5}>
                <TextField
                    label="Email"
                    name="email"
                    value={node?.email || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Téléphone"
                    name="phone"
                    value={node?.phone || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Linked-in"
                    name="linkedin"
                    value={node?.linkedin || ''}
                    onChange={handleInputChange}
                />
                <TextField
                    label="Facebook"
                    name="facebook"
                    value={node?.facebook || ''}
                    onChange={handleInputChange}
                />
            </Stack>
        </FormGroup>
    );
}
