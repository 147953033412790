import React, { useState } from 'react';
import IconButtonBar from '../base/IconButtonBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Setup from '@mui/icons-material/PsychologyRounded';
import { RAMS_COLORS } from '../base/ColorPalette';

function QuanyzButtons(props) {
  const { iconSize = 32 } = props;
  const muiFontSize = iconSize;
  const fill = RAMS_COLORS.beige;
  const blue = RAMS_COLORS.executiveBlue;

  const [isVisible, setIsVisible] = useState(true);

  const handleVisibilityToggle = () => {
    setIsVisible(!isVisible);
  };

  // Define icons with positions and click handlers
  const icons = [
    {
      icon: <Setup sx={{ fontSize: muiFontSize, color: fill }} />,
      position: 'right',
      name: 'setup',
      onClick: () => console.log('Setup icon clicked'),
      color: 'red', // Pass color as part of the icon config
    },
    {
      icon: isVisible ? <VisibilityIcon sx={{ fontSize: muiFontSize, color: fill }} /> : <VisibilityOffIcon sx={{ fontSize: muiFontSize, color: fill }} />,
      position: 'center',
      name: 'visibilityToggle',
      onClick: handleVisibilityToggle,
    },
    {
      icon: <ThumbUpAltIcon sx={{ fontSize: muiFontSize, color: fill }} />,
      position: 'center',
      name: 'thumbUp',
      onClick: () => console.log('ThumbUp icon clicked'),
      visible: !isVisible, // only visible when isVisible is false
    },
  ].filter(iconConfig => iconConfig.visible === undefined || iconConfig.visible); // Filter out icons based on visibility condition

  return <IconButtonBar icons={icons} />;
}

export default QuanyzButtons;
