import React from 'react';
import { Box, Typography } from '@mui/material';

// Helper function to format the price (00,00 format for euros)
const formatPrice = (value, currency) => {
  const formattedValue = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency === '€' ? 'EUR' : 'USD',
    minimumFractionDigits: 2
  }).format(value);

  // Return the formatted value, replacing the currency symbol for custom placement
  return formattedValue.replace(/[\s]/g, ''); // Remove any spaces
};

export default function PriceDisplay({ value, currency = '€', width = 'auto', mt = '18px' }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width }} mt={mt}>
    <Typography variant="body1">{currency}</Typography>
      <Typography variant="body1" noWrap>{formatPrice(value, currency).slice(0, -1)}</Typography>
      
    </Box>
  );
}
