import React, { useState, useEffect } from 'react';
import {
  Box,
  Stack,
  CircularProgress,
  Modal,
  Divider,
  Typography,
} from '@mui/material';
import { ObjectId } from 'bson';
import MultipleChoiceEditor from './MulitpleChoiceEditor';
import MultipleChoiceView from './MultipleChoiceView';
import BasicRecallEditor from './BasicRecallEditor';
import BasicRecallView from './BasicRecallView';
import RedactEditor from './RedactEditor';
import RedactView from './RedactView';
import ImageQuestionsEditor from './ImageQuestionEditor';
import ImageQuestionsView from './ImageQuestionView';
import Toolbox from './QuestionListToolBox';
import ScrollSlider from '../base/ScrollSlider';

const QwanyzEditor = ({ initialValues = {}, onSave, editorMode = 'edit' }) => {
  const [currentEditorMode, setCurrentEditorMode] = useState(editorMode);
  const [isLoading, setIsLoading] = useState(false);
  const [questionList, setQuestionList] = useState(initialValues.questions || []);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionTypeLabel, setQuestionTypeLabel] = useState('');

  // Helper function to auto-save the questions
  const autoSaveQuestions = (updatedQuestions) => {
    if (onSave) {
      onSave({ questions: updatedQuestions });
    }
  };

  // Handler for importing questions from a JSON file
  const handleImportQuestions = (importedQuestions) => {
    setQuestionList(importedQuestions);
    setCurrentQuestionIndex(0); // Reset index to the first question after import
    autoSaveQuestions(importedQuestions); // Auto-save the imported questions
  };

  // Update the questionTypeLabel whenever question type changes
  useEffect(() => {
    if (questionList.length > 0) {
      const currentType = questionList[currentQuestionIndex]?.type;
      switch (currentType) {
        case 'multiple':
          setQuestionTypeLabel('Multiple Choices');
          break;
        case 'redact':
          setQuestionTypeLabel('Redacted Text');
          break;
        case 'image':
          setQuestionTypeLabel('Image Flip');
          break;
        case 'basic':
        default:
          setQuestionTypeLabel('Basic Recall');
      }
    } else {
      setQuestionTypeLabel('No Questions Available');
    }
  }, [questionList, currentQuestionIndex]);

  const LoadingModal = (props) => (
    <Modal open={props.open} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress />
    </Modal>
  );

  // Handle question type change
  const handleQuestionTypeChange = (newType) => {
    setQuestionList((prevList) => {
      const updatedList = prevList.map((question, idx) =>
        idx === currentQuestionIndex ? { ...question, type: newType } : question
      );
      autoSaveQuestions(updatedList); // Auto-save on question type change
      return updatedList;
    });
  };

  // Handle Toolbox icon click actions
  const handleIconClick = (iconName) => {
    let updatedList = [...questionList];
    let newIndex = currentQuestionIndex;
  
    switch (iconName) {
      case 'showQuestion':
        setCurrentEditorMode('question');
        break;
      case 'showAnswer':
        setCurrentEditorMode('answer');
        break;
      case 'edit':
        setCurrentEditorMode('edit');
        break;
      case 'add':
        // Add a new question with default type and qId
        updatedList = [
          ...updatedList,
          { type: 'basic', content: '', qId: new ObjectId().toString() },
        ];
        newIndex = updatedList.length - 1; // Set index to the newly added question
        break;
      case 'remove':
        updatedList = updatedList.filter((_, idx) => idx !== currentQuestionIndex);
        newIndex = Math.max(currentQuestionIndex - 1, 0); // Move to previous question or stay on the first one
        break;
      case 'duplicate':
        // Duplicate current question and assign a new qId
        updatedList = [
          ...updatedList,
          { ...updatedList[currentQuestionIndex], qId: new ObjectId().toString() },
        ];
        newIndex = updatedList.length - 1; // Set index to the duplicated question
        break;
      case 'firstPage':
        newIndex = 0;
        break;
      case 'skipPrevious':
        newIndex = Math.max(currentQuestionIndex - 1, 0);
        break;
      case 'skipNext':
        newIndex = Math.min(currentQuestionIndex + 1, updatedList.length - 1);
        break;
      case 'lastPage':
        newIndex = updatedList.length - 1;
        break;
      case 'import':
        // Trigger the file input in the Toolbox for importing questions
        document.getElementById('import-file-input').click();
        break;
      default:
        console.warn(`Unhandled icon action: ${iconName}`);
        break;
    }
  
    // Update state with new question list and current question index
    setQuestionList(updatedList);
    setCurrentQuestionIndex(newIndex);
    autoSaveQuestions(updatedList);
  };

  // Handle question change from editor
  const handleQuestionChange = (updatedQuestion) => {
    setQuestionList((prevList) => {
      const updatedList = prevList.map((question, idx) =>
        idx === currentQuestionIndex ? updatedQuestion : question
      );
      autoSaveQuestions(updatedList); // Auto-save whenever a question is changed
      return updatedList;
    });
  };

  // Handle slider change to select the question
  const handleSliderChange = (event, newValue) => {
    setCurrentQuestionIndex(newValue - 1); // Adjusting for zero-based index
  };

  // Update slider value whenever currentQuestionIndex changes
  useEffect(() => {
    if (questionList.length > 0) {
      setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex, questionList.length - 1));
    }
  }, [questionList.length]);

  // Render the form based on the selected question type and editor mode
  const renderForm = () => {
    if (questionList.length === 0) {
      return (
        <Typography sx={{ marginTop: 2, textAlign: 'center', color: 'gray' }}>
          No questions available. Please use the "Add" button to create a new question.
        </Typography>
      );
    }

    const currentQuestion = questionList[currentQuestionIndex];
    if (currentEditorMode === 'edit') {
      switch (currentQuestion.type) {
        case 'multiple':
          return <MultipleChoiceEditor question={currentQuestion} onChange={handleQuestionChange} />;
        case 'redact':
          return <RedactEditor question={currentQuestion} onChange={handleQuestionChange} />;
        case 'image':
          return <ImageQuestionsEditor question={currentQuestion} onChange={handleQuestionChange} />;
        case 'basic':
        default:
          return <BasicRecallEditor question={currentQuestion} onChange={handleQuestionChange} onTypeChange={handleQuestionTypeChange} />;
      }
    } else if (currentEditorMode === 'question' || currentEditorMode === 'answer') {
      const viewMode = currentEditorMode;
      switch (currentQuestion.type) {
        case 'image':
          return <ImageQuestionsView question={currentQuestion} viewMode={viewMode} />;
        case 'basic':
          return <BasicRecallView question={currentQuestion} viewMode={viewMode} />;
        case 'multiple':
          return <MultipleChoiceView question={currentQuestion} viewMode={viewMode} />;
        case 'redact':
          return <RedactView question={currentQuestion} viewMode={viewMode} />;
        default:
          return null;
      }
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LoadingModal open={isLoading} />

      {/* Stack for the Form and Toolbox */}
      <Stack direction="column" spacing={2} alignItems="flex-start" sx={{ width: '100%', maxWidth: 700 }}>

        {/* Add Divider above the Toolbox */}
        <Divider sx={{ width: '100%' }} />

        {/* Toolbox with questionType and icon click handlers */}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Toolbox
            onIconClick={handleIconClick}
            questionType={questionList[currentQuestionIndex]?.type}
            onQuestionTypeChange={handleQuestionTypeChange}
            onImportQuestions={handleImportQuestions} // Pass the import handler to Toolbox
          />
        </Box>

        {/* ScrollSlider below Toolbox */}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <ScrollSlider
            min={1}
            max={questionList.length}
            value={currentQuestionIndex + 1} // Adjusting for one-based index
            onChange={handleSliderChange}
          />
        </Box>

        {/* Display the label for the current question type */}
        <Box sx={{ marginTop: 2, fontWeight: 'bold' }}>{questionTypeLabel}</Box>

        {/* Dynamic Form */}
        {renderForm()}

        {/* Display the current question qId */}
        <Typography sx={{ marginTop: '0.5rem', textAlign: 'center' }}>
          Current Question ID: {questionList[currentQuestionIndex]?.qId}
        </Typography>
      </Stack>
    </Box>
  );
};

export default QwanyzEditor;
