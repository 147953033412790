import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const MultipleChoiceEditor = ({ question, onChange }) => {
  const [formData, setFormData] = useState({
    title: question?.title || '',
    question: question?.question || '',
    choices: question?.choices || [],
  });
  const [newChoice, setNewChoice] = useState('');
  const [selectedChoices, setSelectedChoices] = useState(question?.selectedChoices || []);
  const [editingChoiceIndex, setEditingChoiceIndex] = useState(-1);
  const [draggedChoiceIndex, setDraggedChoiceIndex] = useState(null);
  const [hoveredChoiceIndex, setHoveredChoiceIndex] = useState(null);
  const [randomize, setRandomize] = useState(question?.randomize || false);

  // Handle form data changes
  useEffect(() => {
    if (onChange) {
      onChange({
        ...question,
        title: formData.title,
        question: formData.question,
        choices: formData.choices,
        selectedChoices: selectedChoices,
        randomize: randomize,
      });
    }
  }, [formData, selectedChoices, randomize]);

  // Handle title changes
  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  // Handle question text changes
  const handleQuestionChange = (e) => {
    setFormData({ ...formData, question: e.target.value });
  };

  // Handle adding a new choice
  const handleAddChoice = () => {
    if (newChoice.trim()) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        choices: [...prevFormData.choices, newChoice],
      }));
      setSelectedChoices([...selectedChoices, false]);
      setNewChoice('');
    }
  };

  // Handle deleting a choice
  const handleDeleteChoice = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      choices: prevFormData.choices.filter((_, i) => i !== index),
    }));
    setSelectedChoices((prevSelected) => prevSelected.filter((_, i) => i !== index));
  };

  // Handle selecting a choice
  const handleChoiceClick = (index) => {
    setSelectedChoices((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[index] = !newSelected[index];
      return newSelected;
    });
  };

  // Drag-and-drop handlers
  const handleDragStart = (e, index) => {
    setDraggedChoiceIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setHoveredChoiceIndex(index);
    e.dataTransfer.dropEffect = "move";
  };

  const handleDrop = () => {
    if (draggedChoiceIndex === null || draggedChoiceIndex === hoveredChoiceIndex) {
      setDraggedChoiceIndex(null);
      setHoveredChoiceIndex(null);
      return;
    }

    const updatedChoices = [...formData.choices];
    const [movedChoice] = updatedChoices.splice(draggedChoiceIndex, 1);
    updatedChoices.splice(hoveredChoiceIndex, 0, movedChoice);

    setFormData({ ...formData, choices: updatedChoices });

    const updatedSelectedChoices = [...selectedChoices];
    const [movedSelection] = updatedSelectedChoices.splice(draggedChoiceIndex, 1);
    updatedSelectedChoices.splice(hoveredChoiceIndex, 0, movedSelection);

    setSelectedChoices(updatedSelectedChoices);
    setDraggedChoiceIndex(null);
    setHoveredChoiceIndex(null);
  };

  // Style for the dragged choice
  const getChoiceStyles = (index) => {
    return {
      opacity: draggedChoiceIndex === index ? 0.5 : 1,
      border: hoveredChoiceIndex === index ? '2px dashed #3f51b5' : 'none',
    };
  };

  const handleRandomizeChange = (e) => {
    setRandomize(e.target.checked);
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 700, margin: 'auto' }}>
      <TextField
        fullWidth
        value={formData.title}
        onChange={handleTitleChange}
        label="Title"
        variant="outlined"
        sx={{ marginBottom: 2 }}
      />

      <TextField
        label="Question"
        multiline
        rows={3}
        fullWidth
        value={formData.question}
        onChange={handleQuestionChange}
        sx={{ marginTop: 2 }}
      />

      <Box sx={{ marginTop: 2 }}>
        {formData.choices.map((choice, index) => (
          <Box
            key={index}
            sx={{ display: 'flex', alignItems: 'center', mb: 1, ...getChoiceStyles(index) }}
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDrop={handleDrop}
          >
            <Button
              variant="contained"
              onClick={() => handleChoiceClick(index)}
              sx={{
                flexGrow: 1,
                backgroundColor: selectedChoices[index] ? 'primary.main' : 'white',
                color: selectedChoices[index] ? 'white' : 'black',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: selectedChoices[index] ? 'primary.dark' : 'white',
                },
              }}
            >
              {choice}
            </Button>
            <IconButton onClick={() => handleDeleteChoice(index)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
        <TextField
          label="New Choice"
          fullWidth
          value={newChoice}
          onChange={(e) => setNewChoice(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleAddChoice();
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 1 }}>
        <FormControlLabel
          control={<Checkbox checked={randomize} onChange={handleRandomizeChange} />}
          label="Randomize"
        />
      </Box>
    </Paper>
  );
};

export default MultipleChoiceEditor;
