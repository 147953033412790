import React from 'react';
import { Typography, Box } from '@mui/material';

const TextWithNumber = ({ text, number, backgroundColor = '#333333', textColor = 'white' }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor,  // Customizable background color
        padding: '0px 15px', // Add padding for better layout
        borderRadius: '0px', // Optional: rounding the corners
      }}
    >
      <Typography variant="body1" fontWeight="bold" sx={{ color: textColor }}>
        {text}
      </Typography>
      <Typography variant="body1" sx={{ color: textColor }}>
        {number}
      </Typography>
    </Box>
  );
};

export default TextWithNumber;
