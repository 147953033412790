import React from 'react';
import { Box } from '@mui/material';
import DiscreteSliderValues from '../base/DiscreteSliderValues';
import PriceDisplay from '../base/PriceDisplay';

const SliderWithPrice = ({
    min,
    max,
    step,
    valueLabelDisplay = "on",
    onChange,
    sliderValue, // value for the slider
    priceValue,  // calculated price value
    priceWidth = '65px',  // default width for the price
    currency = '€'        // default currency
}) => {
    return (
        <Box
            sx={{ display: 'flex', alignItems: 'center', px: 3, paddingLeft: 4 }}
        >
            <Box sx={{ flexGrow: 1, marginRight: 2 }}>
                <DiscreteSliderValues
                    min={min}
                    max={max}
                    step={step}
                    valueLabelDisplay={valueLabelDisplay}
                    onChange={onChange}
                    value={sliderValue}
                />
            </Box>
            <PriceDisplay
                value={priceValue}
                currency={currency}
                width={priceWidth}
            />
        </Box>
    );
};

export default SliderWithPrice;
