import React, { useEffect, useState } from 'react';
import {
  TextField,
  Box,
  Paper,
  Checkbox,
  FormControlLabel
} from '@mui/material';

const BasicRecallEditor = ({ question, onChange }) => {
  const [formData, setFormData] = useState({
    title: question.title || '',
    question: question.question || '',
    correctAnswer: question.correctAnswer || '',
    useEmptyField: question.useEmptyField || false,
  });

  // Update local state when the parent question prop changes
  useEffect(() => {
    setFormData({
      title: question.title || '',
      question: question.question || '',
      correctAnswer: question.correctAnswer || '',
      useEmptyField: question.useEmptyField || false,
    });
  }, [question]);

  // Handle form changes and notify the parent component (QwanyzEditor)
  const handleChange = (field, value) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);
    // Notify parent of the changes
    onChange({ ...question, ...updatedData });
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 700, margin: 'auto' }}>
      <TextField
        fullWidth
        value={formData.title}
        onChange={(e) => handleChange('title', e.target.value)}
        label="Title"
        variant="outlined"
        sx={{ marginTop: 1 }}
      />

      <TextField
        label="Question"
        multiline
        rows={3}
        fullWidth
        value={formData.question}
        onChange={(e) => handleChange('question', e.target.value)}
        sx={{ marginTop: 2 }}
      />

      <Box sx={{ marginTop: 2 }}>
        <TextField
          label="Correct Answer"
          fullWidth
          value={formData.correctAnswer}
          onChange={(e) => handleChange('correctAnswer', e.target.value)}
          sx={{ marginTop: 1 }}
        />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 1 }}>
        <FormControlLabel
          control={<Checkbox
            checked={formData.useEmptyField}
            onChange={(e) => handleChange('useEmptyField', e.target.checked)}
          />}
          label="Use an empty field"
        />
      </Box>
    </Paper>
  );
};

export default BasicRecallEditor;
