import React, { useState, useRef, useEffect } from 'react';
import {
  TextField,
  Box,
  Paper,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material';

const RedactEditor = ({ question, onChange }) => {
  const [formData, setFormData] = useState({
    title: question?.title || '',
    question: question?.question || '',
  });
  const [useEmptyFields, setUseEmptyFields] = useState(question?.useEmptyFields || false);
  const textAreaRef = useRef(null); // Reference to the text field

  // Sync changes with parent component
  useEffect(() => {
    if (onChange) {
      onChange({
        ...question,
        title: formData.title,
        question: formData.question,
        useEmptyFields: useEmptyFields,
      });
    }
  }, [formData, useEmptyFields]);

  // Handle title change
  const handleTitleChange = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  // Handle question text change
  const handleQuestionChange = (e) => {
    setFormData({ ...formData, question: e.target.value });
  };

  // Handle "Use Empty Fields" checkbox change
  const handleUseEmptyFieldsChange = (e) => {
    setUseEmptyFields(e.target.checked);
  };

  // Handle text redaction
  const handleRedactText = () => {
    const textarea = textAreaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start === end) {
      alert("Please select text to redact.");
      return;
    }

    // Get the selected text and its trimmed version
    const selectedText = formData.question.slice(start, end);
    const trimmedText = selectedText.trim();

    // Determine the number of leading and trailing spaces
    const leadingSpaces = selectedText.length - selectedText.trimStart().length;
    const trailingSpaces = selectedText.length - selectedText.trimEnd().length;

    // Create the redacted text with original spaces preserved
    const redactedText = `${formData.question.slice(0, start)}${' '.repeat(leadingSpaces)}[${trimmedText}]${' '.repeat(trailingSpaces)}${formData.question.slice(end)}`;

    setFormData({ ...formData, question: redactedText });
  };

  // Handle clearing redacted text
  const handleClearRedact = () => {
    const textarea = textAreaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    if (start === end) {
      // Regex to find all text inside square brackets and remove the brackets
      const clearedText = formData.question.replace(/\[(.*?)\]/g, '$1');
      setFormData({ ...formData, question: clearedText });
    } else {
      // Get the selected text within the brackets
      let selectedText = formData.question.slice(start, end);

      // Regex to find text inside brackets within the selected range and remove brackets
      const clearedText = selectedText.replace(/\[(.*?)\]/g, '$1');

      // Replace only the selected portion with the cleared text
      const updatedQuestion = `${formData.question.slice(0, start)}${clearedText}${formData.question.slice(end)}`;
      setFormData({ ...formData, question: updatedQuestion });
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 700, margin: 'auto' }}>
      <TextField
        fullWidth
        value={formData.title}
        onChange={handleTitleChange}
        label="Title"
        variant="outlined"
        sx={{ marginTop: 1 }}
      />

      <TextField
        label="Question"
        multiline
        rows={4}
        fullWidth
        value={formData.question}
        onChange={handleQuestionChange}
        sx={{ marginTop: 2 }}
        inputRef={textAreaRef} // Reference the text field
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={useEmptyFields} onChange={handleUseEmptyFieldsChange} />}
          label="Use empty fields"
        />
        <Box>
          <Button variant="contained" color="primary" onClick={handleRedactText} sx={{ marginRight: 1 }}>
            Redact
          </Button>
          <Button variant="outlined" color="secondary" onClick={handleClearRedact}>
            Clear Redact
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default RedactEditor;
