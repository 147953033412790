import React, { useEffect, useState } from 'react';
import Form from '@rjsf/mui';
import validator from '@rjsf/validator-ajv8';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import { Divider, Typography } from '@mui/material';

// Custom No-Op Submit Button
const NoOpSubmitButton = () => null;

const MlCanvasSetup = () => {
    const {
        mlNode,
        setMlNode,
        getUserId
    } = useMlCanvas();

    const superuser = process.env.REACT_APP_SUPERUSER;
    const templateUserId = mlNode ? mlNode.userId : null;

    // Form data state to manage the current form values
    const [formData, setFormData] = useState({
        template: false,
        playBar: false,
        publicTemplate: false,
        course: false,
        publicCourse: false,
        imageLink: '',
        brief: '',
        tags: ''
    });

    // Effect to initialize formData from mlNode
    useEffect(() => {
        if (mlNode) {
            setFormData({
                template: mlNode.template || false,
                playBar: mlNode.playBar || false,
                publicTemplate: mlNode.publicTemplate || false,
                course: mlNode.course || false,
                publicCourse: mlNode.publicCourse || false,
                imageLink: mlNode.imageLink || '',
                brief: mlNode.brief || '',
                tags: mlNode.tags ? mlNode.tags.join(', ') : '',
            });
        }
    }, [mlNode]);

    // Common Style for Boolean Fields
    const booleanFieldStyle = {
        "ui:widget": "checkbox",
        "ui:options": {
            style: {
                marginBottom: "-30px",
                marginRight: "-30px" // Adjust margin as needed to create appropriate spacing
            }
        }
    };

    // Schema for the String Fields Form
    const stringFieldsSchema = {
        type: "object",
        properties: {
            imageLink: {
                type: "string",
                title: "Image Link"
            },
            brief: {
                type: "string",
                title: "Brief"
            },
            tags: {
                type: "string",
                title: "Tags",
                description: "Enter tags separated by commas"
            }
        }
    };

    // Schema for the Boolean Fields Form
    const booleanFieldsSchema = {
        type: "object",
        properties: {
            template: {
                type: "boolean",
                title: "Template"
            },
            playBar: {
                type: "boolean",
                title: "Display Play Bar"
            },
            ...(getUserId() === superuser && {
                publicTemplate: {
                    type: "boolean",
                    title: "Public Template"
                }
            }),
            course: {
                type: "boolean",
                title: "Course"
            },
            publicCourse: {
                type: "boolean",
                title: "Public Course"
            }
        }
    };

    // UI Schema for specifying how fields should be rendered for string fields
    const stringFieldsUiSchema = {
        imageLink: {
            "ui:widget": "text"
        },
        brief: {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            }
        },
        tags: {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            }
        }
    };

    // UI Schema for specifying how fields should be rendered for boolean fields
    const booleanFieldsUiSchema = {
        template: booleanFieldStyle,
        playBar: booleanFieldStyle,
        publicTemplate: booleanFieldStyle,
        course: booleanFieldStyle,
        publicCourse: booleanFieldStyle
    };

    // Automatically handle changes to the form and update the context state
    const handleChange = ({ formData }) => {
        // Update the local formData state for immediate reactivity
        setFormData(formData);

        // Update the context state to keep mlNode updated
        setMlNode(prevNode => ({
            ...prevNode,
            ...formData,
            tags: formData.tags.split(',').map(tag => tag.trim()) // Properly format the tags
        }));
    };

    return (
        <div style={{ maxWidth: 800, margin: 'auto', padding: 15 }}>
            {/* Replaced h3 with Typography */}
            <Typography variant="h5" component="h3" gutterBottom>
                Canvas Setup
            </Typography>

            {/* String Fields Form */}
            <Form
                schema={stringFieldsSchema}
                uiSchema={stringFieldsUiSchema}
                formData={formData}
                onChange={handleChange} // Automatically handle changes and reflect them in the context
                validator={validator}
                disabled={templateUserId !== getUserId()} // Disable form if user doesn't match
                liveValidate // Validates the form as changes are made
                showErrorList={false} // Do not show the error list at the top
                templates={{
                    ButtonTemplates: {
                        SubmitButton: NoOpSubmitButton // Disable the submit button by rendering null
                    }
                }}
            />

            {/* Divider between forms */}
            <Divider sx={{ width: '100%', marginTop: 3 }} />

            {/* Boolean Fields Form */}
            <Form
                schema={booleanFieldsSchema}
                uiSchema={booleanFieldsUiSchema}
                formData={formData}
                onChange={handleChange} // Automatically handle changes and reflect them in the context
                validator={validator}
                disabled={templateUserId !== getUserId()} // Disable form if user doesn't match
                liveValidate // Validates the form as changes are made
                showErrorList={false} // Do not show the error list at the top
                templates={{
                    ButtonTemplates: {
                        SubmitButton: NoOpSubmitButton // Disable the submit button by rendering null
                    }
                }}
            />
        </div>
    );
};

export default MlCanvasSetup;
