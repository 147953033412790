import React, { useEffect, useState, useRef } from 'react';
import {
    Editor,
    EditorState,
    convertToRaw,
    convertFromRaw,
    CompositeDecorator,
    Modifier,
    RichUtils
} from 'draft-js';
import LinkDialog from './LinkDialog';
import { IconButton, Stack, Divider, Box } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import LaunchIcon from '@mui/icons-material/Launch';
import 'draft-js/dist/Draft.css';
import { useMlCanvas } from '../../MlModels/MlCanvasProvider';
import { getUserId } from '../../MlModels/MlNode';


// Debounce function
function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this, args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (immediate && !timeout) func.apply(context, args);
    };
}

// Strategy for finding link entities
const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
};

// Link component
const Link = (props) => {
    const { contentState, entityKey, children } = props;
    const { url } = contentState.getEntity(entityKey).getData();
    const { goTarget, loadCanvas, saveToDatabase, quanyxStorage, showSlide, closeAllModals, loadPublicCanvas } = useMlCanvas();

    let linkColor = "#3b5998"; // Default link color
    const prefix = url.split(":")[0];
    switch (prefix) {
        case 'image':
            linkColor = "red";
            break;
        case 'canvas':
            linkColor = "blue";
            break;
        case 'author':
            linkColor = "green";
            break;
    }

    const handleAction = (e) => {
        e.preventDefault();
        const target = url.split(":").pop();
        const targetValue = isNaN(Number(target)) ? target : Number(target);
        switch (prefix) {
            case 'image':
                showSlide('image', target);
                break;
            case 'qdraft':
                showSlide('qdraft', target);
                break;
            case 'author':
            case 'canvas':
                const userId = getUserId();
                if (!userId) {
                    loadPublicCanvas(targetValue);
                    closeAllModals();
                } else {
                    saveToDatabase().then(() => loadCanvas(targetValue));
                    closeAllModals();
                }
                break;
            case 'http':
            case 'https':
                window.open(url, "_blank", "noopener,noreferrer");
                break;
            default:
                goTarget(targetValue);
                break;
        }
    };

    if (prefix === 'img') {
        const urlParts = url.substring(4).split("?"); // Split base URL and query params
        const baseUrl = urlParts[0];
        const queryParams = new URLSearchParams(urlParts[1]);

        const imageUrl = baseUrl.startsWith('/') ? `${quanyxStorage}${baseUrl}` : baseUrl;
        const link = queryParams.get('link');
        const copyright = queryParams.get('copy');
        let width = queryParams.get('width');
        width = width || '250px';

        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ textAlign: 'center' }}>
                    <img loading="lazy" src={imageUrl} alt="" onClick={handleAction}
                        style={{ maxWidth: '100%', width: width, height: 'auto', cursor: 'pointer', color: linkColor }} />
                    {copyright && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '0.1rem' }}>
                            <p style={{ textAlign: 'left', fontSize: '9px', margin: 0, color: 'black' }}>
                                {copyright}
                            </p>
                            {link && (
                                <div>
                                    <LaunchIcon onClick={handleAction} style={{ fontSize: '9px' }} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return (
        <a href={url} onClick={handleAction} onContextMenu={handleAction}
            style={{ color: linkColor, textDecoration: 'underline' }} rel="noopener noreferrer">
            {children}
        </a>
    );
};

// Composite decorator
const linkDecorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: Link,
    },
]);

const QDraft = ({ readOnly = false, onChange, value, height = '100%', width = '100%', cancel }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty(linkDecorator));
    const [isLinkDialogOpen, setIsLinkDialogOpen] = useState(false);
    const [initialUrl, setInitialUrl] = useState('');
    const lastExternalValueRef = useRef(null);
    const isMounted = useRef(false);

    useEffect(() => {
        if ((value && value !== lastExternalValueRef.current) || (!isMounted.current && value)) {
            try {
                const contentState = convertFromRaw(JSON.parse(value));
                setEditorState(EditorState.createWithContent(contentState, linkDecorator));
                lastExternalValueRef.current = value;
            } catch (error) {
                console.error("Failed to load the editor content from JSON:", error);
            }
        }
        if (!isMounted.current) {
            isMounted.current = true;
        }
    }, [value]);

    useEffect(() => {
        const editorElement = document.querySelector('.DraftEditor-root');
        if (editorElement) {
            editorElement.addEventListener('copy', handleCopy);
            editorElement.addEventListener('paste', handlePaste);
        }
        return () => {
            if (editorElement) {
                editorElement.removeEventListener('copy', handleCopy);
                editorElement.removeEventListener('paste', handlePaste);
            }
        };
    }, [editorState]);

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            handleChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const handleChange = (newEditorState) => {
        setEditorState(newEditorState);
        debounceSerializeAndCallback(newEditorState);
    };

    const debounceSerializeAndCallback = debounce((newState) => {
        const contentState = newState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const rawJson = JSON.stringify(rawContentState);
        if (rawJson !== lastExternalValueRef.current) {
            lastExternalValueRef.current = rawJson;
            if (onChange) onChange(rawJson);
        }
    }, 250);

    const openLinkDialog = () => {
        const selection = editorState.getSelection();
        let currentUrl = '';
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const endKey = selection.getEndKey();
            let foundEntity = false;

            const findEntityInSelection = (startBlockKey, endBlockKey) => {
                let blockKey = startBlockKey;
                while (blockKey) {
                    const block = contentState.getBlockForKey(blockKey);
                    let entityKey = null;
                    block.findEntityRanges(
                        (character) => {
                            entityKey = character.getEntity();
                            return (
                                entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
                            );
                        },
                        (start, end) => {
                            const selectionStart = selection.getStartOffset();
                            const selectionEnd = selection.getEndOffset();
                            const isEntitySelected = (selectionStart <= end && selectionEnd >= start) ||
                                (blockKey === startKey && selectionStart <= end) ||
                                (blockKey === endKey && selectionEnd >= start);
                            if (isEntitySelected) {
                                const linkInstance = contentState.getEntity(entityKey);
                                currentUrl = linkInstance.getData().url;
                                foundEntity = true;
                            }
                        }
                    );
                    if (foundEntity || blockKey === endBlockKey) {
                        break;
                    }
                    blockKey = contentState.getKeyAfter(blockKey);
                }
            };

            findEntityInSelection(startKey, endKey);

            if (!foundEntity) {
                // If no entity was found in the direct selection, it remains '' as before
            }
        }
        setInitialUrl(currentUrl);
        setIsLinkDialogOpen(true);
    };

    const addOrEditLink = (url) => {
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();

        let entityKey;
        const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
        entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const contentStateWithLink = Modifier.applyEntity(
            contentStateWithEntity,
            selection,
            entityKey
        );

        const newEditorState = EditorState.push(editorState, contentStateWithLink, 'apply-entity');
        handleChange(EditorState.forceSelection(newEditorState, selection));
        setIsLinkDialogOpen(false);
    };

    const handleCopy = (e) => {
        e.preventDefault();
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const rawJson = JSON.stringify(rawContentState);
        e.clipboardData.setData('application/json', rawJson);
        e.clipboardData.setData('text/plain', editorState.getCurrentContent().getPlainText());
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('application/json');
        if (clipboardData) {
            try {
                const contentState = convertFromRaw(JSON.parse(clipboardData));
                const newEditorState = EditorState.createWithContent(contentState, linkDecorator);
                setEditorState(newEditorState);
            } catch (error) {
                console.error("Failed to parse clipboard content:", error);
            }
        } else {
            const text = e.clipboardData.getData('text/plain');
            if (text) {
                const newContentState = Modifier.insertText(
                    editorState.getCurrentContent(),
                    editorState.getSelection(),
                    text
                );
                const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
                setEditorState(newEditorState);
            }
        }
    };

    return (
        <>
            <div style={{ width: { width }, userSelect: 'none' }}>
                <Editor
                    editorState={editorState}
                    onChange={handleChange}
                    readOnly={readOnly}
                    handleKeyCommand={handleKeyCommand}
                />
            </div>
            {!readOnly && (
                <>
                    <Stack direction="column" spacing={0} alignItems="flex-end">
                        <Divider width='100%' />
                        <IconButton onClick={openLinkDialog} aria-label="add link">
                            <LinkIcon />
                        </IconButton>
                    </Stack>
                    <LinkDialog
                        isOpen={isLinkDialogOpen}
                        onClose={() => setIsLinkDialogOpen(false)}
                        onAddLink={addOrEditLink}
                        initialUrl={initialUrl}
                    />
                </>
            )}
        </>
    );
};

export default QDraft;
