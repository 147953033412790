import React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const ScrollSlider = ({ min = 1, max = 100, value, onChange }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        aria-label="Question Navigation"
        valueLabelDisplay="auto"
      />
    </Box>
  );
};

export default ScrollSlider;