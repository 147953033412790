import React from 'react';
import {
  Stack,
  Box,
  Grid,
} from '@mui/material';
import {
  Title,
  Infoframe as InfoframeWrapper,
  Price,
  Pricetva,
  Tvacomprise,
  BigBold,
  TitleXSmall,
  TitleLight,
  Headline,
} from './StyledStyles';
import {
  formatPrice,
  formatPriceVat,
  formatTitle,
  printComponentAsJpg,
  getListWithReturn,
  ColorDetails,
  formatWarranty,
  formatCo2,
} from './AutodinUtils';
import ImageCanvas from '../../ImageEditorComponent/ImageCanvas';
import VehicleSummary from './VehicleSummary';
import InfoSeller from './InfoSeller';
import OptionPrint from './OptionPrint';
import TextWithTitle from './TextWithTitle';
import ImagePlaceHolder from '../../ImageEditorComponent/Vehicle.webp';
import GaragePlaceHolder from '../../ImageEditorComponent/Garage.webp';


const VehiclePoster = React.forwardRef(({ vehicleData, logo = '', businessData }, ref) => {

  const getFirstImageLink = () => {
    const imageLink = vehicleData?.imageLink;
    const firstImageLink = imageLink ? imageLink.split('\n')[0] : null;
    return firstImageLink ? `${process.env.REACT_APP_S3_URL_BASE}/${firstImageLink}` : ImagePlaceHolder;
  };

  const getBusinessLogo = () => {
    const logoLink = businessData?.businessAvatar;
    return logoLink
      ? `${process.env.REACT_APP_S3_URL_BASE}/${logoLink}`
      : GaragePlaceHolder;
  }

  const test = getBusinessLogo();

  if (!vehicleData) {
    return null; // Or return a loading state
  }

  return (
    <Stack
      ref={ref}
      gap={1}
      justifyContent='center'
      alignItems='center'
      sx={{ backgroundColor: '#ffffff' }}
      padding={3}
    >
      <Box mt={3} mb={3}>
        <ImageCanvas
          src={getBusinessLogo()} height='5rem' backgroundColor='#ffffff'
        />
      </Box>
      <ImageCanvas src={getFirstImageLink()} height='250px' backgroundColor='#ffffff' />
      <BigBold>
        {formatTitle(vehicleData?.brand, vehicleData?.model)}
      </BigBold>

      <Box>
        <Grid container direction="column" spacing={1}>
          <Grid item container alignItems="center">
            <Grid item xs="auto">
              <Headline>€ {formatPrice(vehicleData.price)}</Headline>
            </Grid>
            <Grid item xs="auto" style={{ marginLeft: "16px" }}>
              <TitleLight>- {vehicleData.taxDeductible ? "Tva comprise" : "TTC"}</TitleLight>
            </Grid>
          </Grid>

          {vehicleData.taxDeductible && (
            <Grid item container alignItems="center">
              <Grid item xs="auto">
                <Headline>€ {formatPriceVat(vehicleData.price)}</Headline>
              </Grid>
              <Grid item xs="auto" style={{ marginLeft: "16px" }}>
                <TitleLight>- Hors TVA</TitleLight>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>


      <VehicleSummary vehicleData={vehicleData} />
      <TitleXSmall>{formatCo2(vehicleData.co2)}</TitleXSmall>


      <Box mt={2} mb={1}>
        <Stack direction='row' gap={10} alignItems='center'>

          <InfoSeller businessData={businessData} />
        </Stack>
      </Box>
      {vehicleData?.warranties &&
        <Box width='100%'>
          <Title>{formatWarranty(vehicleData.warranties)}</Title>
          <TextWithTitle title='Commentaires' text={vehicleData?.comments} />
          <OptionPrint value={vehicleData} />
        </Box>
      }
    </Stack>
  );
});

export default VehiclePoster;
