import React from 'react';
import { Box, TextField, Radio, RadioGroup, FormControlLabel, Typography } from '@mui/material';

const Header = ({ title, comments, status, onTitleChange, onCommentsChange, onStatusChange }) => {
    return (
        <Box display="flex" flexDirection="column" gap={0} padding={0} borderBottom="1px solid #ccc">
            {/* Status Options (Radio Buttons) */}
            <RadioGroup
                row // Set RadioGroup to row orientation
                value={status}
                onChange={(e) => onStatusChange(e.target.value)}
            >
                <FormControlLabel value="editing" control={<Radio />} label="Edition" />
                <FormControlLabel value="published" control={<Radio />} label="Publiée" />
                <FormControlLabel value="archived" control={<Radio />} label="Archivée" />
            </RadioGroup>
            {/* Title Field */}
            <TextField
                label="Dénomination"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e) => onTitleChange(e.target.value)}
                sx={{ marginBottom: 2 }}
            />

            {/* Comments Field */}
            <TextField
                label="Commentaires"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={comments}
                onChange={(e) => onCommentsChange(e.target.value)}
                sx={{ marginBottom: 2 }}
            />
        </Box>
    );
};

export default Header;
