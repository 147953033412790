// Define all the tiers (both free and paid)
export const userCredits = {
    aiCreditsGift: {
        name: 'gift',
        promptTokensCredits: 10000,  // 10,000 tokens for input (roughly 7,500 words)
        completionTokensCredits: 10000,  // 10,000 tokens for output
        reasoningTokensCredits: 5000,  // Tokens for reasoning-based tasks like advanced completions
        ttsCredits: 3000,  // characters
        transcriptCredits: 3000,  // 10 minutes of transcription
        imageCredits: 10  // 1 credit = 1 image
    },
    aiCreditsVip: {
        name: 'vip',
        promptTokensCredits: 10000000,  
        completionTokensCredits: 10000000,  
        reasoningTokensCredits: 50000000,  
        ttsCredits: 1000000,  
        transcriptCredits: 1000000,
        imageCredits: 1000  // 1 credit = 1 image  
    }
};

// Function to add credits to the user's account
export const addCredits = (userData, creditsObject) => {
    if (!userData || !userData.aiCredits) {
        console.error('User data or AI credits not loaded.');
        return userData;
    }

    // Clone the current aiCredits to modify
    const updatedCredits = { ...userData.aiCredits };

    // Iterate over the passed credit properties and add them
    Object.keys(creditsObject).forEach((creditType) => {
        if (updatedCredits[creditType] !== undefined) {
            // Add the new credit value
            updatedCredits[creditType] += creditsObject[creditType];
        } else {
            console.warn(`Credit type "${creditType}" not found in user's aiCredits.`);
        }
    });

    // Return updated userData with the added credits
    return { ...userData, aiCredits: updatedCredits };
};

export const removeChatGptCredits = (userData, chatCompletionUsage) => {
    const creditsToRemove = {
        promptTokensCredits: chatCompletionUsage.prompt_tokens,
        completionTokensCredits: chatCompletionUsage.completion_tokens,
    };

    // Use the private removeCredits function
    return removeCredits(userData, creditsToRemove);
};

// Function to remove credits from the user's account
export const removeCredits = (userData, creditsObject) => {
    if (!userData || !userData.aiCredits) {
        console.error('User data or AI credits not loaded.');
        return userData;
    }

    // Clone the current aiCredits to modify
    const updatedCredits = { ...userData.aiCredits };

    // Iterate over the passed credit properties and subtract them
    Object.keys(creditsObject).forEach((creditType) => {
        if (updatedCredits[creditType] !== undefined) {
            // Subtract the new credit value, ensuring it doesn't go below 0
            updatedCredits[creditType] -= creditsObject[creditType];
            if (updatedCredits[creditType] < 0) {
                updatedCredits[creditType] = 0;
            }
        } else {
            console.warn(`Credit type "${creditType}" not found in user's aiCredits.`);
        }
    });

    // Return updated userData with the removed credits
    return { ...userData, aiCredits: updatedCredits };
};

// Function to reset the user's credits to a specific tier
export const resetCredits = (userData, creditsTierName) => {
    if (!userCredits[creditsTierName]) {
        console.error(`Credit tier "${creditsTierName}" not found in userCredits.`);
        return userData;
    }

    if (!userData) {
        console.error('User data not loaded.');
        return userData;
    }

    // Get the credits for the specified tier
    const newCredits = { ...userCredits[creditsTierName] };

    // Return updated userData with reset credits
    return { ...userData, aiCredits: newCredits };
};

// Function to assign default credits if not present
export const assignDefaultCredits = (userData) => {
    if (!userData.aiCredits) {
        userData.aiCredits = userCredits.aiCreditsGift;
    }

    if (!userData.role) {
        userData.role = 'normal';
    }

    // VIP or superUser should always have aiCredits
    if (userData.role === 'vip' || userData.role === 'superuser') {
        userData.aiCredits = userCredits.aiCreditsVip;
    }

    return userData;
};
