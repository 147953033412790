import React, { useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import LabelIcon from '../base/LabelIcon';
import First from '@mui/icons-material/SkipPreviousRounded';
import Previous from '@mui/icons-material/ForwardRounded';
import Next from '@mui/icons-material/ForwardRounded';
import Last from '@mui/icons-material/SkipNextRounded';
import Add from '@mui/icons-material/AddCircleRounded';
import Delete from '@mui/icons-material/RemoveCircleRounded';
import QuestionType from '@mui/icons-material/QuestionAnswerRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import Answer from '@mui/icons-material/Visibility';
import Question from '@mui/icons-material/VisibilityOff';
import Edit from '@mui/icons-material/Edit';
import Import from '@mui/icons-material/FileUploadRounded';

// Import the Qwanyz Color Palette
import { QWANYZ_COLORS } from './QwanyzColorPalette';

const Toolbox = ({ onIconClick, questionType, onQuestionTypeChange, onImportQuestions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMode, setActiveMode] = useState('edit'); // Track the active mode: 'question', 'answer', or 'edit'
  const [viewIconColor, setViewIconColor] = useState(QWANYZ_COLORS.black); // Color for the view icons

  const tools = [
    { icon: activeMode === 'question' ? Answer : Question, text: 'Preview', action: 'toggleQuestionAnswer', mode: 'questionOrAnswer', color: viewIconColor },
    { icon: Edit, text: 'Edit', action: 'edit', mode: 'edit' },
    { icon: First, text: 'First', action: 'firstPage' },
    { icon: Previous, text: 'Previous', action: 'skipPrevious', transform: 'rotate(180deg)' },
    { icon: Next, text: 'Next', action: 'skipNext' },
    { icon: Last, text: 'Last', action: 'lastPage' },
    { icon: Import, text: 'Import', action: 'import' },
    { icon: Add, text: 'Add', action: 'add' },
    { icon: FileCopyRoundedIcon, text: 'Clone', action: 'duplicate' },
    { icon: QuestionType, text: 'Type', action: 'questionType', openMenu: true },
    { icon: Delete, text: 'Delete', action: 'remove', color: QWANYZ_COLORS.coral },
  ];

  // Handle click and pass the name of the icon to the parent
  const handleIconClick = (tool, event) => {
    if (tool.action === 'import') {
      // Trigger file input click for Import action
      document.getElementById('import-file-input').click();
    } else if (tool.openMenu) {
      handleMenuOpen(event);
    } else if (tool.mode) {
      if (tool.mode === 'questionOrAnswer') {
        const newMode = activeMode === 'question' ? 'answer' : 'question';
        setActiveMode(newMode);
        setViewIconColor(QWANYZ_COLORS.disableled); // Set the view icons to grey when toggled
        if (onIconClick) {
          onIconClick(newMode === 'question' ? 'showQuestion' : 'showAnswer');
        }
      } else {
        setActiveMode(tool.mode);
        setViewIconColor(QWANYZ_COLORS.black); // Reset view icons to black in edit mode
        if (onIconClick) {
          onIconClick(tool.action);
        }
      }
    } else {
      if (onIconClick) {
        onIconClick(tool.action);
      }
    }
  };

  // Handle file import for questions
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = JSON.parse(e.target.result);
        if (onImportQuestions) {
          onImportQuestions(data || []); // Pass imported questions array to parent component
        }
      };
      reader.readAsText(file);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleQuestionTypeChange = (type) => {
    if (onQuestionTypeChange) {
      onQuestionTypeChange(type);
    }
    handleMenuClose();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      {tools.map((tool) => (
        <LabelIcon
          key={tool.text}
          icon={tool.icon}
          label={tool.text}
          onClick={(event) => handleIconClick(tool, event)}
          color={tool.color}
          transform={tool.transform}
          disabled={tool.mode && tool.mode === activeMode && tool.mode !== 'questionOrAnswer'}
        />
      ))}

      {/* Hidden file input for Import functionality */}
      <input
        type="file"
        id="import-file-input"
        style={{ display: 'none' }}
        accept=".json"
        onChange={handleFileChange}
      />

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={() => handleQuestionTypeChange('basic')}>Basic recall</MenuItem>
        <MenuItem onClick={() => handleQuestionTypeChange('multiple')}>Multiple Choices</MenuItem>
        <MenuItem onClick={() => handleQuestionTypeChange('redact')}>Redacted text</MenuItem>
        <MenuItem onClick={() => handleQuestionTypeChange('image')}>Image Flip</MenuItem>
      </Menu>
    </Box>
  );
};

export default Toolbox;
