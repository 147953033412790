import React from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Box, useMediaQuery } from '@mui/material';
import PricingCard from './PricingCard';

const PricingDialog = ({ open, handleClose }) => {
  const isSmallScreen = useMediaQuery('(max-width:900px)');

  // JSON data for the cards
  const plans = [
    {
      title: "Free",
      price: "$0/month/seat",
      description: "Powerful AI features to create & improve your content everywhere you work online.",
      features: [
        "1 user seat",
        "Unlimited Templates",
        "Qwanyx Chat",
        "Unlimited Canvas",
        "20,000 tokens included/month",
        "5 images included",
        "10 minutes transcript",
        "5 minutes text to voice",
        "Project managment",
      ]
    },
    {
      title: "Plus",
      price: "$29/month/seat",
      description: "Advanced AI features to create content for multiple brands & collaborate on campaigns.",
      features: [
        "1 user seat",
        "Unlimited Templates",
        "Collaboration & Networking",
        "Qwanyx Chat",
        "Unlimited Canvas",
        "200,000 AI tokens included/month",
        "100 images included",
        "200 minutes transcript",
        "Project managment"
      ]
    },
    {
      title: "Business",
      price: "$59/month/seat",
      description: "Personalized AI features with additional control, security.",
      features: [
        "Unlimited feature usage",
        "Collaboration",
        "Networking",
        "Performance Analytics & Insights",
        "Custom Style Guides with X-ray view",
        "Enterprise-grade security & governance",
        "Advanced admin panel with permissions",
        "Custom workflows and templates",
        "API access",
        "Dedicated account management team with premium support"
      ]
    }
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>Pricing Plans</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', padding: '20px', display: 'flex', justifyContent: 'center' }}>
          <Grid container spacing={4}>
            {plans.map((plan, index) => (
              <Grid item xs={12} md={4} key={index} sx={{ display: 'flex' }}>
                {/* Ensure PricingCard takes full height */}
                <PricingCard plan={plan} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PricingDialog;
