// ToolBar.js
import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import First from '@mui/icons-material/Filter1';
import MlFileUploader from '../components/Mainlist/Dialogs/MlFileUploader';
import DeleteIcon from '@mui/icons-material/Delete';
import TreeIcon from '@mui/icons-material/AccountTree';
import { uploadFileToServer, getUserId } from '../MlModels/MlNode';

const ToolBar = ({ node, onChange, selectedImage, moveImageToFirst, deleteImage }) => {
  const handleFileUpload = (fileName) => {
    const existingLinks = node.imageLink ? node.imageLink.split('\n') : [];
    existingLinks.push(fileName);
    onChange('imageLink', existingLinks.join('\n'));
  };

  return (
    <Box sx={{ height: '2rem', backgroundColor: 'black', display: 'flex', alignItems: 'center' }}>
      <Stack direction="row" spacing={1} sx={{ ml: 1 }}>
        <IconButton sx={{ color: 'white' }} onClick={moveImageToFirst}>
          <First />
        </IconButton>
        <MlFileUploader
          color='white'
          onUpload={handleFileUpload}
          folderPath={`IMAGES/autodin/`}
          userId={getUserId()}
          nodeId={node?._id.toString()}  // Convert BSON ObjectId to string
          accept="image/*"
        />
        <IconButton sx={{ color: 'white' }} onClick={deleteImage}>
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Box>
  );
};

export default ToolBar;
