import React from 'react';
import { Card, CardContent, Typography, List, ListItem, Button, Box } from '@mui/material';

const PricingCard = ({ plan }) => {
  const { title, price, description, features } = plan;

  return (
    <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="h6" color="text.secondary">
          {price}
        </Typography>
        <Typography variant="body2" sx={{ margin: '20px 0' }}>
          {description}
        </Typography>

        {/* Move Button before Features */}
        <Box sx={{ margin: '20px 0' }}>
          <Button variant="contained" color="primary" fullWidth>
            {title === 'Business' ? 'Contact Sales' : 'Start Free 7-Day Trial'}
          </Button>
        </Box>

        {/* Feature List */}
        <List>
          {features.map((feature, index) => (
            <ListItem key={index}>{feature}</ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
