import React, { useRef, useState, useEffect } from "react";
import AutoPartsFooter from './AutoPartsFooter';
import AutoDinList from '../autodin/AutoDinList';
import BusinessList from '../../business/BusinessList';
import SearchBox from '../SearchBox';
import AddButton from "../AddButton";
import TabPanelStyled from '../TabPanelStyled';
import AutoPartsLogo from './AutoPartsLogo';
import CarForm from "../autodin/CarForm";
import VehicleDetails from '../autodin/VehicleDetails';
import AutodinMenu from '../autodin/AutodinMenu';
import BusinessBoard from "../../business/BusinessBoard";
import {
    Stack,
    Box,
    Button,
    Typography,
    Paper
} from '@mui/material';
import { getUserMail } from '../../MlModels/MlNode';
import { getEmbedding } from '../../MlModels/GPTembedding';
import { useUser } from '../../MlModels/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import DataMatchEditor from "./DataMatchEditor";
import { RAMS_COLORS } from '../base/ColorPalette';

const AutoPartsLandingPage = ({ bgColor = 'rgb(230,230,230)', loggedIn = false }) => {
    const { selectedNodeId: urlNodeId } = useParams(); // Extract selectedNodeId from the URL
    const [selectedNodeId, setSelectedNodeId] = useState(null);
    const [isCarFormVisible, setCarFormVisible] = useState(false);
    const [selectedBusinessId, setSelectedBusinessId] = useState(null);
    const [refreshList, setRefreshList] = useState(false);
    const [embedding, setEmbedding] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0); // "Edit" tab is selected by default (0 index)
    const [mainSelectedTab, setMainSelectedTab] = useState(1); // "Vehicules" tab is selected by default (1 index for vehicles)
    const navigate = useNavigate();
    const { userData } = useUser();
    const searchInputRef = useRef(null);
    const [isBusinessDashboardOpen, setBusinessDashboardOpen] = useState(false);

    useEffect(() => {
        if (selectedNodeId !== null) {
            setCarFormVisible(true);
        }
    }, [selectedNodeId]);
    // Update the state when the URL contains a selectedNodeId
    useEffect(() => {
        if (urlNodeId) {
            handleCardClick(urlNodeId); // Trigger the card click
        }
    }, [urlNodeId]);

    // On component mount, ensure "Edition" tab and "Vehicules" tab are selected for logged-in users
    useEffect(() => {
        if (loggedIn) {
            // Simulate the click on the "Edit" tab to apply filtering
            setSelectedTab(0); // Select "Edition" tab by default (tab index 0 for Edit)
            setMainSelectedTab(1); // Select "Vehicules" tab by default (tab index 1 for Vehicles)
            setRefreshList(prev => !prev); // Trigger a refresh in AutoDinList with the correct filtering for the current business
        }
    }, [loggedIn]);

    const handleBusinessDashboardOpen = () => {
        setBusinessDashboardOpen(true);
    };

    const handleBusinessDashboardClose = () => {
        setBusinessDashboardOpen(false);
        setRefreshList(prev => !(prev));
    };

    const handleAddButtonClick = () => {
        setSelectedNodeId(null);
        setCarFormVisible(true);
    };

    const handleAddGarageButtonClick = () => {
        setSelectedBusinessId(null);
        setTimeout(() => {
            handleBusinessDashboardOpen();
        }, 0);
    };

    const handleCloseCarForm = () => {
        setCarFormVisible(false);
        setRefreshList(prev => !prev);
    };

    // This function triggers when a card is clicked, setting the selectedNodeId
    const handleCardClick = (selectedNodeId) => {
        setSelectedNodeId(selectedNodeId); // Set the clicked node ID
        setCarFormVisible(true); // Open the car form/details when clicked
    };

    const handleBusinessClick = (businessId) => {
        setSelectedBusinessId(businessId);
        handleBusinessDashboardOpen();
        setRefreshList(prev => !prev);
    };

    const mainTabsLabels = ["Vendeurs", "Vehicules"];
    const tabLabels = ["Edition", "Archive", "En ligne", "Public"];

    return (
        <>

            <Stack
                alignItems={'center'}
                sx={{
                    backgroundColor: bgColor,
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >
                <Stack mt={3} alignItems={'center'} position="relative" width="100%">
                    <AutoPartsLogo width='12rem' />
                    {loggedIn &&
                        <Typography
                            variant="h7"
                            component="h2"
                            mt={2}
                        >
                            {`Editeur: ${getUserMail() || ''}`}
                        </Typography>
                    }
                    <Box position="absolute" top={0} right={0}>
                        <AutodinMenu />
                    </Box>
                </Stack>
                <Stack
                    sx={{
                        width: '98%',
                        maxWidth: '920px',
                    }}
                >
                    <Stack mt={0} alignItems='flex-end'>
                        <Box
                            mt={1}
                            mb={1}
                            sx={{
                                width: '100%',
                            }}
                        >
                            {loggedIn && (
                                <>
                                    <Paper backgroundColor={RAMS_COLORS.lightBeige}>
                                        <Box padding={2} >
                                            <DataMatchEditor />
                                        </Box>
                                    </Paper>
                                </>
                            )}

                            {loggedIn && mainSelectedTab === 0 && (

                                <BusinessList
                                    elevation={2}
                                    onRowClick={handleBusinessClick}
                                    refresh={refreshList}
                                    searchEmbedding={embedding}
                                    selectedTab={selectedTab}
                                    identity="autodin"
                                    type="businessData"
                                />
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <AutoPartsFooter />

            {/* Show the Car Form or Vehicle Details based on whether a card was clicked */}
            {isCarFormVisible && loggedIn && selectedTab !== 3 ? (
                <CarForm
                    open={isCarFormVisible}
                    onClose={handleCloseCarForm}
                    nodeId={selectedNodeId} // This can be null when adding a new car
                    loggedIn={loggedIn}
                />
            ) : (
                selectedNodeId && (
                    <VehicleDetails
                        open={isCarFormVisible}
                        onClose={handleCloseCarForm}
                        nodeId={selectedNodeId}
                        loggedIn={loggedIn}
                    />
                )
            )}
            <BusinessBoard
                open={isBusinessDashboardOpen}
                handleClose={handleBusinessDashboardClose}
                selectedBusinessId={selectedBusinessId}
                identity='autodin'
            />
        </>
    );
};

export default AutoPartsLandingPage;
