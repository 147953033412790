import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

// Helper function to generate marks dynamically based on step value
const generateMarks = (min, max, step) => {
    const marks = [];
    for (let i = min; i <= max; i += step) {
        marks.push({
            value: i,
            label: `${i}°C`,
        });
    }
    return marks;
};

// The component itself
export default function DiscreteSliderValues({ min = 0, max = 100, step = 10, name, onChange }) {
    const marks = generateMarks(min, max, step);

    // Internal handler for slider change
    const handleSliderChange = (event, value) => {
        onChange(name, value);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ marginTop: '18px' }}>
                <Slider
                    aria-label="Always visible"
                    defaultValue={min}
                    valueLabelDisplay="on"
                    step={step}
                    min={min}
                    max={max}
                    onChange={handleSliderChange}
                />
            </Box>
        </Box>
    );
}
