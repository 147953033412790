// qwanyxRender/qwanyxRender.js
import React from 'react';
import { QCard, Stack } from 'qwanyx-core'; // Assume qwanyx-core exposes all the components

export function qwanyxRender(data) {
  if (!data) {
    return null;
  }

  const { type, props } = data;

  switch (type) {
    case 'QCard':
      return (
        <QCard {...props} style={{ ...props.style, flex: 1 }}>
          {props.children && props.children.map((child, index) => (
            <React.Fragment key={index}>{qwanyxRender(child)}</React.Fragment>
          ))}
        </QCard>
      );
    case 'Stack':
      return (
        <Stack {...props} style={{ ...props.style, display: 'flex', flexGrow: 1 }}>
          {props.children && props.children.map((child, index) => (
            <React.Fragment key={index}>{qwanyxRender(child)}</React.Fragment>
          ))}
        </Stack>
      );
    case 'Text':
      return (
        <div style={{ ...props.style }}>
          {props.content}
        </div>
      );
    default:
      console.warn(`Unknown component type: ${type}`);
      return null;
  }
}
