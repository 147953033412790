import React, { useState, useEffect } from 'react';
import { List, Stack, Paper, Switch } from '@mui/material';
import { getNodesList } from '../../MlModels/MlNode';
import NodeCard from '../qwanyx/NodeCard';
import { useAuth } from "../../AuthContext";
import { RAMS_COLORS } from '../base/ColorPalette';
import { getNode, getUserId } from '../../MlModels/MlNode';

const CourseList = ({  onCardClick, refresh }) => {
    const { currentUser } = useAuth();
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);

    const userId = currentUser ? currentUser.id : null;
    const bgColor = RAMS_COLORS.lightBeige;

    const customMatch = {
        course: true,
        tags: { $regex: '(^|,)blog(,|$)', $options: 'i' }
    };

    const customProject = {
        _id: 1,
        title: 1,
        brief: 1,
        createdAt: 1,
        imageLink: 1
    };

    useEffect(() => {
        const fetchNodes = async () => {
            try {
                const nodesList = await getNodesList(userId, customMatch, customProject);
                const sortedNodes = nodesList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                setNodes(sortedNodes);
            } catch (error) {
                console.error('Failed to fetch nodes:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNodes();
    }, [userId, refresh]);

    return (
            <Stack direction="column" width="100%" maxHeight="100%" overflow="auto">
                <List sx={{ width: '100%', bgcolor: bgColor}}>
                    {nodes.map((node) => (
                        <Stack
                            key={node._id.toString()}
                            direction="row"
                            alignItems="center"
                            sx={{ width: '100%', padding: 0, backgroundColor: RAMS_COLORS.lightBeige }}
                        >
                            {/* NodeCard fills the available space */}
                            <NodeCard 
                                node={node} 
                                onCardClick={onCardClick} 
                                sx={{ flex: 1}} 
                            />
                            {/* Switch aligned to the right */}
                            <Switch />
                        </Stack>
                    ))}
                </List>
            </Stack>
    );
};

export default CourseList;
