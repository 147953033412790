import { createContext, useState } from 'react';
import translationsEn from './en.json';
import translationsFr from './fr.json';

const LanguageContext = createContext();

const translations = {
  en: translationsEn,
  fr: translationsFr,
};

export const LanguageProvider = ({ children, initialLanguage = 'en' }) => {
  const [language, setLanguage] = useState(initialLanguage);

  const t = (key) => translations[language][key] || key;

  return (
    <LanguageContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
