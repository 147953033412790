import React from 'react';
import MlImage from '../base/MlImage'; // Adjust the path according to your file structure

const Logo = ({width = '1rem'}) => {
  return (
    <div>
      <MlImage
      width = {width}
        src="https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com/IMAGES/upload/645b5e00c7b61df466430901/node_667d69d4dac9c828db94fdb1/AutoDinLogo.png?v=1719495169"
        alt="Description of image"
      />
    </div>
  );
};

export default Logo;
