import React, { useState, useEffect, memo } from 'react';
import { Handle, Position, NodeToolbar } from 'reactflow';
import styled from '@emotion/styled';

import {
    TextareaAutosize,
    Input,
    Stack,
    Paper,
    Tooltip,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from '@mui/material';

import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MlNodeIcon from './MlNodeIcon';
import LinkIcon from '@mui/icons-material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { tooltipClasses } from '@mui/material/Tooltip';
import MlMenuIcons from '../MlIconMenu';
import SendGPTIcon from '@mui/icons-material/SmartToy';
import SendGPTProjectIcon from '@mui/icons-material/AccountTree';
import PlaySpeech from '@mui/icons-material/VolumeUp';
import StopIcon from '@mui/icons-material/Stop';
import { getIconByTypeAndState } from '../MlIconMenu';

import { getAnswer, getProject } from '../../../MlModels/MlGPT';

import { createNode, updateNode, createNodeForTextEditor } from '../../../MlModels/MlNode';
import { Note } from '../MlBaseIcons';
import SmtpSettingsDialog from '../Dialogs/SmtpSettingsDialog';
import TextEditorDialog from '../Dialogs/TextEditorDialog';
import MlCardMedia from '../MlCardMedia';
import MlVideoPlayer from '../MlVideoPlayer';
import MlToolTip from './MlToolTip';
import MlBriefViewOnlyDialog from '../Dialogs/MlBriefViewOnlyDialog';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';
import { nodeGet } from '../../../MlModels/MlNode';

const HANDLE_ALPHA = 0;// Replace this value with the desired alpha value between 0 and 1
const NODE_BG_ALPHA = 1;


//const S3_URL_BASE = 'https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com';

export default memo(({ data, isConnectable, updateNodeData, id, viewOnly, getNodesAndEdges }) => {

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    const [localData, setLocalData] = useState(() => ({
        ...createNode(data), // Use the createNode result as the base
        id: id, // Set the initial id directly
    }));

    //this is to manage the remount of the node editor when a node is cloned.
    const [dialogKey, setDialogKey] = useState(Date.now());
    const handleRemount = () => {
        setDialogKey(Date.now()); // Change the key to force remount
    };
    const [currentIcon, setCurrentIcon] = useState(() => {
        return localData.type && typeof localData.state !== 'undefined'
            ? getIconByTypeAndState(localData.type, localData.state)
            : Note;
    });

    const {
        goTarget,
        loadCanvas,
        saveToDatabase,
        changeBackgroundColor,//this is a global change that will affect all canvas until changed back
        showSlide,
        setNodes,
        setEdges,
        setCurrentNode,
        refreshCanvas
        //computeStates
    } = useMlCanvas();

    const handleBackgroundColorChange = () => {
        if (localData.BackgroundColor) {
            changeBackgroundColor(localData.BackgroundColor);
        }
    };

    //const [showBrief, setShowBrief] = useState(true); //this will not show the brief by default
    const [showIconMenu, setShowIconMenu] = useState(false);

    const [currentType, setCurrentType] = useState(localData.type || null);  // For type of task or project etc.
    const [dialogType, setDialogType] = useState(null);
    const [currentState, setCurrentState] = useState(localData.state || null); // For state of task

    const [textAreaValue, setTextAreaValue] = useState(localData.brief || '');//GPT state

    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(null);


    useEffect(() => {
        if (localData?.BackgroundColor) {
            changeBackgroundColor(localData.BackgroundColor);
        }
    }, [localData?.BackgroundColor, changeBackgroundColor]);

    useEffect(() => {
        if (localData?.speech) {
            // Create a copy of localData without the 'speech' property
            const { speech, ...updatedData } = localData;
            setLocalData(updatedData); // Update localData without the speech property
        }
    }, []);


    const getProperty = (propertyName, defaultValue = null) => {
        return localData.hasOwnProperty(propertyName) ? localData[propertyName] : defaultValue;
    };

    const getStackStyles = (justification) => {
        switch (justification) {
            case 'left':
                return { justifyContent: 'flex-start', direction: 'row-reverse' };
            case 'right':
                return { justifyContent: 'flex-end', direction: 'row' };
            case 'center':
            default:
                return { justifyContent: 'center', direction: 'row' };
        }
    };

    const { justifyContent, direction } = getStackStyles(localData.justification);

    const handleSendGPTClick = async () => {
        setIsLoading(true); // show loading modal 

        // Retrieve nodes and edges from the parent component
        const answer = await getAnswer(textAreaValue);


        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            setTextAreaValue(prevValue => `${prevValue}\n${answer}`);
        }
    };

    const handleGetProjectGPTClick = async () => {
        setIsLoading(true); // show loading modal

        const answer = await getProject(textAreaValue, 5, 7);

        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            navigator.clipboard.writeText(answer).then(function () {
                console.log("Clipboard successfully set");
                setIsFinished(true); // show finish modal
            }, function (err) {
                console.error("Unable to write to clipboard. Error:", err);
            });
        }
    };

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };

    useEffect(() => {
        setTextAreaValue(localData.brief || '');
    }, []);

    useEffect(() => {
        if (localData.type && typeof localData.state !== 'undefined') { // Check if type and state exist
            const newIcon = getIconByTypeAndState(localData.type, localData.state);
            setCurrentIcon(() => newIcon);
        }
    }, [localData.type, localData.state, localData]);

    const toggleIconMenu = (event) => {
        //setShowBrief(true); // This should show the NodeToolbar
        event.stopPropagation();
        if (viewOnly) {
            return;
        }
        setShowIconMenu(!showIconMenu); // This should toggle the MlMenuIcons
    };



    const handleMenuItemClick = (icon, label, type, state) => {
        setCurrentIcon(() => icon);
        setCurrentType(type);
        setCurrentState(state);
        setShowIconMenu(false);
    
        const updatedData = {
            ...localData,
            type: type,
            state: state,
        };
    
        setLocalData(updatedData);
    
        setTimeout(() => {
            // Call the prop function to update the node and recompute the state of related nodes
            updateNodeData(id, updatedData);  // This is now computeUpdateNodeStates
    
            // Use this to trigger a refresh
            setNodes((prevNodes) => [...prevNodes]);  // Ensure React detects the node update
            setEdges((prevEdges) => [...prevEdges]);
        }, 0);  // Ensure this runs after the state is updated
    };
    

    const handleBlur = (e) => {
        const { name, value } = e.target;
        let updatedField = name === 'inputField' ? 'title' : 'brief';

        const updatedData = {
            ...localData,
            [updatedField]: name === 'brief' ? textAreaValue : value,
        };

        setLocalData(updatedData); // Update localData state

        console.log("Updated data: ", updatedData);  // Log the updated data for debugging
        updateNodeData(id, updatedData);
    };

    useEffect(() => {
        if (currentType !== null) {
            const updatedData = {
                ...localData,
                type: currentType,
                state: currentState
            };

            setLocalData(updatedData);  // Update localData state
            updateNodeData(id, localData); // Update node data in your backend or parent component
        }
    }, [currentType, currentState]);


    const MlMenuIconsClickHandler = (event) => {
        event.stopPropagation(); // This stops the click from reaching the NodeToolbar
    };

    const handleInputDoubleClick = (event) => {
        // Prevent the double-click event from reaching parent elements
        event.stopPropagation();
    };

    const handleDoubleClick = (event) => {
        event.stopPropagation();
        const test = localData;
        setDialogType(localData.type);
        setIsDialogOpen(true); // Show the dialog
    };

    const handleToolTipDoubleClick = (event) => {
        event.stopPropagation(event);
        setDialogType('');
        setIsDialogOpen(true); // Show the dialog
    };


    const base64ToBlob = (base64, mimeType) => {
        const byteString = atob(base64.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([arrayBuffer], { type: mimeType });
    };
    /*
        const handlePlaySpeech = (event) => {
            event.stopPropagation(event);
            playAudioBlob(localData.audioNodeId);
        };
    */
    const S3_URL_BASE = 'https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com';
    //const S3_URL_BASE = process.env.S3_URL_BASE;
    const playAudio = async () => {
        if (isAudioPlaying && audioElement) {
            setIsAudioPlaying(false);
            audioElement.pause();
            audioElement.currentTime = 0;
            return;
        }

        try {
            setIsAudioPlaying(true);
            let audioUrl;

            if (localData.soundLink) {
                // Check if soundLink starts with 'https:' or 'http:'
                if (localData.soundLink.startsWith('https:') || localData.soundLink.startsWith('http:')) {
                    audioUrl = localData.soundLink;
                } else {
                    audioUrl = S3_URL_BASE + localData.soundLink;
                }
            } else if (localData.audioNodeId) {
                const audioNode = await nodeGet(localData.audioNodeId);
                if (audioNode && audioNode.speechData) {
                    const buffer = audioNode.speechData.buffer;
                    const blob = new Blob([buffer], { type: 'audio/mp3' });
                    audioUrl = URL.createObjectURL(blob);
                }
            }

            if (audioUrl) {
                const audio = new Audio(audioUrl);
                setAudioElement(audio);

                audio.play().then(() => {
                    audio.addEventListener('ended', () => {
                        setIsAudioPlaying(false);
                    });
                }).catch(e => console.error('Error playing audio:', e));
            }
        } catch (error) {
            setIsAudioPlaying(false);
            console.error('Error fetching or playing audio:', error);
        }
    };


    const handleBriefSave = (updatedNodeProperties, dbSave) => {
        setLocalData(prevLocalData => {
            // Create a copy of the previous local data
            let newLocalData = { ...prevLocalData };

            // Update newLocalData with updatedNodeProperties
            Object.keys(updatedNodeProperties).forEach(key => {
                newLocalData[key] = updatedNodeProperties[key];
            });

            // Remove properties not present in updatedNodeProperties
            Object.keys(newLocalData).forEach(key => {
                if (!(key in updatedNodeProperties)) {
                    delete newLocalData[key];
                }
            });

            updateNodeData(id, newLocalData); // Update the node data with the new state
            return newLocalData; // Return the new state
        });
    };

    const handleOpenLink = async () => {
        const link = localData.canvasLink;

        let linkType = 'internal'; // Default type
        // Determine the link type based on its prefix
        if (link.startsWith('http://') || link.startsWith('https://')) {
            linkType = 'external';
        } else if (link.startsWith('mailto:')) {
            linkType = 'mailto';
        } else if (link.startsWith('tel:')) {
            linkType = 'tel';
        } else if (link.startsWith('target:')) {
            linkType = 'target';
        } else if (link.startsWith('target:')) {
            linkType = 'node';
        } else if (link.startsWith('image:')) {
            linkType = 'image';
        }
        else if (link.startsWith('qdraft:')) {
            linkType = 'qdraft';
        }
        switch (linkType) {
            case 'image':
                const src = link.split("image:")[1];
                showSlide('image', src);
                break;
            case 'qdraft':
                const propertyName = link.split("qdraft:")[1];
                //setCurrentNode(localData);
                showSlide('qdraft', propertyName, localData);
                break;
            case 'external':
                window.open(link, '_blank');
                break;
            case 'mailto':
            case 'tel':
                window.open(link, '_blank');
                break;
            case 'target':
                const targetSpecifier = link.split('target:')[1];
                const targetIndex = parseInt(targetSpecifier, 10);
                if (!isNaN(targetIndex)) {
                    goTarget(targetIndex); // Directly go to the target without saving the flow
                } else {
                    console.error('Invalid target index:', targetSpecifier);
                }
                break;
            case 'internal':
                // Extract the UUID from the link, assuming the UUID is the part before any '?'
                const uuid = link.split('?')[0]; // This will work for both '12345' and '12345?p=1' formats

                if (viewOnly) {
                    // Construct the public URL dynamically without the query parameters
                    const domain = window.location.origin; // Dynamically get the domain
                    const publicLink = `${domain}/public-canvas/${uuid}`; // Construct the URL with the UUID only
                    window.location.href = publicLink;
                } else {
                    saveToDatabase(); // Save the current flow first
                    loadCanvas(uuid); // Then load the new canvas with the UUID only
                }
                break;
            default:
                console.error('Unrecognized link type:', link);
        }
    };

    const getLinkIcon = (link, defaultIcon) => {
        if (!link) return null;

        const urlPattern = /^(https?|ftp|mailto|tel):/;
        const match = link.match(urlPattern);

        if (match) {
            return match[1]; // return the scheme (e.g., "http", "https", "mailto", "tel")
        }

        return defaultIcon; // return default icon if not a URL
    };

    const linkIcon = localData.linkIcon ? localData.linkIcon : getLinkIcon(localData.canvasLink, "nextIcon");



    function hasTextContent(htmlString) {
        // Create a new DOM element and set its innerHTML to the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Extract the text content from the element
        const text = tempDiv.textContent || tempDiv.innerText || '';

        // Check if the text contains anything other than whitespace
        return text.trim().length > 0;
    }


    //const HtmlContent = ({ html }) => <span dangerouslySetInnerHTML={{ __html: html }} />;

    return (
        <>
            <Stack direction='column' spacing={2} alignItems="center">
                <NodeToolbar isVisible={showIconMenu} position={'bottom'}>
                    {showIconMenu &&
                        <MlMenuIcons
                            onMenuItemClick={handleMenuItemClick}
                            onClick={MlMenuIconsClickHandler}
                        />}
                </NodeToolbar>



                <div
                    style={{ padding: '10px' }}
                    //onClick={toggleSmtpDialog}
                    //onDoubleClick={() => setShowBrief(!showBrief)}
                    onDoubleClick={handleDoubleClick}
                >



                    <Paper elevation={localData.elevation}
                        style={{ padding: '15px', borderRadius: '8px', backgroundColor: `rgba(${localData.backgroundColor})` }}
                    >
                        <div className="custom-drag-handle"
                            style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                        >
                            {(localData.showImage || localData.showVideo) && (
                                <Stack direction='column' spacing={0} alignItems="center">
                                    {localData.showImage && (
                                        <div onDoubleClick={handleToolTipDoubleClick} >
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <MlCardMedia
                                                    size={localData.imageSize}
                                                    imageLink={localData.imageLink}
                                                    imageNodeId={localData.imageNodeId}
                                                    getProperty={getProperty}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {localData.showVideo && (
                                        <MlVideoPlayer
                                            size={localData.videoSize}
                                            mediaLink={localData.videoLink}
                                        />
                                    )}
                                </Stack>
                            )}
                        </div>

                        <Stack spacing={3} alignItems="center">
                            <div className="custom-drag-handle"
                                style={{ width: '100%', display: 'flex', justifyContent }}
                            >
                                <Stack spacing={2} alignItems="center" >
                                    <Stack
                                        direction={direction}
                                        spacing={2}
                                        alignItems='center'
                                    >
                                        {(localData.displayIcon !== false) && (
                                            <div onClick={toggleIconMenu}>
                                                {React.createElement(currentIcon, { fill: '#444444', size: '48px' })}
                                            </div>
                                        )}
                                        {(localData.displayName !== false) && (
                                            <Typography
                                                minWidth={'20px'}
                                                name="inputField"
                                                onBlur={handleBlur}
                                                onDoubleClick={handleToolTipDoubleClick}
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    fontWeight: 'bold',
                                                    fontSize: (localData.nameSize ? localData.nameSize : '24') + 'px'
                                                }}
                                            >
                                                {localData.title || 'Hello world'}
                                            </Typography>
                                        )}
                                        {hasTextContent(localData.brief) && !localData.showBrief && !localData.audioNodeId && (
                                            <LiveHelpIcon
                                                sx={{ fontSize: '32px' }}
                                                onClick={handleToolTipDoubleClick}
                                            />
                                        )}
                                        {
                                            (localData.soundLink || localData.audioNodeId) && (
                                                isAudioPlaying
                                                    ? <StopIcon sx={{ fontSize: '40px' }} onClick={playAudio} />
                                                    : <PlaySpeech sx={{ fontSize: '40px' }} onClick={playAudio} />
                                            )
                                        }

                                        {localData.canvasLink && (
                                            <MlNodeIcon
                                                size={localData.linkIconSize}
                                                onClick={handleOpenLink}
                                                icon={linkIcon}
                                                color={localData.linkIconColor}
                                                alpha={localData.linkIconAlpha}
                                            />
                                        )}
                                        {false && (
                                            <>
                                                <ExpandMoreIcon
                                                    sx={{ fontSize: '32px' }}
                                                    onClick={handleToolTipDoubleClick}
                                                />
                                            </>
                                        )}
                                    </Stack>


                                    {localData.showBrief && (
                                        //tooltip
                                        <MlToolTip text={localData.brief} />
                                    )}

                                </Stack>
                            </div>
                        </Stack>
                    </Paper>
                    <Handle
                        type="target"
                        position={Position.Left}
                        id="c"
                        style={{ background: `rgba(85, 85, 85, ${HANDLE_ALPHA})`, width: '36px', height: '100%', border: '0px', borderRadius: '0%' }}
                        onConnect={(params) => console.log('handle onConnect', params)}
                        isConnectable={isConnectable}
                    />

                    <Handle
                        type="source"
                        position={Position.Right}
                        id="a"
                        style={{ background: `rgba(85, 85, 85, ${HANDLE_ALPHA})`, width: '36px', height: '100%', border: '0px', borderRadius: '0%' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        type="target"
                        position={Position.top}
                        id="b"
                        style={{ background: `rgba(85, 85, 85, ${HANDLE_ALPHA})`, width: '100%', height: '36px', border: '0px', borderRadius: '0%' }}
                        isConnectable={isConnectable}
                    />
                    <Handle
                        type="source"
                        position={Position.Bottom}
                        id="c"
                        style={{ background: `rgba(85, 85, 85, ${HANDLE_ALPHA})`, width: '100%', height: '36px', border: '0px', borderRadius: '0%' }}

                        isConnectable={isConnectable}
                    />
                </div>
                {/* Loading dialog */}
                <Dialog open={isLoading}>
                    <DialogTitle>I'm Thinking</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please wait, this may take some time...
                        </DialogContentText>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>

                {/* Finish dialog */}
                <Dialog
                    open={isFinished}
                    onClick={() => setIsFinished(false)} // Close on outside click
                >
                    <DialogTitle>Finished</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You can now paste the project in a canvas using Ctrl+U.
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {isDialogOpen && dialogType === 'mailsettings' && (
                    <SmtpSettingsDialog

                        open={true}
                        onClose={() => setIsDialogOpen(false)}
                    />
                )}
                {isDialogOpen && (dialogType === 'note' || dialogType === '' || dialogType === null) && (
                    viewOnly
                        ? <MlBriefViewOnlyDialog
                            title={localData.title}
                            content={localData.brief}
                            open={true}
                            onClose={() => setIsDialogOpen(false)}
                        />
                        : <TextEditorDialog
                            key={dialogKey}
                            onRemount={handleRemount}
                            nodeData={localData}
                            open={true}
                            onClose={() => setIsDialogOpen(false)}
                            onSave={handleBriefSave}
                        //getNodesAndEdges={getNodesAndEdges}
                        />
                )}

            </Stack>
        </>

    );
});
