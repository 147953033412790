import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import QwanyzDesktop from './QwanyzDesktop';

function QwanyzButton({ iconSize = "18px", color = "#FFF" }) {
  const [showDashboard, setShowDashboard] = useState(false);

  const handleOpen = () => {
    setShowDashboard(true);
  };

  const handleClose = () => {
    setShowDashboard(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <SchoolRoundedIcon style={{ fontSize: iconSize, color: color }} />
      </IconButton>
      {/* Update prop name to `open` to match the expected prop */}
      <QwanyzDesktop open={showDashboard} handleClose={handleClose} />
    </>
  );
}

export default QwanyzButton;
