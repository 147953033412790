import React, { useState, useEffect } from 'react';
import { Box, Stack, IconButton } from '@mui/material';
import ToolBar from './ToolBar';
import ImageListComponent from './ImageList';
import ImageCanvas from './ImageCanvas';
import { deleteFileFromServer, getUserId } from '../MlModels/MlNode';
import ImagePlaceHolder from './Vehicle.webp';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ImageEditor = ({ node, onChange, loggedIn = false }) => {
  const getFirstImageLink = (imageLinks) => {
    const firstImageLink = imageLinks ? imageLinks.split('\n')[0] : null;
    return firstImageLink ? `${process.env.REACT_APP_S3_URL_BASE}/${firstImageLink}` : ImagePlaceHolder;
  };

  const [imageUrl, setImageUrl] = useState(getFirstImageLink(node?.imageLink));
  const [selectedImage, setSelectedImage] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);

  useEffect(() => {
    setImageUrl(getFirstImageLink(node?.imageLink));
    setSelectedImage(0); // Reset to the first image when the node changes
  }, [node]);

  const handleImageClick = (url, index) => {
    setImageUrl(url);
    setSelectedImage(index);
  };

  const moveImageToFirst = () => {
    if (selectedImage !== null) {
      const imageLinks = node.imageLink.split('\n');
      const selectedLink = imageLinks[selectedImage];
      imageLinks.splice(selectedImage, 1);
      imageLinks.unshift(selectedLink);
      onChange('imageLink', imageLinks.join('\n'));
      setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${selectedLink}`);
      setSelectedImage(0);
    }
  };

  const deleteImage = async () => {
    if (selectedImage !== null) {
      const imageLinks = node.imageLink.split('\n');
      const selectedLink = imageLinks[selectedImage];
      imageLinks.splice(selectedImage, 1);
      onChange('imageLink', imageLinks.join('\n'));

      if (imageLinks.length > 0) {
        if (selectedImage > 0) {
          setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${imageLinks[selectedImage - 1]}`);
          setSelectedImage(selectedImage - 1);
        } else {
          setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${imageLinks[0]}`);
          setSelectedImage(0);
        }
      } else {
        setImageUrl(ImagePlaceHolder);
        setSelectedImage(null);
      }

      try {
        const folderPath = `IMAGES/autodin/`;
        const userId = getUserId();
        const fileNameFromUrl = selectedLink.split('/').pop();
        const fileName = folderPath + userId + fileNameFromUrl;

        const result = await deleteFileFromServer(fileName);
        if (result.success) {
          console.log('File deleted successfully');
        } else {
          console.error('Error deleting file:', result.error);
        }
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    }
  };

  // Swipe handling logic for both touch and mouse events
  const handleStart = (e) => {
    const clientX = e.touches ? e.touches[0].clientX : e.clientX;
    setStartX(clientX);
    setIsMouseDown(true);
  };

  const handleMove = (e) => {
    if (!isMouseDown) return;

    const clientX = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
    const swipeDistance = startX - clientX;

    if (swipeDistance > 50) {
      // Swipe left (show next image)
      handleNextImage();
      setIsMouseDown(false);
    } else if (swipeDistance < -50) {
      // Swipe right (show previous image)
      handlePreviousImage();
      setIsMouseDown(false);
    }
  };

  const handleEnd = () => {
    setIsMouseDown(false);
  };

  const handleNextImage = () => {
    const imageLinks = node?.imageLink.split('\n') || [];
    if (imageLinks.length > 0) {
      const nextIndex = (selectedImage + 1) % imageLinks.length;
      setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${imageLinks[nextIndex]}`);
      setSelectedImage(nextIndex);
    }
  };

  const handlePreviousImage = () => {
    const imageLinks = node?.imageLink.split('\n') || [];
    if (imageLinks.length > 0) {
      const prevIndex = (selectedImage - 1 + imageLinks.length) % imageLinks.length;
      setImageUrl(`${process.env.REACT_APP_S3_URL_BASE}/${imageLinks[prevIndex]}`);
      setSelectedImage(prevIndex);
    }
  };

  return (
    <Stack sx={{ backgroundColor: 'rgb(150,150,150)', height: '100%', position: 'relative' }}>
      {/* Create a Box around the ImageCanvas and IconButtons to handle swipe */}
      <Box
        sx={{ position: 'relative' }}
        onTouchStart={handleStart} // Register touch start
        onTouchMove={handleMove}   // Register touch move/swipe
        onTouchEnd={handleEnd}     // Register touch end
        onMouseDown={handleStart}  // Register mouse down
        onMouseMove={handleMove}   // Register mouse move
        onMouseUp={handleEnd}      // Register mouse up
        onMouseLeave={handleEnd}   // Handle case when mouse leaves the image area
      >
        {/* Left arrow for previous image */}
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translateY(-50%)',
            color: 'white',
            display: { xs: 'none', md: 'block' }, // Only display on medium screens and above (desktop)
            zIndex: 2,
          }}
          onClick={handlePreviousImage}
        >
          <ArrowBackIosIcon fontSize="large" />
        </IconButton>

        <ImageCanvas src={imageUrl} style={{ userSelect: 'none' }} draggable="false" />

        {/* Right arrow for next image */}
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translateY(-50%)',
            color: 'white',
            display: { xs: 'none', md: 'block' }, // Only display on medium screens and above (desktop)
            zIndex: 2,
          }}
          onClick={handleNextImage}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </IconButton>
      </Box>

      {loggedIn && (
        <ToolBar
          node={node}
          onChange={onChange}
          moveImageToFirst={moveImageToFirst}
          selectedImage={selectedImage}
          deleteImage={deleteImage}
        />
      )}

      <Box>
        <ImageListComponent images={node?.imageLink} onImageClick={handleImageClick} />
      </Box>
    </Stack>
  );
};

export default ImageEditor;
