import React from 'react';

// Default Icon Component (used if no icon is provided)
const DefaultIcon = ({ color }) => (
    <svg width="24" height="24" fill={color}>
        <circle cx="12" cy="12" r="10" />
    </svg>
);

export const QIcon = ({ icon: IconComponent = DefaultIcon, label, size = 24, onClick, isDisabled = false, iconColor = 'black' }) => (
    <div 
        onClick={!isDisabled ? onClick : undefined} 
        style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            opacity: isDisabled ? 0.5 : 1,
            fontSize: size,
            color: iconColor, // Apply color to the icon and text
        }}
    >
        <IconComponent size={size} color={iconColor} />
        <span style={{ fontSize: size * 0.5 }}>{label}</span>
    </div>
);

