import React, { useState } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';
import LabelIcon from '../base/LabelIcon';
import Previous from '@mui/icons-material/ArrowBackIosNewRounded';
import Next from '@mui/icons-material/ArrowForwardIosRounded';
import Save from '@mui/icons-material/SaveRounded';
import Delete from '@mui/icons-material/RemoveCircleRounded';
import Process from '@mui/icons-material/BuildCircleRounded';
import { RAMS_COLORS } from '../base/ColorPalette';

const Toolbox = ({ onIconClick }) => {
  const tools = [
    { icon: Previous, text: '', action: 'previous' },
    { icon: Process, text: '', action: 'Convertir', color: RAMS_COLORS.executiveBlue },
    { icon: Save, text: '', action: 'save' },
    { icon: Delete, text: '', action: 'delete', color: RAMS_COLORS.coral },
    { icon: Next, text: '', action: 'next' },
  ];

  // Handle icon clicks and pass the action name to the parent component
  const handleIconClick = (tool) => {
    if (onIconClick) {
      onIconClick(tool.action);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
      {tools.map((tool) => (
        <LabelIcon
          key={tool.text}
          icon={tool.icon}
          label={tool.text}
          onClick={() => handleIconClick(tool)}
          color={tool.color || RAMS_COLORS.black}
        />
      ))}
    </Box>
  );
};

export default Toolbox;
