import React, { useState, useEffect } from 'react';
import { Binary } from 'bson';
import { ObjectId } from 'bson';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ProjectHelp from '@mui/icons-material/HelpCenter';
import ImageFromCanvas from '@mui/icons-material/Filter';
import QwanyzEditor from '../../qwanyz/QwanyzEditor';

import {
    Stack,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    DialogContentText,
    TextField,
    Checkbox,
    FormControlLabel,
    Box,
    IconButton,
    Tooltip
} from '@mui/material';
import ReactQuill from 'react-quill';
import ImageResize from 'quill-image-resize';
import 'react-quill/dist/quill.snow.css';
import SendGPTIcon from '@mui/icons-material/SmartToy';
import SendGPTProjectIcon from '@mui/icons-material/AccountTree';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TitleIcon from '@mui/icons-material/Title';
import TTIIcon from '@mui/icons-material/AddPhotoAlternate';
import MlEditorToolBox from '../MlEditorToolBox';
import MlTitle from '../../base/MlTitle';
import MlNodeTypeEditor from './MlNodeTypeEditor';
import MlNodeDisplayEditor from './MlNodeDisplayEditor';
import MlNodeSoundEditor from './MlNodeSoundEditor';
import MlNodePictureEditor from './MlNodePictureEditor';
import MlCardMedia from '../MlCardMedia';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import MailIcon from '@mui/icons-material/Mail';
import MmsIcon from '@mui/icons-material/Mms';
import VisionIcon from '@mui/icons-material/PreviewRounded';

import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import { uploadFiles } from '../../../AWS/s3Utils'

//import { QStack } from '@QGraph'; not working at this time !!!!
import { GraphBrowser } from 'qwanyx-core';


import MenuIcon from '@mui/icons-material/Menu';
import CanvasIcon from '@mui/icons-material/SchemaRounded';
import { useMlCanvas } from '../../../MlModels/MlCanvasProvider';

import {
    createNode,
    cloneNode,
    nodePut,
    nodeGet,
    getNewId,
    getUserId,
    nodeDelete,
    uploadFileToServer
} from '../../../MlModels/MlNode'

//Functions from my GPT lib
import {
    getAnswer,
    getAnswerNoContext,
    getProject,
    getKnowledge,
    getTitle,
    getSummary,
    getDevelopmement,
    transcribeAudio,
    convertGPTMarkdownToHTML,
    textToSpeechBlob,
    textToImage,
    getNotes,
    getProofRead,
    getMail,
    nodesFromTemplate,
    getCompletionAnswer,
    getAnswerFromImage
} from '../../../MlModels/MlGPT';

import MlTranscribeFile from '../sound/MlTranscribeFile';
import MlRecorder from '../sound/MlRecorder';
import MlTTS from '../sound/MlTTS';
import { useUser } from '../../../MlModels/UserContext';
import { removeChatGptCredits, removeCredits } from '../../../MlModels/UserCredits';
import { getConnectedNodesAndEdges } from "../../../qwanyx2/getConnectedNodesAndEdges";

const Quill = ReactQuill.Quill;
Quill.register('modules/imageResize', ImageResize);

const USER_ID = getUserId();

function LockControl({ locked, toggleLock }) {
    return (
        <IconButton onClick={toggleLock}>
            {locked ? <LockIcon /> : <LockOpenIcon />}
        </IconButton>
    );
}

function TextEditorDialog({
    nodeData, // This should be an object containing all the properties
    open,
    onClose,
    onSave,
    getNodesAndEdges,
    onRemount,
}) {
    const [nodeProperties, setNodeProperties] = useState(nodeData);
    const [isLocked, setIsLocked] = useState(nodeProperties.locked ?? false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [nodeTypeComponentValues, setNodeTypeComponentValues] = useState({});
    const { userData, userCommit, setUserData } = useUser();

    useEffect(() => {
        // When nodeData changes, update the lock state accordingly
        setIsLocked(nodeProperties.locked ?? false);
    }, [nodeData]);

    const handleLockToggle = () => {
        setIsLocked(prevLocked => {
            const newLocked = !prevLocked;
            setNodeProperties(prevProps => {
                const updatedProps = {
                    ...prevProps,
                    locked: newLocked
                };
                // Ensure the onSave function is called after the state has been updated
                onSave(updatedProps);
                return updatedProps;
            });
            return newLocked;
        });
    };

    const handleQuillChange = (content) => {
        setNodeProperties(prevProps => ({
            ...prevProps,
            brief: content,
        }));
    };

    useEffect(() => {
        setNodeProperties(nodeData);
    }, []);

    useEffect(() => {
        // This code runs after nodeProperties has been updated and the component has re-rendered
        console.log("Updated nodeProperties:", nodeProperties);
    }, [nodeProperties]); // This useEffect depends on nodeProperties


    const handleNodeTypeEditorSave = (values) => {
        setNodeProperties(prevProps => {
            // Create a new object to store the updated properties
            let updatedProps = {};

            // Iterate over the values (new properties)
            for (let key in values) {
                // Add/update the properties from values to updatedProps
                updatedProps[key] = values[key];
            }

            // Return the updated properties, which will be set as the new state
            return updatedProps;
        });
    };
    const getProperty = (propertyName, defaultValue = null) => {
        return nodeProperties.hasOwnProperty(propertyName) ? nodeProperties[propertyName] : defaultValue;
    };

    const handleNodeDisplayEditorSave = (updatedValues) => {
        setNodeProperties((prevProps) => {
            // Check if updatedValues contain the 'questions' key
            if (updatedValues.hasOwnProperty('questions')) {
                return {
                    ...prevProps,
                    questions: updatedValues.questions, // Specifically handle the questions update
                    ...updatedValues, // Merge other updates (if any)
                };
            } else {
                return {
                    ...prevProps,
                    ...updatedValues, // Handle general updates for other properties
                };
            }
        });
    };

    const {
        nodes,
        edges,
        createCanvas,
        //loadCanvas,
        //saveToDatabase
    } = useMlCanvas();

    const handleCancel = () => {
        onClose();
    };

    const testClick = () => {
        console.log("Icon clicked");
    };

    // State to track which components are visible
    const [visibleComponents, setVisibleComponents] = useState({
        imageComponent: false,
        videoComponent: false,
        displayComponent: false,
        textEditor: true,
        // ... other components
    });

    // Handle tool selection from MlEditorToolBox
    const handleToolSelect = (toolName) => {
        let newVisibleComponents = { ...visibleComponents };

        // Reset visibility for all components
        Object.keys(newVisibleComponents).forEach(key => {
            newVisibleComponents[key] = false;
        });

        // Update visibility based on selected tool
        switch (toolName) {
            case 'Text':
                newVisibleComponents.textEditor = true;
                break;
            case 'Image':
                newVisibleComponents.imageComponent = true;
                newVisibleComponents.videoComponent = false;
                break;
            case 'Sound':
                newVisibleComponents.soundComponent = true;
                break;
            case 'Video':
                newVisibleComponents.videoComponent = true;
                break;
            case 'Display':
                newVisibleComponents.displayComponent = true;
                break;
            case 'Data':
                newVisibleComponents.typeComponent = true;
                break;
            case 'Qwanyz':
                newVisibleComponents.qwanyxComponent = true;
                break;
            case 'Import':
                newVisibleComponents.qstackComponent = true;
                break;
            // ... other cases for different tools
        }

        setVisibleComponents(newVisibleComponents);
    };


    const handleSave = () => {
        onSave(nodeProperties);
        onClose();
    };

    const handleClone = () => {
        const clonedNode = cloneNode(nodeProperties);
        //setNodeProperties(clonedNode);
        //onSave(clonedNode);
        onRemount();
    };
    useEffect(() => {
        // This code runs after nodeProperties has been updated
        const test = nodeProperties;
        // Perform actions that depend on the updated value of nodeProperties here
    }, [nodeProperties]);

    const handleSaveToDatabase = async () => {
        // Ensure nodeProperties._id is an ObjectId
        if (!nodeProperties._id || !(nodeProperties._id instanceof ObjectId)) {
            nodeProperties._id = new ObjectId();
        }

        const result = await nodePut(nodeProperties); // Assuming nodePut is an async operation
        //onSave(nodeProperties);
        //onClose();
    };


    const handleChange = (property, value) => {
        setNodeProperties(prevProps => ({
            ...prevProps,
            [property]: value,
        }));
    };

    const simplifyNodes = nodes.map(node => {
        // Helper function to convert HTML to plain text
        const htmlToText = (html) => {
            const div = document.createElement("div");
            div.innerHTML = html;
            return div.textContent || div.innerText || "";
        };

        return {
            id: node.id,
            title: node.data.title,
            brief: htmlToText(node.data.brief), // Convert HTML to plain text
            type: node.data.type,
            state: node.data.state,
            x: node.positionAbsolute?.x,
            y: node.positionAbsolute?.y
        };
    });

    const simplifyEdges = edges.map(edge => ({
        source: edge.source,
        target: edge.target
    }));


    const simplifiedData = {
        nodes: simplifyNodes,
        edges: simplifyEdges
    };

    const canvasContext = JSON.stringify(simplifiedData);

    const handleLinkNewCanvas = async () => {
        const canvasObjectId = await createCanvas(nodeProperties.title);
        if (canvasObjectId !== null) {
            try {
                const canvasIdString = canvasObjectId.toString();
                handleChange('canvasLink', canvasIdString);
                handleChange('linkIcon', 'nextIcon')
            } catch (err) {
                console.error("Unable to write the link. Error:", err);
            }
        }
        console.log("Updated canvasLink:", nodeProperties.canvasLink);
    };

    //-----------------------------------------CHATGPT
    //helper to deduct the tokens from the user credits

    function updateUserCreditsProp(propName, value) {
        const creditsObject = {
            [propName]: value
        };
        const newUserData = removeCredits(userData, creditsObject); // assuming userData is already in scope
        setUserData(newUserData);
        userCommit();
    }

    const updateUserCredits = (chatCompletion) => {
        const newUserData = removeChatGptCredits(userData, chatCompletion.usage)
        setUserData(newUserData)
        userCommit()
    };

    const handleSendGPTClick = async () => {
        setIsLoading(true); // show loading modal 
        const chatCompletion = await getAnswer(nodeProperties.brief, canvasContext);
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion);
        const formattedAnswer = convertGPTMarkdownToHTML(answer)
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`);
        }
    };
    //context   
    const handleContentGenerate = async () => {
        setIsLoading(true); // show loading modal
        
        const connectedContext = getConnectedNodesAndEdges(nodeProperties.id, simplifyNodes, simplifyEdges);
        const canvasContextString = JSON.stringify(connectedContext, null, 2);
        //const test = nodeData;
        if (!connectedContext || !connectedContext.nodes || connectedContext.nodes.length === 0) {
            setIsLoading(false);
            return;
        }

        const chatCompletion = await getAnswer(nodeProperties.brief, canvasContextString);
        updateUserCredits(chatCompletion);
        const answer = getCompletionAnswer(chatCompletion);
        const formattedAnswer = convertGPTMarkdownToHTML(answer);
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`);
        }
    };



    const handleVision = async () => {
        setIsLoading(true); // show loading modal
        
        const connectedContext = getConnectedNodesAndEdges(nodeProperties.id, simplifyNodes, simplifyEdges);
        const canvasContextString = JSON.stringify(connectedContext, null, 2);
        //const test = nodeData;
        if (!connectedContext || !connectedContext.nodes || connectedContext.nodes.length === 0) {
            setIsLoading(false);
            return;
        }
        
        const chatCompletion = await getAnswerFromImage(nodeProperties.brief, nodeProperties.imageLink, canvasContextString);
        updateUserCredits(chatCompletion);
        const answer = getCompletionAnswer(chatCompletion);
        const formattedAnswer = convertGPTMarkdownToHTML(answer);
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`);
        }
    };

    const handleSendGPTClickNoContext = async () => {
        setIsLoading(true); // show loading modal 
        const chatCompletion = await getAnswerNoContext(nodeProperties.brief)
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion)
        const formattedAnswer = convertGPTMarkdownToHTML(answer)
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`)
        }
    };

    const handleGetProjectGPTClick = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getProject(nodeProperties.brief, 5, 7);
        updateUserCredits(chatCompletion)
        const answer = '[\n' + getCompletionAnswer(chatCompletion) + '\n]'; // Wrap the content in an array and return it

        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            navigator.clipboard.writeText(answer).then(function () {
                console.log("Clipboard successfully set");
                setIsFinished(true); // show finish modal
            }, function (err) {
                console.error("Unable to write to clipboard. Error:", err);
            });
        }
    };

    const handleGetTitleClick = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getTitle(nodeProperties.brief);
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion);

        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                handleChange('title', answer);
            } catch (err) {
                console.error("Unable to write the title. Error:", err);
            }
        }
    };

    const handleSummarizeClick = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getSummary(nodeProperties.brief);
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion);

        const formattedAnswer = convertGPTMarkdownToHTML(answer)
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                handleChange('brief', formattedAnswer); // Assuming this is a synchronous function
            } catch (err) {
                console.error("Unable to write the brief. Error:", err);
            }
        }
    };

    const handleDevelopClick = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getDevelopmement(nodeProperties.brief)
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion)
        const formattedAnswer = convertGPTMarkdownToHTML(answer)

        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`);
            } catch (err) {
                console.error("Unable to write the brief. Error:", err);
            }
        }
    };

    const handleProofRead = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getProofRead(nodeProperties.brief);
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion)
        const formattedAnswer = convertGPTMarkdownToHTML(answer)

        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`);
            } catch (err) {
                console.error("Unable to write the brief. Error:", err);
            }
        }
    };

    const handleMail = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getMail(nodeProperties.brief);
        const answer = getCompletionAnswer(chatCompletion)
        updateUserCredits(chatCompletion)
        const formattedAnswer = convertGPTMarkdownToHTML(answer)

        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`);
            } catch (err) {
                console.error("Unable to write the brief. Error:", err);
            }
        }
    };


    const handleGetKnowledgeClick = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getKnowledge(nodeProperties.brief, 5, 5);
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion)
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                await navigator.clipboard.writeText('[\n' + answer + '\n]');
                console.log("Clipboard successfully set");
                setIsFinished(true); // show finish modal
            } catch (err) {
                console.error("Unable to write to clipboard. Error:", err);
                // Handle the error - perhaps show a modal or tooltip asking the user to copy manually
            }
        }
    };

    const handleGetNotesClick = async () => {
        setIsLoading(true); // show loading modal

        const chatCompletion = await getNotes(nodeProperties.brief, 5, 5);
        updateUserCredits(chatCompletion)
        const answer = getCompletionAnswer(chatCompletion)
        setIsLoading(false); // hide loading modal

        if (answer !== null) {
            try {
                await navigator.clipboard.writeText('[\n' + answer + '\n]');
                console.log("Clipboard successfully set");
                setIsFinished(true); // show finish modal
            } catch (err) {
                console.error("Unable to write to clipboard. Error:", err);
                // Handle the error - perhaps show a modal or tooltip asking the user to copy manually
            }
        }
    };

    const handleTTISave = async (useMap = false) => {

        //TODO push the code to MlGPT so it will be easyer to use the TTI  in other
        setIsLoading(true); // Show loading modal
        const plainTextContent = extractTextFromHtml(nodeProperties.brief);

        const imageGenerationPrompt = `You are a talented illustrator. Illustrate the text below in a symbolic manner for an explanation`;

        const imageFromContextPrompt = `
        the promt contains two json sturctures
        const simplifyNodes = nodes.map(node => ({
            id: node.id,
            title: node.data.title,
            brief: node.data.brief,
            type: node.data.type,
            state: node.data.state,
            x: node.positionAbsolute?.x,
            y: node.positionAbsolute?.y
        }));

        and 

        const simplifyEdges = edges.map(edge => ({
            source: edge.source,
            target: edge.target
        }));

        simplifyNodes titles can be interpreted as strong indication about subject, style, details to add etc.
        the brief of these nodes will provide what the title define as indication.

        for example the style could be: hyperrealistic, italian renaissance, abstract, destrutcured or a more complex explanation
        if a title is named subject then use the brief of that node to have the description of the subject.
        
        edges also provide clues about how to relate stuff.

        If you have a nodewith subject title with 3 targets each target will define a subject.

        So you have to interpret the graph represented by the nodes and edges to generate the image accordingly
        
        Don't use the json structure itself as an instruction for the picture generation but only it's content
        
        `;


        const imageFolder = "GPT/images/";

        const fileName = `${imageFolder}${nodeProperties._id.toString()}.png`;

        // Conditionally concatenate based on useContext
        const finalPrompt = useMap
            ? `${plainTextContent}\r\n${canvasContext}`
            : `${imageGenerationPrompt}\r\n${plainTextContent}`;

        try {
            // Call textToImage and check the result
            const imageResult = await textToImage(finalPrompt, undefined, fileName);

            if (imageResult) {
                nodeProperties.imageLink = "/" + fileName;
                nodeProperties.imageSize = "350px";
                nodeProperties.showImage = true;
            } else {
                // Handle the case where textToImage did not succeed
                console.error('Error in generating image:', imageResult);
                // Optionally, set an error state or message here
            }
        } catch (error) {
            console.error('Error during handleTTISave:', error);
            // Optionally, set an error state or message here
        }

        setIsLoading(false);
    };

    //END-----------------------------------------CHATGPT

    const handleGetNodesFromClipBoard = async () => {
        setIsLoading(true); // show loading modal

        try {
            const clipboardContent = await navigator.clipboard.readText();
            // Use clipboardContent as the source for the template
            const template = clipboardContent;

            const answer = await nodesFromTemplate(template);

            setIsLoading(false); // hide loading modal

            if (answer !== null) {
                try {
                    await navigator.clipboard.writeText(answer);
                    console.log("Clipboard successfully set");
                    setIsFinished(true); // show finish modal
                } catch (err) {
                    console.error("Unable to write to clipboard. Error:", err);
                    // Handle the error - perhaps show a modal or tooltip asking the user to copy manually
                }
            }
        } catch (err) {
            console.error("Unable to read from clipboard. Error:", err);
            setIsLoading(false); // hide loading modal
            // Handle the error - perhaps show a modal or tooltip indicating the error
        }
    };

    const handleAudioSave = async (audioBlob, audioDuration) => {


        updateUserCreditsProp('transcriptCredits', audioDuration)

        // Transcribe the audio blob
        setIsLoading(true); // show loading modal
        const transcriptionText = await transcribeAudio(audioBlob)
        const formattedAnswer = convertGPTMarkdownToHTML(transcriptionText)
        //console.log('Transcribed text:', audioBlob);

        if (transcriptionText !== null) {
            handleChange('brief', `${nodeProperties.brief}\n\n${formattedAnswer}`)
        }
        setIsLoading(false); // hide loading modal
    };

    const playAudioBlob = (blob) => {
        // Create a new audio element
        const audio = new Audio();

        // Create an object URL from the blob
        const audioUrl = URL.createObjectURL(blob);

        // Set the object URL as the audio source
        audio.src = audioUrl;

        // Play the audio
        audio.play().catch(e => console.error('Error playing audio:', e));
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
        });
    };

    const extractTextFromHtml = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body.textContent || '';
    };



    const handleTitleTISave = async () => {
        setIsLoading(true); // Show loading modal
        const plainTextContent = extractTextFromHtml(nodeProperties.title);

        const imageGenerationPrompt = `Create a catchy icon using 3D rendering style for ${plainTextContent}. Be very carefull to have no text in the picture`
        const imageFolder = "GPT/images/";

        const fileName = `${imageFolder}${nodeProperties._id.toString()}.png`;

        const finalPrompt = `${imageGenerationPrompt}\r\n${plainTextContent}`;

        try {
            // Call textToImage and check the result
            const imageResult = await textToImage(finalPrompt, undefined, fileName);

            if (imageResult) {
                nodeProperties.imageLink = "/" + fileName;
                nodeProperties.imageSize = "350px";
                nodeProperties.showImage = true;
                updateUserCreditsProp('imageCredits', 1)
            } else {
                // Handle the case where textToImage did not succeed
                console.error('Error in generating image:', imageResult);
                // Optionally, set an error state or message here
            }
        } catch (error) {
            console.error('Error during handleTTISave:', error);
            // Optionally, set an error state or message here
        }

        setIsLoading(false);
    };

    const handleDeleteImage = async () => {

        // TODO: It will be neccesary to add testing to see
        // if the node that contains the image was really deleted.

        nodeDelete(nodeProperties.imageNodeId);
        nodeProperties.imageNodeId = null;

    };
    const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });


    function getVersionString() {
        const seconds = Math.floor(new Date().getTime() / 1000);
        return `v=${seconds}`;
    }

    const handleSpeechSave = async (voice, isHD) => {
        setIsLoading(true); // Show loading modal

        const plainTextContent = extractTextFromHtml(nodeProperties.brief);
        const speechBlob = await textToSpeechBlob(plainTextContent, voice, isHD);

        if (speechBlob) {
            // Update user credits based on the content length
            updateUserCreditsProp('ttsCredits', plainTextContent.length);

            const soundFolder = `RECORD/voice/${USER_ID}/${nodeProperties._id.toString()}/`;
            const fileName = `${soundFolder}${nodeProperties._id.toString()}.wav`;

            // Upload the file using the new uploadFiles function
            const filesToUpload = [
                { file: speechBlob, key: fileName }
            ];

            const uploadResult = await uploadFiles(filesToUpload); // No need for base64 conversion

            // Construct the soundLink with cache-busting for future use
            const cacheBustingLink = fileName + "?" + getVersionString();
            nodeProperties.soundLink = "/" + cacheBustingLink;

            // Optionally play the audio directly from the blob
            playAudioBlob(speechBlob);
        }

        setIsLoading(false); // Hide loading modal
    };


    const handleTTSDelete = async () => {
        if (nodeProperties.audioNodeId) {
            // If audioNodeId exists, delete the corresponding node
            await nodeDelete(nodeProperties.audioNodeId)
                .catch(error => console.error('Error deleting audio node:', error));

            // Update the audioNodeId to null in the local state
            handleChange('audioNodeId', null);
        }
    };

    const handlePictureEditorSave = (updatedValues) => {
        setNodeProperties(prevProps => ({
            ...prevProps,
            ...updatedValues // Updates or adds new image properties
        }));
    };

    //quill stuff
    const fullToolbarOptions = [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['link', 'image', 'video', 'formula'],
        ['clean']
    ];

    const modules = {
        toolbar: fullToolbarOptions
    };


    return (
        <>
            {/* Loading dialog */}
            <Dialog open={isLoading}>
                <DialogTitle>I'm Thinking</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please wait, this may take some time...
                    </DialogContentText>
                    <CircularProgress />
                </DialogContent>
            </Dialog>

            {/* Finish dialog */}
            <Dialog
                open={isFinished}
                onClick={() => setIsFinished(false)} // Close on outside click
            >
                <DialogTitle>Finished</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        You can now paste the project in a canvas using Ctrl+U.
                    </DialogContentText>
                </DialogContent>
            </Dialog>


            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="quill-editor-dialog-title"
                fullWidth
                maxWidth="md"
                PaperProps={{
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        height: '95vh',
                    },
                }}
            >
                <DialogTitle id="quill-editor-dialog-title">
                    <Stack direction='column' spacing={1}>
                        <Stack direction='column' spacing={2} height>
                            <Stack
                                direction='row'
                                spacing={2}
                                sx={{ justifyContent: 'space-between' }} // This will align the MenuIcon to the right
                                alignItems='center'
                            >
                                <MlTitle
                                    value={nodeProperties.title}
                                    onChange={e => handleChange('title', e.target.value)}
                                    sx={{ flexGrow: 1 }} // This will make MlTitle take up the remaining space
                                />
                                <MenuIcon sx={{ flexGrow: 0 }} />
                            </Stack>

                            <Box>
                                <Box display="flex" justifyContent="center" width="100%">
                                    <MlEditorToolBox onToolSelect={handleToolSelect} />
                                </Box>
                            </Box>
                        </Stack>
                        {visibleComponents.imageComponent &&
                            <>
                                <MlNodePictureEditor
                                    initialPictureValues={nodeProperties}
                                    onSave={handlePictureEditorSave}
                                />
                                <MlCardMedia
                                    size={'100%'}
                                    imageLink={nodeProperties.imageLink}
                                    imageNodeId={nodeProperties.imageNodeId}
                                    getProperty={getProperty}
                                />
                            </>
                        }
                        {visibleComponents.videoComponent &&
                            <>
                                <TextField
                                    label="Video URL"
                                    variant="outlined"
                                    size="small"
                                    value={nodeProperties.videoLink}
                                    onChange={e => handleChange('videoLink', e.target.value)}
                                />
                                <TextField
                                    label="Video Size"
                                    variant="outlined"
                                    size="small"
                                    value={nodeProperties.videoSize}
                                    onChange={e => handleChange('videoSize', e.target.value)}
                                // Prevent TextField from shrinking
                                />
                                <FormControlLabel
                                    size="small"

                                    control={
                                        <Checkbox
                                            checked={nodeProperties.showVideo}
                                            onChange={e => handleChange('showVideo', e.target.checked)}
                                        />
                                    }
                                    label="Show Video"
                                    style={{ flexShrink: 0 }} // Prevent FormControlLabel from shrinking
                                />
                            </>
                        }

                        {visibleComponents.typeComponent &&
                            <MlNodeTypeEditor
                                initialComponentValues={nodeProperties || {}}
                                onSave={handleNodeTypeEditorSave}
                            />
                        }
                        {visibleComponents.displayComponent &&
                            <MlNodeDisplayEditor
                                initialDisplayValues={nodeProperties}
                                onSave={handleNodeDisplayEditorSave}
                            />
                        }
                        {visibleComponents.soundComponent &&
                            <MlNodeSoundEditor
                                initialSoundValues={nodeProperties}
                                onSave={handleNodeDisplayEditorSave}
                            />
                        }
                        {visibleComponents.qwanyxComponent &&
                            <QwanyzEditor
                                initialValues={nodeProperties}
                                onSave={handleNodeDisplayEditorSave}
                            />
                        }
                        {visibleComponents.qstackComponent &&
                            <GraphBrowser
                                initialValues={nodeProperties}
                                onSave={handleNodeDisplayEditorSave}
                            />
                        }
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    {visibleComponents.textEditor &&
                        <Stack direction='column' spacing={1}>
                            <FormControlLabel
                                size="small"
                                control={
                                    <Checkbox
                                        checked={nodeProperties.showBrief}
                                        onChange={e => handleChange('showBrief', e.target.checked)}
                                    />
                                }
                                label="Show Tooltip"
                                style={{ flexShrink: 0 }} // Prevent FormControlLabel from shrinking
                            />
                            <Stack
                                direction="row"
                                spacing={1}
                                sx={{ width: '100%' }}
                                alignItems='center'
                            >
                                <TextField
                                    sx={{ width: '100%' }}
                                    label="Link"
                                    variant="outlined"
                                    size="small"
                                    value={nodeProperties.canvasLink}
                                    onChange={e => handleChange('canvasLink', e.target.value)}
                                />
                                <Tooltip title="Create a subcanvas and link it" arrow>
                                    <CanvasIcon sx={{ fontSize: 24 }} onClick={handleLinkNewCanvas} />
                                </Tooltip>
                            </Stack>

                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                pt={1}
                                gap={1}
                                alignItems='center'
                            >
                                <Tooltip title="Prompt to email" arrow>
                                    <MailIcon sx={{ fontSize: 24 }} onClick={handleMail} />
                                </Tooltip>
                                <Tooltip title="Proof reading" arrow>
                                    <SpellcheckIcon sx={{ fontSize: 24 }} onClick={handleProofRead} />
                                </Tooltip>
                                <Tooltip title="Image from the title" arrow>
                                    <MmsIcon sx={{ fontSize: 24 }} onClick={handleTitleTISave} />
                                </Tooltip>
                                <Tooltip title="Image from the text" arrow>
                                    <VisionIcon sx={{ fontSize: 24 }} onClick={() => handleVision(false)} />
                                </Tooltip>
                                <Tooltip title="Image from the text" arrow>
                                    <TTIIcon sx={{ fontSize: 24 }} onClick={() => handleTTISave(false)} />
                                </Tooltip>
                                <Tooltip title="Image from map" arrow>
                                    <ImageFromCanvas sx={{ fontSize: 24 }} onClick={() => handleTTISave(true)} />
                                </Tooltip>
                                <Tooltip title="Title from the text" arrow>
                                    <TitleIcon sx={{ fontSize: 24 }} onClick={handleGetTitleClick} />
                                </Tooltip>
                                <Tooltip title="Summarize" arrow>
                                    <SummarizeIcon sx={{ fontSize: 24 }} onClick={handleSummarizeClick} />
                                </Tooltip>
                                <Tooltip title="Develop" arrow>
                                    <AddCommentIcon sx={{ fontSize: 24 }} onClick={handleDevelopClick} />
                                </Tooltip>
                                <MlTranscribeFile size='24px' onRecordingComplete={handleAudioSave} />
                                <MlTTS sx={{ fontSize: 24 }} onTTSComplete={handleSpeechSave} onTTSDelete={handleTTSDelete} />
                                <MlRecorder onRecordingComplete={handleAudioSave} />
                                <Tooltip title="Ask anything" arrow>
                                    <TipsAndUpdatesIcon sx={{ fontSize: 24 }} onClick={handleSendGPTClickNoContext} />
                                </Tooltip>
                                <Tooltip title="Generate content from workspace" arrow>
                                    <LiveHelpIcon sx={{ fontSize: 24 }} onClick={handleContentGenerate} />
                                </Tooltip>
                                <Tooltip title="Organize your notes as mindmap" arrow>
                                    <FormatListBulletedIcon sx={{ fontSize: 24 }} onClick={handleGetNotesClick} />
                                </Tooltip>
                                <Tooltip title="Create Nodes From ClipBoard" arrow>
                                    <FormatIndentIncreaseIcon sx={{ fontSize: 24 }} onClick={handleGetNodesFromClipBoard} />
                                </Tooltip>
                                <Tooltip title="Mindmap from your text" arrow>
                                    <SendGPTIcon sx={{ fontSize: 24 }} onClick={handleGetKnowledgeClick} />
                                </Tooltip>
                                <Tooltip title="Project from your text" arrow>
                                    <SendGPTProjectIcon sx={{ fontSize: 24 }} onClick={handleGetProjectGPTClick} />
                                </Tooltip>
                                <Tooltip title="Ask Project Questions" arrow>
                                    <ProjectHelp sx={{ fontSize: 24 }} onClick={handleSendGPTClick} />
                                </Tooltip>
                            </Stack>
                            <ReactQuill
                                theme="snow"
                                value={nodeProperties.brief}
                                modules={modules}
                                onChange={handleQuillChange}
                            />
                        </Stack>
                    }
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClone} color="primary">
                        Clone
                    </Button>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={isLocked} color="primary">
                        Save to Canvas
                    </Button>
                    <LockControl locked={isLocked} toggleLock={handleLockToggle} />
                    <Button onClick={handleSaveToDatabase} color="primary">
                        Save to database
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}

export default TextEditorDialog;
