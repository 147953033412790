import React, { useState, useEffect, useRef } from 'react';
import {
  formatPrice,
  formatPriceVat,
  formatTitle,
  getListWithReturn,
  ColorDetails,
  formatWarranty
} from './AutodinUtils';

import { getNode } from '../../MlModels/MlNode';
import { fetchBusinessData } from './businessDataFetcher'; // Import the fetcher function
import ContactSeller from './ContactSeller';
import MlDialog from '../base/MlDialog';
import SectionWithHeader from '../base/SectionWithHeader';
import ImageEditor from '../../ImageEditorComponent/ImageEditor';
import VehicleSummary from './VehicleSummary';
import VehiclePublicDetails from './VehiclePublicDetails';
import Poster from './VehiclePoster';

import {
  Carouselframe,
  ModleMarque,
  Foot,
  CardetailRoot,
} from './StyledStyles';

import {
  Stack,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import ShareIcon from '@mui/icons-material/Share';
import PrintIcon from '@mui/icons-material/Print';
import SendIcon from '@mui/icons-material/Send';
import { useReactToPrint } from 'react-to-print';

const VehiclDetails = ({ nodeId, open, onClose, loggedIn }) => {
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [vehicleData, setVehiclData] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [isExporting, setIsExporting] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [parentId, setParentId] = useState('autodin');

  const posterRef = useRef();

  const logo = "https://qwanyx-storage-images.s3.eu-central-1.amazonaws.com/IMAGES/upload/645b5e00c7b61df466430901/node_667d69d4dac9c828db94fdb1/AutoDinLogo.png?v=1719495169";

  useEffect(() => {
    const fetchNodeData = async () => {
      if (nodeId) {
        try {
          const fetchedNode = await getNode(nodeId);
          setVehiclData(fetchedNode);
        } catch (error) {
          console.error('Failed to fetch node:', error);
        }
      }
    };

    fetchNodeData();
  }, [nodeId]);

  useEffect(() => {
    const fetchAndSetBusinessData = async () => {
      if (vehicleData?.supplier) {
        const businessData = await fetchBusinessData(vehicleData.supplier);
        setBusinessData(businessData);
      }
    };

    if (vehicleData) {
      fetchAndSetBusinessData();
    }
  }, [vehicleData]);

  const toggleModal = () => {
    setContactDialogOpen(!contactDialogOpen);
  };

  const handleClose = () => {
    setContactDialogOpen(false);
  };

  const generateCarUrl = (nodeId) => {
    const currentUrl = window.location.href;
    let newUrl;

    if (currentUrl.endsWith(`/autodin`)) {
      newUrl = `${currentUrl}/${nodeId}`;;
    } else if (currentUrl.endsWith('/')) {
      newUrl = `${currentUrl}autodin/${nodeId}`;
    } else {
      newUrl = `${currentUrl}/autodin/${nodeId}`;
    }

    return newUrl;
  };

  const handleShareClick = () => {
    const newUrl = generateCarUrl(nodeId);

    navigator.clipboard.writeText(newUrl)
      .then(() => {
        setDialogOpen(true);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePrint = useReactToPrint({
    content: () => posterRef.current,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="vehicle-details-dialog-title"
        fullWidth
        maxWidth="md"
        fullScreen={fullScreen}
        PaperProps={{
          style: {
            display: 'flex',
            flexDirection: 'column',
            height: fullScreen ? '100%' : '95vh',
          },
        }}
      >
        <DialogTitle
          id="vehicle-details-dialog-title"
          sx={{
            borderBottom: '1px solid #ccc',
            backgroundColor: 'rgb(250,250,250)',
          }}
        >
          {vehicleData && (
            <div>
              <ModleMarque>{formatTitle(vehicleData?.brand, vehicleData?.model)}</ModleMarque>
            </div>
          )}
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'auto' }}>
          <CardetailRoot isExporting={isExporting} id="CardetailRoot">
            <Carouselframe className="page-break">
              {/* Carousel content here */}
            </Carouselframe>

            {/* Render sections in the specified order with checks */}

            {/* Images */}
            {vehicleData && (
              <ImageEditor
                node={vehicleData}
                onChange={(key, value) => {
                  // handle image editor changes if necessary
                }}
                loggedIn={loggedIn}
              />
            )}

            {/* Résumé */}
            {vehicleData && <VehicleSummary vehicleData={vehicleData} />}

            {/* Options */}
            {vehicleData?.options && vehicleData.options.length > 0 && (
              <SectionWithHeader
                header="Options"
                content={getListWithReturn(vehicleData?.options, 300)}
                isHtml={true}
              />
            )}

            {/* Détails */}
            {(vehicleData?.co2 || vehicleData?.classeEmission) && (
              <VehiclePublicDetails vehicleData={vehicleData} />
            )}

            {/* Couleur */}
            {(vehicleData?.selectedBodyColor || vehicleData?.setSelectedInsideColor || vehicleData?.setSelectedDecorationeColor || vehicleData?.isMetallic) && (
              <SectionWithHeader
                header="Couleur"
                content={<ColorDetails
                  bodyColor={vehicleData?.selectedBodyColor}
                  interiorColor={vehicleData?.setSelectedInsideColor}
                  decoration={vehicleData?.setSelectedDecorationeColor}
                  paintType={vehicleData?.isMetallic}
                />}
              />
            )}

            <Foot>
              {vehicleData?.price && vehicleData.price > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center', // Always center the entire price section
                    alignItems: 'center',
                    gap: vehicleData.taxDeductible ? '20px' : '0', // Add space between boxes if both prices are shown
                    width: '100%', // Full width to ensure proper centering
                  }}
                >
                  {/* Main Price and VAT Section */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center', // Center the text inside the box
                      padding: '0.5rem 1rem', // Add some padding to the box
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box component="span" sx={{ fontSize: '32px', fontWeight: 800 }}>€</Box>
                      <Box component="span" sx={{ fontSize: '32px', fontWeight: 800, marginLeft: '0.5rem' }}>
                        {formatPrice(vehicleData.price)} -
                      </Box>
                    </Box>
                    <Box component="span" sx={{ fontSize: '1rem', marginTop: '0.5rem' }}>
                      {vehicleData.taxDeductible ? 'Tva comprise' : 'TTC'}
                    </Box>
                  </Box>

                  {/* VAT Excluded Price (if applicable) */}
                  {vehicleData.taxDeductible && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center', // Center the text inside the box
                        padding: '0.5rem 1rem', // Add some padding to the box
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box component="span" sx={{ fontSize: '32px', fontWeight: 800 }}>€</Box>
                        <Box component="span" sx={{ fontSize: '32px', fontWeight: 800, marginLeft: '0.5rem' }}>
                          {formatPriceVat(vehicleData.price)} -
                        </Box>
                      </Box>
                      <Box component="span" sx={{ fontSize: '1rem', marginTop: '0.5rem' }}>
                        Hors TVA
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              {/* Buttons for Contact, Share, Print */}
              <Box
                className="no-print"
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '1rem',
                }}
              >
                <Stack
                  direction="column"
                  style={{ width: '100%' }}
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<SendIcon />}
                    onClick={toggleModal}
                    sx={{ width: '100%' }}
                  >
                    Contact
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<ShareIcon />}
                    onClick={handleShareClick}
                    sx={{ width: '100%' }}
                  >
                    Partager
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<PrintIcon />}
                    onClick={handlePrint}
                    sx={{ width: '100%' }}
                  >
                    Imprimer
                  </Button>
                </Stack>
              </Box>
            </Foot>
          </CardetailRoot>

          <MlDialog
            style={{ zIndex: 3000 }}
            open={contactDialogOpen}
            handleClose={handleClose}
            vehicle={formatTitle(vehicleData?.brand, vehicleData?.model)}
            carUrl={generateCarUrl(nodeId)}
            ChildComponent={ContactSeller}
          />

        </DialogContent>
        <DialogActions
          sx={{
            borderTop: '1px solid #ccc',
            backgroundColor: 'rgb(250,250,250)',
          }}
        >
          <Button onClick={onClose} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{"Copié"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Le lien vers la voiture a été copié. Vous pouvez le coller dans la barre d'adresse de votre navigateur. Ou le partager...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Render VehiclePoster off-screen for printing */}
      <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
        <Poster ref={posterRef} vehicleData={vehicleData} logo={logo} businessData={businessData} />
      </div>
    </>
  );
};

export default VehiclDetails;
