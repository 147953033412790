import ReactDOM from 'react-dom';
import { toJpeg } from 'html-to-image';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import printJS from 'print-js';

export function formatCo2(co2) {

  return "Co2: " + co2

}

export function formatPrice(price) {
  if (price === undefined) {
    return 0;
  }

  if (price >= 10000) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else if (price >= 1000) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } else {
    return price.toString();
  }
}

export const formatPriceVat = (priceWithVat) => {
  const priceWithoutVat = priceWithVat / 1.21;
  return formatPrice(priceWithoutVat.toFixed(0));
};
export function formatInfoString(date, motorisation, capacity, kms) {

  let infoString = "";

  if (date) {
    infoString += date;
  }

  if (motorisation) {
    if (infoString) {
      infoString += " | ";
    }
    infoString += getMotorisationType(motorisation);
  }
  if (capacity) {
    if (infoString) {
      infoString += " | ";
    }
    infoString += capacity += " cc";
  }
  if (kms) {
    if (infoString) {
      infoString += " | ";
    }
    infoString += `${kms} km`;
  }

  return infoString;
}

export function formatSupplier(supplier) {
  let infoString = "";

  if (supplier && supplier.length > 1) {
    infoString += supplier;
  } else { 
    infoString += 'Particulier';
  }

  return infoString;
}


export function formatTitle(brand, model) {
  let infoString = "";

  if (brand) {
    infoString += brand;
  }

  if (model) {
    if (infoString) {
      infoString += " ";
    }
    infoString += model;
  }
  return infoString;
}

export function formatWarranty(warranty) {
  if (!warranty) {
    return "";
  }

  let warrantyString = "Garantie: ";

  warrantyString += warranty;

  // Check if warranty is a string that represents a number
  if (!isNaN(Number(warranty))) {
    warrantyString += " Mois";
  }

  return warrantyString;
}

export function getMotorisationType(motorisation) {
  console.log("MOTORISATION", motorisation)
  const motorisationMap = {
    1: "Essence",
    2: "LPG",
    3: "Diesel",
    4: "Hybride essence",
    5: "Hybride diesel",
    6: "Electrique",
    7: "Hydrogène",
  };
  const fuel = motorisationMap[motorisation] || "Unknown";
  return motorisationMap[motorisation] || "Unknown";
}

export function getListWithReturn(listString, maxLines = 25) {
  let result = "";

  // Determine the loop limit
  const loopLimit = (listString.length > maxLines) ? maxLines - 1 : listString.length;

  // Iterate over the array up to loopLimit
  for (let i = 0; i < loopLimit; i++) {
    result += listString[i] + "<br>";
  }

  // Add ellipsis if the array length exceeds maxLines
  if (listString.length > maxLines) {
    result += "...";
  } else {
    // Remove the last break element if no ellipsis is added
    result = result.slice(0, -4);
  }

  return result;
}

export function getTransmissionType(transmission) {
  const transmissionMap = {
    1: "Automatique",
    2: "Manuelle",

  };
  return transmissionMap[transmission] || "Manuelle";
}

export const formatTransmission = (transmission, gears) => {
  if (transmission === 2) {
    return (
      <>
        Manuelle <br /> 
        {gears && gears.trim() !== '' ? `${gears} vitesses` : null}
      </>
    );
  } else if (transmission === 1) {
    return 'Automatique';
  } else {
    return '';
  }
};

export default getTransmissionType;

export function getSellerType(seller) {
  const sellerMap = {
    1: "Particulier",
    2: "Proffessionel",
  };
  return sellerMap[seller] || "Particulier";
}

/**
* Resize a base64 image to fit within a maximum dimension while maintaining aspect ratio.
* @param {string} base64Image - The base64 representation of the image.
* @param {number} maxSize - The maximum dimension (either width or height) for the resized image.
* @returns {Promise<string>} - A Promise that resolves to the base64 string of the resized image.
*/



export const exportComponentAsJpg = async (Component, componentProps, parentId, userId, fileName = 'my-image-name') => {
  // Step 1: Fetch images
  //const imageNodes = await fetchImages(parentId, userId);

  // Create an off-screen div to render the component
  const tempDiv = document.createElement('div');
  document.body.appendChild(tempDiv);

  // Render the component now that you have the images
  //ReactDOM.render(<Component {...componentProps} imageNodes={null} />, tempDiv);

  // Proceed with the JPEG export
  const node = document.getElementById('ExportableCardetailRoot');
  /*
    toJpeg(node, { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${fileName}.jpeg`;
        link.href = dataUrl;
        link.click();
  
        // Clean up by removing the off-screen div and unmounting the React component
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);
      })
      .catch((error) => {
        console.error('Could not export image:', error);
      })*/
};

export const printComponentAsJpg = async (Component, componentProps, parentId, userId) => {
  // Step 1: Fetch images
  //const imageNodes = await fetchImages(parentId, userId);

  // Create an off-screen div to render the component
  const tempDiv = document.createElement('div');
  document.body.appendChild(tempDiv);

  // Render the component now that you have the images
  ReactDOM.render(<Component {...componentProps} />, tempDiv);

  // Proceed with the JPEG export
  const node = document.getElementById('ExportableCardetailRoot');
  const test = '';
  toJpeg(node, { quality: 0.95 })
    .then((dataUrl) => {
      // Use printJS to print the image
      printJS({
        printable: dataUrl,
        type: 'image',
        imageStyle: 'width:100%;margin:auto;',
        showModal: true,
      });

      // Clean up by removing the off-screen div and unmounting the React component
      ReactDOM.unmountComponentAtNode(tempDiv);
      document.body.removeChild(tempDiv);
    })
    .catch((error) => {
      console.error('Could not print image:', error);
    });

};

export const ColorDetails = ({ bodyColor, paintType, interiorColor, decoration }) => {
  return (
    <Stack direction="column">
      {bodyColor && <Typography variant="body1"><strong>Couleur:</strong> {bodyColor} {paintType ? "Métallisé" : ""}</Typography>}
      {interiorColor && <Typography variant="body1"><strong>Couleur Intérieure:</strong> {interiorColor}</Typography>}
      {decoration && <Typography variant="body1"><strong>Décoration:</strong> {decoration}</Typography>}
    </Stack>
  );
};
