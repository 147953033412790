import React, { useState } from 'react';
import { FormGroup, Stack, Button, Box, Typography, Paper } from '@mui/material';
import SliderWithPrice from '../base/SliderWithPrice';
import TextWithNumber from '../base/TextWithNumber';
import PriceDisplay from '../base/PriceDisplay';

export default function DashBoardCredits({ node }) {
    // Access environment variables for pricing
    const priceWidth = '65px';
    const PRICING = {
        promptTokensCredits: parseFloat(process.env.REACT_APP_PROMPT_TOKENS_CREDITS_PRICE),
        completionTokensCredits: parseFloat(process.env.REACT_APP_COMPLETION_TOKENS_CREDITS_PRICE),
        ttsCredits: parseFloat(process.env.REACT_APP_TTS_CREDITS_PRICE),
        transcriptCredits: parseFloat(process.env.REACT_APP_TRANSCRIPT_CREDITS_PRICE),
        imageCredits: parseFloat(process.env.REACT_APP_IMAGE_CREDITS_PRICE),
    };

    // Initialize state for new order values (start with 0)
    const [order, setOrder] = useState({
        promptTokensCredits: 0,
        completionTokensCredits: 0,
        reasoningTokensCredits: 0,
        ttsCredits: 0,
        transcriptCredits: 0,
        imageCredits: 0
    });

    // Handle slider change and update order state
    const handleSliderChange = (field, value) => {
        setOrder(prevOrder => ({
            ...prevOrder,
            [field]: value
        }));
    };

    // Calculate prices based on the slider values
    const getPrice = (credits, pricePerUnit) => {
        return (credits / 1) * pricePerUnit;  // Convert to per 1000 for tokens and use direct values for minutes/images
    };

    // Calculate total price
    const totalPrice =
        getPrice(order.promptTokensCredits, PRICING.promptTokensCredits) +
        getPrice(order.completionTokensCredits, PRICING.completionTokensCredits) +
        getPrice(order.ttsCredits, PRICING.ttsCredits) +
        getPrice(order.transcriptCredits, PRICING.transcriptCredits) +
        getPrice(order.imageCredits, PRICING.imageCredits);

    return (
        <FormGroup >
            <Stack width="100%" spacing={3} padding="24px 50px" mt={4}>

                {/* Prompt Tokens */}
                <Paper>
                    <Stack width="100%" spacing={3} mb={3}>
                        <TextWithNumber
                            text="User Tokens"
                            number={node?.aiCredits?.promptTokensCredits || 0}
                        />
                        <SliderWithPrice
                            min={0}
                            max={1000000}
                            step={100000}
                            sliderValue={order.promptTokensCredits}
                            onChange={(field, value) => handleSliderChange('promptTokensCredits', value)}
                            priceValue={getPrice(order.promptTokensCredits, PRICING.promptTokensCredits)}
                            priceWidth={priceWidth}
                            currency="€"
                        />

                        {/* Completion Tokens */}
                        <TextWithNumber
                            text="Response Tokens"
                            number={node?.aiCredits?.completionTokensCredits || 0}
                        />
                        <SliderWithPrice
                            min={0}
                            max={500000}
                            step={100000}
                            sliderValue={order.completionTokensCredits}
                            onChange={(field, value) => handleSliderChange('completionTokensCredits', value)}
                            priceValue={getPrice(order.completionTokensCredits, PRICING.completionTokensCredits)}
                            priceWidth={priceWidth}
                            currency="€"
                        />

                        {/* TTS Credits */}
                        <TextWithNumber
                            text="Text to Speech Credits (minutes)"
                            number={node?.aiCredits?.ttsCredits || 0}
                        />
                        <SliderWithPrice
                            min={0}
                            max={1000}
                            step={20}
                            sliderValue={order.ttsCredits}
                            onChange={(field, value) => handleSliderChange('ttsCredits', value)}
                            priceValue={getPrice(order.ttsCredits, PRICING.ttsCredits)}
                            priceWidth={priceWidth}
                            currency="€"
                        />

                        {/* Transcript Credits */}
                        <TextWithNumber
                            text="Transcript Credits (minutes)"
                            number={node?.aiCredits?.transcriptCredits || 0}
                        />
                        <SliderWithPrice
                            min={0}
                            max={1000}
                            step={50}
                            sliderValue={order.transcriptCredits}
                            onChange={(field, value) => handleSliderChange('transcriptCredits', value)}
                            priceValue={getPrice(order.transcriptCredits, PRICING.transcriptCredits)}
                            priceWidth={priceWidth}
                            currency="€"
                        />

                        {/* Image Credits */}
                        <TextWithNumber
                            text="Images"
                            number={node?.aiCredits?.imageCredits || 0}
                        />
                        <SliderWithPrice
                            min={0}
                            max={1000}
                            step={50}
                            sliderValue={order.imageCredits}
                            onChange={(field, value) => handleSliderChange('imageCredits', value)}
                            priceValue={getPrice(order.imageCredits, PRICING.imageCredits)}
                            priceWidth={priceWidth}
                            currency="€"
                        />
                    </Stack>
                </Paper>

                {/* Total Price */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', marginTop: 2 }}>
                    <Typography variant="h6" sx={{ marginRight: 2 }}>Total: </Typography>
                    <PriceDisplay
                        value={totalPrice}
                        currency="€"
                        width={priceWidth}
                    />
                </Box>

                {/* Buy Credits Button */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button variant="contained" color="primary">
                        Buy Credits
                    </Button>
                </Box>
            </Stack>
        </FormGroup>
    );
}
