// Helper function to identify root nodes
export const getRootNodes = (nodes, edges) => {
  const targetNodeIds = new Set(edges.map(edge => edge.target));
  return nodes.filter(node => !targetNodeIds.has(node.id));
};

// Helper function to get children nodes of a given node
export const getChildrenNodes = (node, nodes, edges) => {
  const childEdges = edges.filter(edge => edge.source === node.id);
  const childNodeIds = childEdges.map(edge => edge.target);
  return nodes.filter(node => childNodeIds.includes(node.id));
};

// Helper function to sort nodes from top-left to bottom-right
export const sortNodesTopLeftToBottomRight = (nodes) => {
  return nodes.sort((a, b) => {
    if (a.position.y === b.position.y) {
      return a.position.x - b.position.x;
    }
    return a.position.y - b.position.y;
  });
};

// Helper function to get plain text from HTML
export const getPlainText = (html) => {
  return html.replace(/<[^>]*>/g, '');
};

// Recursive function to create an outline for a given root node
export const createOutlineForNode = (node, nodes, edges) => {
  const children = getChildrenNodes(node, nodes, edges);
  const sortedChildren = sortNodesTopLeftToBottomRight(children);

  return {
    nodeId: node.id,
    title: node.data.title,
    brief: node.data.brief,
    children: sortedChildren.map(child => createOutlineForNode(child, nodes, edges))
  };
};

// Main function to generate an outline from nodes and edges
export const generateOutline = (nodes, edges) => {
  // Step 1: Identify root nodes
  const rootNodes = getRootNodes(nodes, edges);

  // Step 2: Sort root nodes from top-left to bottom-right
  const sortedRootNodes = sortNodesTopLeftToBottomRight(rootNodes);

  // Step 3: Create an outline for each root node
  const outline = sortedRootNodes.map(rootNode => createOutlineForNode(rootNode, nodes, edges));

  return outline;
};

// Function to create an indented text outline from nodes and edges
export const generateTextOutline = (nodes, edges) => {
  const outline = generateOutline(nodes, edges);

  const createTextForNode = (node, indentLevel = 0) => {
    const indent = '  '.repeat(indentLevel);
    let text = `${indent}- ${getPlainText(node.title)}
`;
    if (node.brief) {
      text += `${indent}  ${getPlainText(node.brief)}
`;
    }
    node.children.forEach(child => {
      text += createTextForNode(child, indentLevel + 1);
    });
    return text;
  };

  let textOutline = '';
  outline.forEach(rootNode => {
    textOutline += createTextForNode(rootNode);
  });

  return textOutline;
};

/*
// Example usage
const nodes = [
  // Nodes data from your input
];

const edges = [
  // Edges data from your input
];

const textOutline = generateTextOutline(nodes, edges);
console.log(textOutline);
*/