import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Box, TextField } from '@mui/material';
import DoubleSelector from '../base/DoubleSelector';

const validationSchema = Yup.object().shape({
  category: Yup.string().required('Catégorie est requise'),
  subcategory: Yup.string().required('Sous-Catégorie est requise'),
  brand: Yup.string().required('Marque est requise'),
  model: Yup.string().required('Modèle est requis'),
  priceBeforeTax: Yup.number().required('Prix Hors Taxe est requis').typeError('Doit être un nombre'),
  priceAfterTax: Yup.number().required('Prix TTC est requis').typeError('Doit être un nombre'),
  theoreticalStock: Yup.number().integer('Doit être un entier').required('Stock Théorique est requis'),
  actualStock: Yup.number().integer('Doit être un entier').required('Stock Réel est requis'),
  brands: Yup.string().required('Marques est requis'),
  location: Yup.string().required('Emplacement est requis'),
  quantityOnCustomerOrder: Yup.number().integer('Doit être un entier').required('Quantité en Commande Client est requise'),
  modelsChassis: Yup.string().required('Modèles Châssis est requis'),
  supplierReference: Yup.string().required('Référence Fournisseur est requise'),
  quantityOnSupplierOrder: Yup.number().integer('Doit être un entier').required('Quantité en Commande Fournisseur est requise'),
});

const PartEditor = () => {
  return (
    <Formik
      initialValues={{
        category: '',
        subcategory: '',
        brand: '',
        model: '',
        priceBeforeTax: '',
        priceAfterTax: '',
        theoreticalStock: '',
        actualStock: '',
        brands: '',
        location: '',
        quantityOnCustomerOrder: '',
        modelsChassis: '',
        supplierReference: '',
        quantityOnSupplierOrder: '',
      }}
      validationSchema={validationSchema}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form>
          <Box display="flex" flexDirection="column" gap={2}>
            <DoubleSelector
              placeholder1="Catégorie"
              placeholder2="Sous-Catégorie"
              categories={[]} // You would pass the actual categories here
              onCategoryChange={(value) => setFieldValue('category', value)}
              onSubcategoryChange={(value) => setFieldValue('subcategory', value)}
            />

            <DoubleSelector
              placeholder1="Marque"
              placeholder2="Modèle"
              categories={[]} // You would pass the actual brands and models here
              onCategoryChange={(value) => setFieldValue('brand', value)}
              onSubcategoryChange={(value) => setFieldValue('model', value)}
            />

            <Field name="priceBeforeTax">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Prix Hors Taxe"
                  type="number"
                  fullWidth
                  error={touched.priceBeforeTax && Boolean(errors.priceBeforeTax)}
                  helperText={touched.priceBeforeTax && errors.priceBeforeTax}
                />
              )}
            </Field>

            <Field name="priceAfterTax">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Prix Toutes Taxes Comprises (TTC)"
                  type="number"
                  fullWidth
                  error={touched.priceAfterTax && Boolean(errors.priceAfterTax)}
                  helperText={touched.priceAfterTax && errors.priceAfterTax}
                />
              )}
            </Field>

            <Field name="theoreticalStock">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Stock Théorique"
                  type="number"
                  fullWidth
                  error={touched.theoreticalStock && Boolean(errors.theoreticalStock)}
                  helperText={touched.theoreticalStock && errors.theoreticalStock}
                />
              )}
            </Field>

            <Field name="actualStock">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Stock Réel"
                  type="number"
                  fullWidth
                  error={touched.actualStock && Boolean(errors.actualStock)}
                  helperText={touched.actualStock && errors.actualStock}
                />
              )}
            </Field>

            <Field name="brands">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Marques"
                  fullWidth
                  error={touched.brands && Boolean(errors.brands)}
                  helperText={touched.brands && errors.brands}
                />
              )}
            </Field>

            <Field name="location">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Emplacement"
                  fullWidth
                  error={touched.location && Boolean(errors.location)}
                  helperText={touched.location && errors.location}
                />
              )}
            </Field>

            <Field name="modelsChassis">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Modèles Châssis"
                  fullWidth
                  error={touched.modelsChassis && Boolean(errors.modelsChassis)}
                  helperText={touched.modelsChassis && errors.modelsChassis}
                />
              )}
            </Field>

            <Field name="supplierReference">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Référence Fournisseur"
                  fullWidth
                  error={touched.supplierReference && Boolean(errors.supplierReference)}
                  helperText={touched.supplierReference && errors.supplierReference}
                />
              )}
            </Field>

            <Field name="quantityOnCustomerOrder">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Quantité en Commande Client"
                  type="number"
                  fullWidth
                  error={touched.quantityOnCustomerOrder && Boolean(errors.quantityOnCustomerOrder)}
                  helperText={touched.quantityOnCustomerOrder && errors.quantityOnCustomerOrder}
                />
              )}
            </Field>

            <Field name="quantityOnSupplierOrder">
              {({ field }) => (
                <TextField
                  {...field}
                  label="Quantité en Commande Fournisseur"
                  type="number"
                  fullWidth
                  error={touched.quantityOnSupplierOrder && Boolean(errors.quantityOnSupplierOrder)}
                  helperText={touched.quantityOnSupplierOrder && errors.quantityOnSupplierOrder}
                />
              )}
            </Field>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default PartEditor;
