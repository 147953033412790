import React from 'react';
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { TextField, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const validationSchema = Yup.object().shape({
    taskName: Yup.string().max(100, 'Task Name must be 100 characters or less').required('Task Name is required'),
    description: Yup.string().max(500, 'Description must be 500 characters or less'),
    dueDate: Yup.date().required('Due Date is required').min(new Date(), 'Due Date must be today or later')
});

// Custom Input that handles blur for auto-save
const AutoSaveTextField = ({ label, name, ...props }) => {
    const { values, handleChange, handleBlur, submitForm } = useFormikContext();

    // Wrap handleBlur to trigger form submission
    const handleBlurAndSubmit = (e) => {
        handleBlur(e); // First handle the blur normally
        submitForm();  // Then trigger form submission
    };

    return (
        <TextField
            label={label}
            name={name}
            value={values[name]}
            onChange={handleChange}
            onBlur={handleBlurAndSubmit} // Call the wrapped handleBlur
            fullWidth
            {...props}
        />
    );
};

// Custom Date Picker that auto-saves on change
const AutoSaveDatePicker = ({ label, name }) => {
    const { values, setFieldValue, submitForm } = useFormikContext();

    const handleChangeAndSubmit = (date) => {
        setFieldValue(name, date);
        submitForm();
    };

    return (
        <DatePicker
            label={label}
            value={values[name]}
            onChange={handleChangeAndSubmit}
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                />
            )}
        />
    );
};

const TaskEditor = () => {
    const initialValues = {
        taskName: '',
        description: '',
        dueDate: null,
    };

    const handleSubmit = (values) => {
        console.log('Auto-Saving Form Values:', values);
        // Add your save logic here (e.g., API call)
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form>
                        <Box marginBottom={3} marginTop={3}>
                            <AutoSaveTextField name="taskName" label="Task Name" />
                        </Box>

                        <Box marginBottom={3}>
                            <AutoSaveTextField
                                name="description"
                                label="Description"
                                multiline
                                rows={4}
                            />
                        </Box>

                        <Box marginBottom={3}>
                            <AutoSaveDatePicker name="dueDate" label="Due Date" />
                        </Box>
                    </Form>
                )}
            </Formik>
        </LocalizationProvider>
    );
};

export default TaskEditor;
