// Function to identify root nodes (nodes with no incoming edges)
const getRootNodes = (nodes, edges) => {
    const rootNodes = nodes.filter(node => {
        const hasIncomingEdges = edges.some(edge => edge.target === node.id);
        return !hasIncomingEdges;
    });

    return rootNodes;
};

// Function to get the children of a node, sorted by Y-coordinate
const getSortedChildren = (node, nodes, edges) => {
    const childEdges = edges.filter(edge => edge.source === node.id);
    const childNodes = childEdges.map(edge => nodes.find(n => n.id === edge.target));

    // Sort child nodes by Y-coordinate
    return childNodes.sort((a, b) => a.position.y - b.position.y);
};

// Recursive function to construct the hierarchical JSON structure
const constructHierarchy = (node, nodes, edges) => {
    const children = getSortedChildren(node, nodes, edges);
    const nodeJson = {
        id: node.id,
        type: node.type,
        data: node.data,
        position: node.position,
        children: children.map(child => constructHierarchy(child, nodes, edges))
    };

    return nodeJson;
};

// Main function to generate the JSON structure
export const generateJsonStructure = (nodes, edges) => {
    const rootNodes = getRootNodes(nodes, edges);

    // Sort root nodes by Y-coordinate
    const sortedRootNodes = rootNodes.sort((a, b) => a.position.y - b.position.y);

    // Construct the hierarchical JSON structure starting from the root nodes
    const jsonStructure = sortedRootNodes.map(rootNode => constructHierarchy(rootNode, nodes, edges));

    return jsonStructure;
};

export const getNodeIdsFromJsonStructure = (jsonStructure) => {
    if (!jsonStructure || !Array.isArray(jsonStructure)) {
      console.error("Invalid JSON structure");
      return [];
    }
  
    return jsonStructure.flatMap(extractNodeIds);
  };
  
  const extractNodeIds = (json) => {
    const nodeIds = [];
  
    // Check if the node satisfies the condition for data.soundLink
    if (json.data && json.data.soundLink) {
      nodeIds.push(json.id);
    }
  
    if (json.children) {
      json.children.forEach((child) => {
        nodeIds.push(...extractNodeIds(child));
      });
    }
  
    return nodeIds;
  };

// Function to generate the display list
export const getDisplayList = (nodes, edges) => {
    const jsonStructure = generateJsonStructure(nodes, edges);
    const nodeIds = getNodeIdsFromJsonStructure(jsonStructure);
    return nodeIds.map(id => [id]);
};