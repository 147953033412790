export const calculateNodeStates = (nodeId, newState, nodes, edges) => {
    // Step 1: Create a map of nodes for easier reference and manipulation
    let nodeMap = new Map(nodes.map(node => [node.id, { ...node, data: { ...node.data } }])); // deep clone node data

    // Check if the node exists in the map
    if (!nodeMap.has(nodeId)) {
        console.error(`Node with ID ${nodeId} not found.`);
        return nodes; // Exit early if the node does not exist
    }

    const nodeData = nodeMap.get(nodeId).data;

    // Set the new state of the specified node
    if (nodeData.state !== newState) {
        nodeMap.get(nodeId).data.state = newState;  // Update only if state has changed
    }

    // Step 2: Build a reverse map of parent-child relationships
    const parentMap = new Map(); // Maps each child to its parent
    const childrenMap = new Map(); // Maps each parent to its children

    edges.forEach(edge => {
        const { source: parentId, target: childId } = edge;

        // Map child to parent
        parentMap.set(childId, parentId);

        // Map parent to its children
        if (!childrenMap.has(parentId)) {
            childrenMap.set(parentId, []);
        }
        childrenMap.get(parentId).push(childId);
    });

    // Function to calculate the parent state based on its children's states
    const calculateParentState = (parentId) => {
        try {
            if (!childrenMap.has(parentId)) {
                return; // No children, so we can't calculate a new state
            }

            const children = childrenMap.get(parentId);
            const totalState = children.reduce((acc, childId) => {
                const childNode = nodeMap.get(childId);
                return acc + (childNode ? childNode.data.state : 0);
            }, 0);

            const averageState = totalState / children.length;
            nodeMap.get(parentId).data.state = averageState; // Update the parent state
        } catch (error) {
            console.error(`Error calculating state for parent ID ${parentId}:`, error);
        }
    };

    // Step 3: Traverse up from the node to the root, updating each parent's state
    let currentNodeId = nodeId;
    try {
        while (parentMap.has(currentNodeId)) {
            const parentId = parentMap.get(currentNodeId);
            calculateParentState(parentId); // Update parent's state based on children
            currentNodeId = parentId; // Move up the tree to the parent
        }
    } catch (error) {
        console.error(`Error while traversing up the node tree:`, error);
    }

    // Step 4: Return the updated nodes array with their new states
    return Array.from(nodeMap.values());
};
