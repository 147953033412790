import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

export default function DoubleSelector({
  placeholder1,
  placeholder2,
  categories,
  onCategoryChange,
  onSubcategoryChange,
}) {
  const [category, setCategory] = React.useState('');
  const [subcategory, setSubcategory] = React.useState('');
  const [subcategories, setSubcategories] = React.useState([]);

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategory(selectedCategory);
    setSubcategory(''); // Reset subcategory when category changes

    // Update subcategories based on selected category
    const selectedCategoryObject = categories.find((cat) => cat.id === selectedCategory);
    setSubcategories(selectedCategoryObject ? selectedCategoryObject.sous_categories : []);

    // Notify parent component of the category change
    if (onCategoryChange) {
      onCategoryChange(selectedCategory);
    }
  };

  const handleSubcategoryChange = (event) => {
    const selectedSubcategory = event.target.value;
    setSubcategory(selectedSubcategory);

    // Notify parent component of the subcategory change
    if (onSubcategoryChange) {
      onSubcategoryChange(selectedSubcategory);
    }
  };

  return (
    <Box display="flex" gap={2} alignItems="center">
      <FormControl sx={{ minWidth: 200 }}>
        <InputLabel id="category-select-label">{placeholder1}</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          value={category}
          label={placeholder1}
          onChange={handleCategoryChange}
        >
          <MenuItem value="">
            <em>Aucun</em>
          </MenuItem>
          {categories.map((cat) => (
            <MenuItem key={cat.id} value={cat.id}>
              {cat.nom}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ minWidth: 200 }} disabled={!category}>
        <InputLabel id="subcategory-select-label">{placeholder2}</InputLabel>
        <Select
          labelId="subcategory-select-label"
          id="subcategory-select"
          value={subcategory}
          label={placeholder2}
          onChange={handleSubcategoryChange}
        >
          <MenuItem value="">
            <em>Aucun</em>
          </MenuItem>
          {subcategories.map((sub, index) => (
            <MenuItem key={index} value={sub}>
              {sub}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

DoubleSelector.propTypes = {
  placeholder1: PropTypes.string.isRequired,
  placeholder2: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      nom: PropTypes.string.isRequired,
      sous_categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  onCategoryChange: PropTypes.func,
  onSubcategoryChange: PropTypes.func,
};
