import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import LogIn from "./Login";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";
import MlEditor from "./components/Mainlist/MlEditor";
import { AuthProvider, useAuth } from "./AuthContext";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setUser } from './redux/UserSlice';
import UserConfirmation from './UserConfirmation';
import { MlCanvasProvider } from './MlModels/MlCanvasProvider';
import PublicCanvasViewer from './components/Mainlist/cannevas/PublicCanvasViewer';
import BelgicomicsLandinPage from "./components/Mainlist/PageTemplates/BelgicomicsLandinPage";
import QwanyxLandinPage from "./components/Mainlist/PageTemplates/QwanyxLandingPage";
import AutodinLandinPage from "./components/autodin/AutodinLandinPage";
import EmotionHome from './emotions/Home';
import EmotionBoard from './emotions/EmotionBoard';
import AutodinRGPD from './components/autodin/RGPD';
import AcheterTrucsEtAstuces from './components/autodin/AcheterTrucsEtAstuces';
import ConditionsGenerales from './components/autodin/ConditionsGenerales';
import NormesCO2 from './components/autodin/NormesCO2';
import NeuveOccasion from './components/autodin/NeuveOccasion';
import AutoPartsLandingPage from "./components/autoparts/AutoPartsLandingPage";

import DashBoard from './components/user/DashBoard';

import { AutodinProvider } from "./components/autodin/AutodinProvider";
import GlobalStyle from './styles/GlobalStyles';
import { UserProvider } from './MlModels/UserContext'; // Import the UserProvider
import { BusinessProvider } from './MlModels/BusinessContext';
import { LanguageProvider } from './language/LanguageContext';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { QwanyxLandingPage } from "qwanyx-core";

import "./App.css";

// Load Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ACCESS_KEY_ID);
const test = process.env.REACT_APP_CLIENT_ID;
const getLandingPage = () => {
  switch (process.env.REACT_APP_CLIENT_ID) {
    case 'qwanyx-core':
      return <QwanyxLandingPage />;
    case 'qwanyx':
      return <QwanyxLandinPage />;
    case 'belgicomics':
      return <BelgicomicsLandinPage />;
    case 'autodin':
      return (
        <AutodinProvider>
          <AutodinLandinPage />
        </AutodinProvider>
      );
    case 'autoparts':  // Handle 'autoparts' case
      return (
        <AutodinProvider>
          <AutoPartsLandingPage />
        </AutodinProvider>
      );
    default:
      return <QwanyxLandinPage />; // Default landing page if no match
  }
};

const setFavicon = (url) => {
  const link = document.createElement('link');
  link.rel = 'icon';
  link.type = 'image/png';
  link.href = url;
  document.head.appendChild(link);
};

const MainRoutes = () => {
  const { currentUser } = useAuth();
  const [loggedIn, setLoggedIn] = useState(!!currentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    let faviconUrl;
    switch (process.env.REACT_APP_CLIENT_ID) {
      case 'qwanyx-core':
        document.title = 'Qwanyx Core';
        faviconUrl = '/favicons/qwanyx-favicon.png';
        break;
      case 'qwanyx':
        document.title = 'qwanyx';
        faviconUrl = '/favicons/qwanyx-favicon.png';
        break;
      case 'belgicomics':
        document.title = 'Belgicomics';
        faviconUrl = '/favicons/belgicomics-favicon.png';
        break;
      case 'autodin':
        document.title = 'AUTODIN';
        faviconUrl = '/favicons/autodin-favicon.png';
        break;
      case 'autoparts':
        document.title = 'AUTOPARTS';
        faviconUrl = '/favicons/autodin-favicon.png';
        break;
      default:
        document.title = 'qwanyx'; // Default landing page if no match
        faviconUrl = '/favicons/default-favicon.png';
        break;
    }
    setFavicon(faviconUrl);
  }, []);

  useEffect(() => {
    setLoggedIn(!!currentUser);
    if (currentUser) {
      dispatch(setUser({
        id: currentUser.id,
        name: currentUser.name,
        email: currentUser.email,
      }));
    }
  }, [currentUser, dispatch]);

  const handleLoginRedirect = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;

    switch (clientId) {
      case 'autodin':
        return <Navigate to="/autodin" />;
      case 'autoparts':
        return <Navigate to="/autoparts" />;
      default:
        return <Navigate to="/editor" />;
    }
  };

  return (
    <Routes>
      <Route path="/" element={loggedIn ? handleLoginRedirect() : getLandingPage()} />
      <Route path="/editor" element={loggedIn ? <MlEditor /> : <Navigate to="/" />} />
      <Route path="/autodin" element={<AutodinProvider><AutodinLandinPage loggedIn={loggedIn} /></AutodinProvider>} />
      <Route path="/autoparts" element={<AutodinProvider><AutoPartsLandingPage loggedIn={loggedIn} /></AutodinProvider>} />
      <Route path="/autodin/:selectedNodeId" element={<AutodinProvider><AutodinLandinPage loggedIn={loggedIn} /></AutodinProvider>} />
      <Route path="/autodin/RGPD" element={<AutodinRGPD />} />
      <Route path="/autodin/ConditionsGenerales" element={<ConditionsGenerales />} />
      <Route path="/autodin/AcheterTrucsEtAstuces" element={<AcheterTrucsEtAstuces />} />
      <Route path="/autodin/NormesCO2" element={<NormesCO2 />} />
      <Route path="/autodin/NeuveOccasion" element={<NeuveOccasion />} />
      <Route path="/autodin/DashBoard" element={<DashBoard />} />
      <Route path="/login" element={<LogIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/confirmEmail" element={<UserConfirmation />} />
      <Route path="/resetPassword" element={<ResetPassword />} />
      <Route path="/public-canvas/:canvasId" element={<PublicCanvasViewer />} />
      <Route path="/emotionapp" element={<EmotionBoard />} />
      <Route path="/emotion/home" element={<EmotionHome />} />
    </Routes>
  );
};

// Set language based on REACT_APP_CLIENT_ID
const getDefaultLanguage = () => {
  switch (process.env.REACT_APP_CLIENT_ID) {
    case 'belgicomics':
    case 'autodin':
    case 'autoparts':
      return 'fr'; // French
    case 'qwanyx':
    case 'qwanyx-core':
    default:
      return 'en'; // English
  }
};

const App = () => {
  const [language, setLanguage] = useState(getDefaultLanguage());

  useEffect(() => {
    // Prevent the default browser context menu globally
    const preventContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', preventContextMenu);

    return () => {
      // Clean up the event listener when the component is unmounted
      document.removeEventListener('contextmenu', preventContextMenu);
    };
  }, []);

  return (
    <AuthProvider>
      <LanguageProvider initialLanguage={language}>
        <MlCanvasProvider>
          <UserProvider>
            <BusinessProvider>
              <GlobalStyle />
              <Router>
                <Elements stripe={stripePromise}>
                  <MainRoutes />
                </Elements>
              </Router>
            </BusinessProvider>
          </UserProvider>
        </MlCanvasProvider>
      </LanguageProvider>
    </AuthProvider>
  );
};

export default App;
