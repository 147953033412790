import React from 'react';
import { Dialog } from '@mui/material';

const FullScreenModal = ({ open, handleClose, backgroundColor = '#f5f5f5', children }) => {
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen // Forces full screen mode
            PaperProps={{
                style: {
                    width: '100vw',  // Full width of the viewport
                    height: '100vh', // Full height of the viewport
                    margin: 0, // Removes any default margins or gaps
                    padding: 0, // Ensures no internal padding
                    backgroundColor: backgroundColor,
                    overflow: 'hidden', // Prevents any overflow
                },
            }}
        >
            <div style={{ width: '100%', height: '100%' }}>
                {children}
            </div>
        </Dialog>
    );
};

export default FullScreenModal;
