// MlRecording.js
import React, { useState, useRef } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicNoneIcon from '@mui/icons-material/MicNone';
import { Alert, Tooltip } from '@mui/material';

export default function AudioRecorder({ onRecordingComplete, size = 'default' }) {
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState('');
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const startTimeRef = useRef(null); // Ref to store the start time of the recording

  const handleStartRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener('dataavailable', event => {
          audioChunksRef.current.push(event.data);
        });

        mediaRecorderRef.current.addEventListener('stop', () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
          const audioDuration = (Date.now() - startTimeRef.current) / 1000; // Calculate the duration

          if (audioDuration < 1) {
            setError('Recording is too short. Please record for at least 3 seconds.');
          } else {
            onRecordingComplete(audioBlob, audioDuration);
            setError('');
          }

          audioChunksRef.current = [];
        });

        mediaRecorderRef.current.start();
        startTimeRef.current = Date.now(); // Store the start time
        setIsRecording(true);
      })
      .catch((e) => {
        console.error(e);
        setError('Could not start recording.');
      });
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const iconStyle = { fontSize: size }; // Style object to control icon size

  return (
    <>
      {error && <Alert severity="error">{error}</Alert>}
      <div
        onMouseDown={handleStartRecording}
        onMouseUp={handleStopRecording}
        onMouseLeave={handleStopRecording} // In case the mouse leaves the element while recording
        onTouchStart={handleStartRecording} // For touch devices
        onTouchEnd={handleStopRecording}
        style={{ cursor: 'pointer' }}
      >
        <Tooltip title="Dictate" arrow>
          {isRecording ? <MicIcon color="secondary" style={iconStyle} /> : <MicNoneIcon style={iconStyle} />}
        </Tooltip>
      </div>
    </>
  );
}