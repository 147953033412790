import React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MailSettings } from './MlBusinessIcons';
import {
  Task,
  Task10,
  Task20,
  Task40,
  Task50,
  Task60,
  Task80,
  Task90,
  Task100,
  TaskHold,
  Project,
  Resource,
  Chart
} from './MlProjectIcons';

import { Note } from './MlBaseIcons'

const menuItems = [
  { icon: Note, label: 'Note', disabled: false, type: 'note', state: null },
  { icon: Task, label: 'Task', disabled: false, type: 'task', state: 0 },
  { icon: Task10, label: 'Task 10%', disabled: false, type: 'task', state: 10 },
  { icon: Task20, label: 'Task 20%', disabled: false, type: 'task', state: 20 },
  { icon: Task40, label: 'Task 40%', disabled: false, type: 'task', state: 40 },
  { icon: Task50, label: 'Task 50%', disabled: false, type: 'task', state: 50 },
  { icon: Task60, label: 'Task 60%', disabled: false, type: 'task', state: 60 },
  { icon: Task80, label: 'Task 80%', disabled: false, type: 'task', state: 80 },
  { icon: Task90, label: 'Task 90%', disabled: false, type: 'task', state: 90 },
  { icon: Task100, label: 'Task 100%', disabled: false, type: 'task', state: 100 },
  { icon: TaskHold, label: 'Task on Hold', disabled: false, type: 'task', state: 'hold' },
  { icon: Project, label: 'Project', disabled: false, type: 'project', state: null },
  { icon: Resource, label: 'Resource', disabled: false, type: 'resource', state: null },
  { icon: MailSettings, label: 'Mail Settings', disabled: false, type: 'mailsettings', state: null },
  { icon: Chart, label: 'Chart', disabled: true, type: 'chart', state: null },
];

export function getIconByTypeAndState(type, state) {
  if (type === 'task') {
    // Ensure state is a number (if it's not, set it to 0 by default)
    const numericState = typeof state === 'number' ? state : 0;

    if (numericState >= 0 && numericState <= 4) {
      return Task;        // Icon for state between 0 and 4
    } else if (numericState >= 5 && numericState <= 10) {
      return Task10;      // Icon for state between 5 and 10
    } else if (numericState >= 11 && numericState <= 20) {
      return Task20;      // Icon for state between 11 and 20
    } else if (numericState >= 21 && numericState <= 40) {
      return Task40;      // Icon for state between 21 and 40
    } else if (numericState >= 41 && numericState <= 50) {
      return Task50;      // Icon for state between 41 and 50
    } else if (numericState >= 51 && numericState <= 60) {
      return Task60;      // Icon for state between 51 and 60
    } else if (numericState >= 61 && numericState <= 80) {
      return Task80;      // Icon for state between 61 and 80
    } else if (numericState >= 81 && numericState <= 90) {
      return Task90;      // Icon for state between 81 and 90
    } else if (numericState >= 91 && numericState <= 100) {
      return Task100;     // Icon for state between 91 and 100
    } else if (state === 'hold') {
      return TaskHold;    // Icon for "hold"
    } else {
      return Task;        // Default icon for any other task
    }
  } else {
    // For non-task types
    switch (type) {
      case 'project': return Project;
      case 'mailsettings': return MailSettings;
      case 'resource': return Resource;
      case 'chart': return Chart;
      case 'note': return Note;
      default: return Note;  // Default icon
    }
  }
}
export default function MLIconMenu({ onMenuItemClick }) {

  const handleItemClick = (icon, label, type, state) => {
    if (onMenuItemClick) {
      onMenuItemClick(icon, label, type, state);
    }
  };
  

  return (
    <Paper >
      <MenuList>
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            {index === 1 && <Divider />}
            {index === 10 && <Divider />}
            <MenuItem
              onClick={() => handleItemClick(item.icon, item.label, item.type, item.state)}
              disabled={item.disabled}
            >
              <ListItemIcon>
                <item.icon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          </React.Fragment>
        ))}
      </MenuList>
    </Paper>
  );
}