import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import { QWANYZ_COLORS } from './QwanyzColorPalette';

const MultipleChoiceView = ({ question, viewMode = 'question' }) => {
  const isAnswerMode = viewMode === 'answer';
  const [selectedChoices, setSelectedChoices] = useState(Array(question?.choices.length).fill(false));

  // Set the selected choices based on mode
  useEffect(() => {
    if (isAnswerMode) {
      // Set the choices based on the recorded selections (correct answers)
      setSelectedChoices(question.selectedChoices || Array(question?.choices.length).fill(false));
    } else {
      // Reset selections when in question mode
      setSelectedChoices(Array(question?.choices.length).fill(false));
    }
  }, [viewMode, question?.choices.length]);

  // Handle choice selection in question mode
  const handleChoiceClick = (index) => {
    if (!isAnswerMode) {
      const updatedSelections = [...selectedChoices];
      updatedSelections[index] = !updatedSelections[index];
      setSelectedChoices(updatedSelections);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 700, margin: 'auto' }}>
      {/* Title Section */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" gutterBottom>
          {question?.title}
        </Typography>
      </Box>
      
      {/* Question Section */}
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="body1">
          {question?.question}
        </Typography>
      </Box>
      
      {/* Choices Section */}
      <Box sx={{ marginTop: 2 }}>
        {question?.choices && question.choices.map((choice, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => handleChoiceClick(index)}
              sx={{
                backgroundColor: selectedChoices[index]
                  ? 'primary.main'
                  : 'white',
                color: selectedChoices[index]
                  ? 'white'
                  : 'black',
                textTransform: 'none',
                cursor: isAnswerMode ? 'default' : 'pointer',
                '&:hover': {
                  backgroundColor: selectedChoices[index]
                    ? 'primary.dark'
                    : 'primary.light',
                },
              }}
            >
              {choice}
            </Button>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default MultipleChoiceView;
