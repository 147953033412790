import React, { createContext, useState, useContext, useCallback } from 'react';
import { nodesGet, createNode, getUserId, nodePut } from '../MlModels/MlNode';
import { userCredits } from './UserCredits';

// Create the UserContext
export const UserContext = createContext();

// Create a provider component for the UserContext
export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const type = 'userData';

    // Memoized function to fetch user data based on identity
    const fetchUserData = useCallback(async (identity) => {
        try {
            const query = { userId: getUserId(), identity, type };
            const fetchedNodes = await nodesGet(query);
    
            if (fetchedNodes && fetchedNodes.length > 0) {
                let user = fetchedNodes[0];
    
                // Check if user is a superUser or vip and reset aiCredits
                if (user.role === 'superUser' || user.role === 'vip') {
                    user.aiCredits = userCredits.aiCreditsVip;
                }
    
                // Set the user data in the state (without saving it back)
                setUserData(user);
            } else {
                // Create a new user node with base credits and a normal role
                const newUser = createNode({
                    title: '',
                    type: type,
                    identity: identity,
                    aiCredits: userCredits.aiCreditsGift,
                    role: 'normal',
                });
    
                // Save the new user node to the database using nodePut
                await nodePut(newUser);
    
                // Set the newUser directly in the state after saving
                setUserData(newUser);
            }
        } catch (error) {
            console.error('Failed to fetch or create user data:', error);
        }
    }, []);

    // Modify props locally without saving to the database
    const setProp = useCallback((propObject) => {
        if (!userData) {
            console.error('User data not loaded. Fetch data before setting properties.');
            return;
        }

        // Update local state with the modified properties
        setUserData((prevData) => ({ ...prevData, ...propObject }));  // Reflect locally
    }, [userData]);

    // Commit the pending changes to the database
    const userCommit = useCallback(async () => {
        if (!userData) {
            console.error('User data not loaded. Cannot commit changes.');
            return;
        }
    
        try {
            // Directly commit the userData to the database
            nodePut(userData);
            console.log('User data committed to the database.');
        } catch (error) {
            console.error('Failed to commit user data:', error);
        }
    }, [userData]);

    return (
        <UserContext.Provider
            value={{
                userData,
                setProp,
                userCommit,
                fetchUserData,
                setUserData,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to use the UserContext
export const useUser = () => {
    return useContext(UserContext);
};
