import React from 'react';
import {
    Stack, 
    Typography
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

import {
    Title,
    Infoframe as InfoframeWrapper,
    Price,
    Pricetva,
    Tvacomprise,
    BigBold,
  } from './StyledStyles'; 

export default function InfoSeller({ businessData }) {
    // Construct the address
    const buildAddress = () => {
        const number = businessData?.number || '';
        const unit = businessData?.unit || '';
        const street = businessData?.street || '';
        const postalCode = businessData?.postalCode || '';
        const city = businessData?.city || '';
    
        return `${number}${unit ? `/${unit}` : ''} ${street}${postalCode ? `, ${postalCode}` : ''} ${city}`.trim();
    };

    return (
        <Stack spacing={2}>
            {/* Only render if phone exists */}
            {businessData?.phone && (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <PhoneIcon />
                    <Title variant='h7'>{businessData?.phone}</Title>
                </Stack>
            )}

            {/* Only render if the address is not empty */}
            {buildAddress().trim() && (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <LocationOnIcon />
                    <Title>{buildAddress()}</Title>
                </Stack>
            )}

            {/* Only render if email exists */}
            {businessData?.email && (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <EmailIcon />
                    <Title>{businessData?.email}</Title>
                </Stack>
            )}
        </Stack>
    );
}
