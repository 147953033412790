import React from 'react';
import FullScreenModal from '../base/FullScreenModal';
import QwanyzControlPanel from './QwanyzControlPanel';
import SimpleQuestionEditor from './SimpleQuestionEditor';
import { Stack, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const QwanyzDesktop = ({ open, handleClose }) => {
    return (
        <FullScreenModal open={open} handleClose={handleClose}>
            <Stack direction="column" sx={{ width: '100%', height: '100%', position: 'relative' }}>
                {/* Close button at the top right */}
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        zIndex: 1,
                    }}
                >
                    <ClearIcon />
                </IconButton>
                <SimpleQuestionEditor sx={{ flexGrow: 1, width: '100%' }} />
                <QwanyzControlPanel showNavigation={true} showPlayStop={true} />
            </Stack>
        </FullScreenModal>
    );
};

export default QwanyzDesktop;
