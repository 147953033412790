import React from 'react';
import { Box, Typography, Paper, TextField } from '@mui/material';

const BasicRecallView = ({ question, viewMode = 'question' }) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 700, margin: 'auto' }}>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6" gutterBottom>
          {question.title}
        </Typography>
      </Box>
      {viewMode === 'question' && (
        <Box sx={{ marginBottom: 2 }}>
          <Typography variant="body1">
            {question.question}
          </Typography>
          {question.useEmptyField && (
            <TextField
              label="Your Answer"
              multiline
              rows={4}
              fullWidth
              variant="outlined"
            />
          )}
        </Box>
      )}
      {viewMode === 'answer' && (
        <Box>
          <Typography variant="body1">
            {question.correctAnswer}
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default BasicRecallView;