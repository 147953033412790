import React from 'react';
import { Paper, Box } from '@mui/material';

const ImageQuestionsView = ({ question, viewMode = 'question' }) => {
  // Function to get the final image URL based on the source string
  const getImageUrl = (imageSrc) => {
    if (imageSrc.startsWith('/IMAGES')) {
      return `${process.env.REACT_APP_S3_URL_BASE}${imageSrc}`;
    }
    return imageSrc;
  };

  const imageUrl = viewMode === 'question' ? question.questionImage : question.answerImage;

  return (
    <Paper elevation={3} sx={{ padding: 0, width: '100%', maxWidth: 700, margin: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
        {imageUrl ? (
          <img
            src={getImageUrl(imageUrl)}
            alt={viewMode === 'question' ? 'Question' : 'Answer'}
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        ) : null}
      </Box>
    </Paper>
  );
};

export default ImageQuestionsView;
