// qwanyxRender/preprocessQwanyxData.js
import { fetchQwanyxData } from 'qwanyx-core'; // Assuming qwanyx-core exports this function

export async function preprocessQwanyxData(data) {
  if (!data) {
    return null;
  }

  // If data has a fetch field, fetch the data
  if (data.fetch) {
    const fetchedData = await fetchQwanyxData(data.fetch);
    return preprocessQwanyxData(fetchedData); // Recursively preprocess the fetched data
  }

  // If data has children, process them as well
  if (data.props && data.props.children) {
    const processedChildren = await Promise.all(
      data.props.children.map(preprocessQwanyxData) // Preprocess each child
    );
    return {
      ...data,
      props: {
        ...data.props,
        children: processedChildren,
      },
    };
  }

  // Return data if it doesn't need any fetching
  return data;
}
