

export const RAMS_COLORS = {
    orange: "#FF4600",
    coral: "#FF7F50",
    beige: "#DDDBC4",
    lightBeige: "#FFFFFA",
    grey: "#5F5F5F",
    white: "#FFFFFF",
    disableled: '#bdbdbd',
    executiveBlue: '#5097ff',
    executiveGreen: '#6bf2ba',
    executiveYellow: '#fff683',

  };


  