import React, { useEffect, useState } from 'react';
import './Card.css'; // Minimal CSS styles for structure only
import { WebsocketProvider } from 'y-websocket';
import { fetchCardData, saveCardData, syncDoc, renderComponent } from 'qwanyx-core';

export const QCard = ({
  cardId,
  isCollaborative = false,
  showHeader = false,      // Headers are hidden by default
  showFooter = false,      // Footers are hidden by default
  headerContent = null,
  footerContent = null,
  headerStyle = {},        // Optional custom styles for the header
  footerStyle = {},        // Optional custom styles for the footer
  contentStyle = {},       // Optional custom styles for the content area
  children,
  className = '',
  style = {},              // Base style that can be customized
  lockHeader = false,
  lockFooter = false,
  ...props
}) => {
  const [cardData, setCardData] = useState({});
  const [yMap, setYMap] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isCollaborative && cardId) {
      const yMapInstance = syncDoc.getMap(`card-${cardId}`);
      const provider = new WebsocketProvider('wss://your-websocket-server', `card-${cardId}`, syncDoc);

      const updateCardData = () => {
        if (isMounted) setCardData(yMapInstance.get('data') || {});
      };

      yMapInstance.observe(updateCardData);
      setYMap(yMapInstance);

      return () => {
        isMounted = false;
        yMapInstance.unobserve(updateCardData);
        provider.disconnect();
      };
    } else {
      fetchCardData(cardId).then((data) => {
        if (isMounted) setCardData(data || {});
      });
      return () => {
        isMounted = false;
      };
    }
  }, [cardId, isCollaborative]);

  const updateCardData = (newData) => {
    if (isCollaborative && yMap) {
      yMap.set('data', newData);
    } else {
      setCardData(newData);
      saveCardData(cardId, newData);
    }
  };

  const renderContent = () => {
    if (children) {
      return children; // Render static children passed directly to the Card
    }
    if (cardData && cardData.children) {
      return cardData.children.map((child, index) => (
        <React.Fragment key={index}>
          {renderComponent(child)}
        </React.Fragment>
      ));
    }
    return null;
  };

  return (
    <div
      className={`card ${className}`}
      style={{
        ...style,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        boxSizing: 'border-box', // Neutral layout to prevent overflow issues
      }}
      {...props}
    >
      {showHeader && (
        <header
          className="card-header"
          style={{
            ...headerStyle,
            ...(lockHeader ? { position: 'sticky', top: 0, zIndex: 1 } : {}),
          }}
        >
          {headerContent || cardData.headerContent}
        </header>
      )}
      <main
        className="card-content"
        style={{
          ...contentStyle,
          flex: 1,
          overflowY: 'auto', // Only if content exceeds height
        }}
      >
        {renderContent()}
      </main>
      {showFooter && (
        <footer
          className="card-footer"
          style={{
            ...footerStyle,
            ...(lockFooter ? { position: 'sticky', bottom: 0, zIndex: 1 } : {}),
          }}
        >
          {footerContent || cardData.footerContent}
        </footer>
      )}
    </div>
  );
};
