import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Stack } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';  
import DashBoard from '../user/DashBoard';  // Import the DashBoard component
import app from '../../realm-config';

const FooterContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#333333', // dark grey
    color: '#ffffff', // white text
    padding: theme.spacing(4, 0),
}));

const Intro = `Cherchez-vous une voiture d'occasion fiable ou une pièce détachée spécifique ?

Avez-vous besoin d'une réparation mécanique ou de carrosserie de qualité ?

Vous êtes au bon endroit !

Chez Bel-Car, nous offrons un large choix de voitures d'occasion toutes marques, inspectées et certifiées pour une conduite en toute tranquillité. Nous proposons aussi un service complet de réparation en mécanique et carrosserie, pour tous types de véhicules.

Si vous avez des pièces à vendre, ajoutez-les à notre base de données; nous les vendrons pour vous. Nous sommes spécialisés dans les boîtes automatiques toutes marques, vous assurant un service expert et de qualité. Rejoignez Bel-Car pour une expérience automobile sans tracas.

Bienvenue à bord !`;

const Footer = () => {
    const [isDashboardOpen, setDashboardOpen] = useState(false);

    const handleDashboardOpen = () => {
        setDashboardOpen(true);
    };

    const handleDashboardClose = () => {
        setDashboardOpen(false);
    };

    return (
        <FooterContainer>
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Qui sommes-nous
                        </Typography>
                        <div>
                            {Intro.split('\n\n').map((paragraph, index) => (
                                <Typography key={index} paragraph>
                                    {paragraph}
                                </Typography>
                            ))}
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Liens
                        </Typography>
                        <Stack spacing={.8}>
                            <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Home
                            </Link>
                            <Link to="/autodin/RGPD" style={{ color: 'inherit', textDecoration: 'none' }}>
                                RGPD
                            </Link>
                            <Link to="/autodin/ConditionsGenerales" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Conditions Générales
                            </Link>
                            <Link to="/autodin/AcheterTrucsEtAstuces" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Acheter une voiture trucs et astuces
                            </Link>
                            <Link to="/autodin/NeuveOccasion" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Voiture neuve ou occasion?
                            </Link>
                            
                            <Link to="/autodin/NormesCO2" style={{ color: 'inherit', textDecoration: 'none' }}>
                                Tout savoir sur les normes co2
                            </Link>
                            {app.currentUser && (
                                <a href="#" onClick={handleDashboardOpen} style={{ color: 'inherit', textDecoration: 'none' }}>
                                    Tableau de bord
                                </a>
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" gutterBottom>
                            Contact
                        </Typography>
                        <Typography variant="body2">
                            Email: contact@belcar.com
                        </Typography>
                        <Typography variant="body2">
                            Phone: +32 123 45 67 89
                        </Typography>
                    </Grid>
                </Grid>

                <Box textAlign="center" pt={5} pb={2}>
                    <Typography variant="body2">
                        © {new Date().getFullYear()} Autodin. All rights reserved.
                    </Typography>
                </Box>
            </Container>

            {/* Render the DashBoard as a Dialog */}
            <DashBoard 
                open={isDashboardOpen} 
                handleClose={handleDashboardClose} 
                identity='autodin'
            />
        </FooterContainer>
    );
};

export default Footer;
